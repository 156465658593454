import React from "react";
import s from "./prelandingCard.module.scss";
import bomb from "../../../../../../assets/wheelsThemes/blue/additions/bombBackgroundBL.svg";
import stoneTL from "../../../../../../assets/wheelsThemes/blue/additions/stoneWheelTL.svg";
import stoneTR from "../../../../../../assets/wheelsThemes/blue/additions/stoneWheelTR.svg";
import stoneBL from "../../../../../../assets/wheelsThemes/blue/additions/stoneWheelBL.svg";
import stoneBR from "../../../../../../assets/wheelsThemes/blue/additions/stoneWheelBR.svg";
import AdaptiveMuiTooltip from "../../../../../../components/UiKit/MuiTooltip/AdaptiveMuiTooltip";
import GlobalSvgSelector from "../../../../../../components/GlobalSvgSelector/GlobalSvgSelector";
import MuiTooltip from "../../../../../../components/UiKit/MuiTooltip/MuiTooltip";
import { setCurrentEditPrelandingObj } from "../../../../../../redux/slices/flowSettingsSlice";
import customPrelandImg from "../../../../../../assets/img/customPrelandMockup.png";

const PrelandingCard = ({
  name,
  id,
  style,
  prelanding_type,
  wheelStylesList,
  currentPreland,
  formik,
  setContent,
  obj,
  updateEditPrelandingObj,
}) => {
  const currentStyle = wheelStylesList.find((item) => item.id === style);

  let pointerStyle = {};

  if (currentStyle?.wheel?.includes("purple")) {
    pointerStyle = { width: 30, height: 30, top: -5 };
  }

  if (
    currentStyle?.wheel?.includes("leprechaun") ||
    currentStyle?.wheel?.includes("zeus") ||
    currentStyle?.wheel?.includes("wolf") ||
    currentStyle?.wheel?.includes("bulls") ||
    currentStyle?.wheel?.includes("egypt") ||
    currentStyle?.wheel?.includes("tiger")
  ) {
    pointerStyle = { width: 25, height: 20, top: 0 };
  }

  if (
    currentStyle?.wheel?.includes("plinko") ||
    currentStyle?.wheel?.includes("orange")
  ) {
    pointerStyle = { width: 30, height: 25, top: 0 };
  }

  if (
    currentStyle?.wheel?.includes("aviator") ||
    currentStyle?.wheel?.includes("red") ||
    currentStyle?.wheel?.includes("fruits")
  ) {
    pointerStyle = { width: 30, height: 25, top: 0 };
  }

  if (currentStyle?.wheel?.includes("bonanza")) {
    pointerStyle = { width: 30, height: 25, top: 0 };
  }

  return (
    <div className={s.prelandCardWrapper}>
      <div
        className={`${s.prelandCard} ${currentPreland === id ? s.active : ""}`}
      >
        <div className={`${s.header} ${prelanding_type === 4 ? s.custom : ""}`}>
          <button
            className={s.editPrelandButton}
            onClick={() => {
              updateEditPrelandingObj(obj);
              setContent("edit");
            }}
          >
            <MuiTooltip title="Редактировать прелендинг">
              <GlobalSvgSelector id="edit-icon" />
            </MuiTooltip>
          </button>
          <AdaptiveMuiTooltip title={name}>
            <p className={s.prelandName}>{name}</p>
          </AdaptiveMuiTooltip>
        </div>

        {prelanding_type === 1 ? (
          <div
            className={s.body}
            onClick={() => {
              formik.setFieldValue("prelanding_id", id);
            }}
            style={{
              backgroundImage: `url(${currentStyle?.background})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
            }}
            customPrelandImg
          >
            {currentStyle?.wheel?.includes("blue") && (
              <img
                src={bomb}
                style={{
                  width: 60,
                  height: 70,
                  position: "absolute",
                  bottom: -20,
                  left: -10,
                  zIndex: 20,
                }}
              />
            )}

            <div className={s.title}>DEFAULT TITLE</div>
            <div className={s.wheelWrapper}>
              {currentStyle?.wheel?.includes("blue") && (
                <>
                  <img
                    src={stoneTL}
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      zIndex: 10,
                      width: 55,
                      height: 55,
                    }}
                  />
                  <img
                    src={stoneTR}
                    style={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                      zIndex: 10,
                      width: 55,
                      height: 55,
                    }}
                  />
                  <img
                    src={stoneBL}
                    style={{
                      position: "absolute",
                      bottom: 0,
                      left: 0,
                      zIndex: 10,
                      width: 55,
                      height: 55,
                    }}
                  />
                  <img
                    src={stoneBR}
                    style={{
                      position: "absolute",
                      bottom: 0,
                      right: 0,
                      zIndex: 10,
                      width: 55,
                      height: 55,
                    }}
                  />
                </>
              )}

              <img
                style={pointerStyle}
                className={s.pointer}
                src={currentStyle?.pointer}
                alt="pointer"
              />
              <img
                className={s.wheelImg}
                src={currentStyle?.wheel}
                alt="wheel"
              />
            </div>
            <div className={s.btnWrapper}>
              <span>BUTTON</span>
              <img src={currentStyle?.button} alt="button" />
            </div>
          </div>
        ) : (
          <div
            className={s.customBody}
            onClick={() => {
              formik.setFieldValue("prelanding_id", id);
            }}
          >
            <img src={customPrelandImg} alt="Custom Preland" />
          </div>
        )}
      </div>
    </div>
  );
};

export default PrelandingCard;
