import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import $api from "../../api/url";
import {
  ABORTED_ERROR,
  prelandTypeList,
  wordPressOptions,
} from "../../utils/vars/staticVariables";
import { errorMessage } from "../../api/helpers/scripts";
import axios from "axios";
import { fetchClicksStats } from "./statisticsSlice";

export const getFlowById = createAsyncThunk("flow/getFlowById", async (id) => {
  try {
    const response = await $api.get(`/configuration/flows/${id}/`);

    return response.data;
  } catch (error) {
    throw error;
  }
});

export const deleteFlow = createAsyncThunk(
  "flow/deleteFlow",
  async ({ id }) => {
    try {
      const response = await $api.delete(`/configuration/flows/${id}/`);

      const { data, status } = response;

      return { data, status };
    } catch (error) {
      const { data, status } = error.response;

      return { data, status };
    }
  },
);

export const setNote = createAsyncThunk("flow/createNote", async (noteData) => {
  try {
    const response = await $api.post(
      "/configuration/flow/create/set_notes/",
      noteData,
    );
    const { data, status } = response;

    return { data, status };
  } catch (error) {
    const { data, status } = error.response;

    return { data, status };
  }
});

export const changeFlowRedirectById = createAsyncThunk(
  "flow/changeFlowRedirectById",
  async ({ sendDataObj, onSuccessAction, onErrorAction }) => {
    try {
      const response = await $api.post(
        "/configuration/flow/change-redirect-by-id/",
        sendDataObj,
      );
      onSuccessAction();
    } catch (error) {
      const errorMessage = error.response.data.details;

      console.log("errorMessage", errorMessage);

      if (errorMessage === "Не переданы обязательные параметры") {
        onErrorAction("Передайте минимум один редирект");
        throw new Error(errorMessage);
      } else {
        onErrorAction(errorMessage);
        throw error;
      }
    }
  },
);

export const fetchFlowsList = createAsyncThunk(
  "flow/fetchFlowsList",
  async (params, { signal, rejectWithValue }) => {
    try {
      const copiedParams = new URLSearchParams(params);

      const { data } = await $api.get("/configuration/flows/?" + copiedParams, {
        signal,
      });
      return data;
    } catch (error) {
      let err = errorMessage(error);

      if (axios.isCancel(error)) {
        return rejectWithValue();
      }

      throw new Error(err);
    }
  },
);

export const fetchFlowList = createAsyncThunk(
  "application/fetchFlowList",
  async ({ page, search, sub7, domains, applications }) => {
    try {
      // if (currentController) {
      //     currentController.abort();
      // }

      // const controller = new AbortController();
      // currentController = controller;

      let url = `/configuration/flows/?page=${page}&page_size=7&search=${search}`;

      if (sub7.length > 0) {
        url += `&sub7=${sub7}`;
      }

      if (domains.length > 0) {
        url += `&domain_address=${domains}`;
      }

      if (applications.length > 0) {
        url += `&application_name=${applications}`;
      }

      // const response = await $api.get(url, { signal: controller.signal });
      const response = await $api.get(url);
      return response.data;
    } catch (error) {
      if (error.name === "AbortError") {
        console.log("Request was aborted");
        return; // Запит було скасовано, тому немає необхідності викидати помилку
      }

      const errorMessage = error.response.data.detail;

      if (errorMessage === "Invalid page.") {
        throw new Error(errorMessage);
      } else {
        throw error;
      }
    }
  },
);

export const duplicateFlow = createAsyncThunk(
  "flow/duplicateFlow",
  async (dataObj) => {
    try {
      const response = await $api.post(
        "/configuration/flow/duplicate/",
        dataObj,
      );

      const { data, status } = response;

      return { data, status };
    } catch (error) {
      const { data, status } = error.response;
      return { data, status };
    }
  },
);

export const testFlow = createAsyncThunk(
  "flow/testFlow",
  async ({ payload, onSuccessAction, onErrorAction }) => {
    try {
      const response = await $api.post(
        "/configuration/flow/flow-testing/",
        payload,
      );

      const details = response?.data?.details;

      if (
        details === "Режим тестирования запущен на 3 минуты." ||
        details === "Режим тестирования был выключен."
      ) {
        onSuccessAction();
      }
      return response.data;
    } catch (error) {
      const errorMessage = error.response.data.details;

      if (
        errorMessage ===
        "Заполните настройки, для того чтобы включить режим тестирования."
      ) {
        onErrorAction("Заполните настройки, чтобы включить режим тестирования");
        throw new Error(errorMessage);
      }

      if (
        errorMessage === "Режим тестирования был выключен, обновите страницу"
      ) {
        onErrorAction(errorMessage);
        throw new Error(errorMessage);
      }

      onErrorAction("Произошла ошибка при включении режима тестирования");
      throw error;
    }
  },
);

export const updateFlowSettings = createAsyncThunk(
  "pixelApi/postPixelApiItem",
  async ({ data, onSuccessAction, onErrorAction }) => {
    console.log("data in request", data);

    try {
      const response = await $api.patch(
        "/configuration/flow/create/set_settings/",
        data,
      );

      onSuccessAction(response.data);
      return response.data;
    } catch (error) {
      const errorMessage = error.response.data.details;

      // console.log("errorMessage errrr,", errorMessage);

      onErrorAction(errorMessage);
      throw error;
    }
  },
);

export const addFlowPrelanding = createAsyncThunk(
  "prelanding/addFlowPrelanding",
  async ({ data, onSuccessAction, onErrorAction }) => {
    try {
      const response = await $api.post(
        "/configuration/flow/create/set_prelanding/",
        data,
      );

      onSuccessAction(response.data);
      return response.data;
    } catch (error) {
      onErrorAction("Произошла ошибка");
      throw error;
    }
  },
);

export const changeFlowPrelanding = createAsyncThunk(
  "flow/changeFlowPrelanding",
  async ({ data, onSuccessAction, onErrorAction }) => {
    try {
      const response = await $api.post("/prelanding/change-prelanding/", data);

      onSuccessAction();
      return response.data;
    } catch (error) {
      const errorMessage = error.response.data.details;

      if (errorMessage === "Вы выбрали тот же прелендинг") {
        onErrorAction("Вы выбрали тот же прелендинг");
      } else {
        onErrorAction("Произошла ошибка");
      }

      // console.log('errorMessage', errorMessage)

      throw error;
    }
  },
);

const flowSlice = createSlice({
  name: "flow",
  initialState: {
    flowObj: null,
    dataLoading: false,
    changeRedirectLoading: false,
    flowListLoading: true,
    flowList: [],
    flowsList: [],
    totalCount: null,
    currentPage: 1,
    selectedRedirectors: [],
    duplicateFlowPending: false,

    fetchObjLoading: false,

    setNotePending: false,

    offerLink: "",
    status: "Filter",
    whitePageUrl: "",
    wordPressLabel: null,
    wordPressName: null,
    cloacaType: "gp",
    facebook: "fb",
    selectedGeos: [],
    geoSelectInput: [],

    currentFlowObjId: null,

    currentEditRedirectorId: null,

    fb_type: "fb_lite",

    wordPressOption: null,

    ip: [],
    ipList: [],
    allowed_ips: [],

    prelandTypeLabel: prelandTypeList[0].label,
    prelandTypeName: prelandTypeList[0].name,

    currentFlowPrelandOption: null,
    currentPrelandId: null,

    wheelStylesList: [],
    wheelStylesLoading: false,

    prelandingObj: null,
    prelandingObjId: null,
    prelandingObjLoading: false,

    customPrelandName: "",

    selectedImage: "",
    spinCount: 1,
    prelandingName: "",
    prelandTitle: "Welcome",
    spinButtonText: "Spin",
    respinButtonText: "Spin",
    popupTitle: "OK",
    popupText: "You get {bonus}",
    popupButtonText: "Get",
    styleId: 1,
    currentWheel: {
      id: 1,
      background: "/static/media/blueBg.91bfaf22c80389297f4d.png",
      wheel: "/static/media/blueWheelImg.3361f562dbe24aa60733bdea4e55bb31.svg",
      button: "/static/media/blueWheelBtn.058b645cc9b0dd225d251d19e8725a67.svg",
      pointer:
        "/static/media/blueWheelPointer.c6bf6fe15884f8ddb281bb19b71d1484.svg",
      popup: "/static/media/bluePopup.0583c1635b8553b21ae568c308ed55c2.svg",
    },

    sectors: new Array(8).fill().map((_, index) => ({
      name: `Сектор ${index}`,
      value: "",
      is_win: index <= 0,
    })),
    prelandingStatus: true,
  },
  reducers: {
    setCustomPrelandName: (state, action) => {
      state.customPrelandName = action.payload;
    },
    addAllowedIp: (state, action) => {
      state.allowed_ips = [...state.allowed_ips, action.payload];
    },

    setWordPressOption: (state, action) => {
      state.wordPressOption = action.payload;
    },

    setAllowedIps: (state, action) => {
      state.allowed_ips = action.payload;
    },

    setFbType: (state, action) => {
      state.fb_type = action.payload;
    },

    setIp: (state, action) => {
      state.ip = action.payload;
    },
    setIpList: (state, action) => {
      state.ipList = action.payload;
    },
    setPrelandTypeLabel: (state, action) => {
      state.prelandTypeLabel = action.payload;
    },
    setPrelandTypeName: (state, action) => {
      state.prelandTypeName = action.payload;
    },

    setCurrentFlowObjId: (state, action) => {
      state.currentFlowObjId = action.payload;
    },
    setCurrentEditRedirectorId: (state, action) => {
      state.currentEditRedirectorId = action.payload;
    },
    setFlowObj: (state) => {
      state.flowObj = null;
      state.dataLoading = false;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setTotalCount: (state, action) => {
      state.totalCount = action.payload;
    },
    setSelectedRedirectors: (state, action) => {
      state.selectedRedirectors = action.payload;
    },

    setOfferLink: (state, action) => {
      state.offerLink = action.payload;
    },

    setStatus: (state, action) => {
      state.status = action.payload;
    },

    setFacebook: (state, action) => {
      state.facebook = action.payload;
    },

    setSelectedGeos: (state, action) => {
      state.selectedGeos = action.payload;
    },

    setGeoSelectInput: (state, action) => {
      state.geoSelectInput = action.payload;
    },

    setCloacaType: (state, action) => {
      state.cloacaType = action.payload;
    },

    setWhitePageUrl: (state, action) => {
      state.whitePageUrl = action.payload;
    },

    setWordPressLabel: (state, action) => {
      state.wordPressLabel = action.payload;
    },

    setWordPressName: (state, action) => {
      state.wordPressName = action.payload;
    },

    setSelectedImage: (state, action) => {
      state.selectedImage = action.payload;
    },

    // setSectors: (state, action) => {
    //     state.selectedImage = action.payload
    // },

    setSpinCount: (state, action) => {
      state.spinCount = action.payload;
    },

    setPrelandingName: (state, action) => {
      state.prelandingName = action.payload;
    },

    setPrelandTitle: (state, action) => {
      state.prelandTitle = action.payload;
    },

    setSpinButtonText: (state, action) => {
      state.spinButtonText = action.payload;
    },

    setRespinButtonText: (state, action) => {
      state.respinButtonText = action.payload;
    },

    setPopupTitle: (state, action) => {
      state.popupTitle = action.payload;
    },

    setPopupText: (state, action) => {
      state.popupText = action.payload;
    },

    setPopupButtonText: (state, action) => {
      state.popupButtonText = action.payload;
    },

    setStyleId: (state, action) => {
      state.styleId = action.payload;
    },

    setCurrentWheel: (state, action) => {
      state.currentWheel = action.payload;
    },

    setPrelandingStatus: (state, action) => {
      state.prelandingStatus = action.payload;
    },

    updateSectorValue: (state, action) => {
      const { index, newValue } = action.payload;
      state.sectors = state.sectors.map((sector, sectorIndex) =>
        sectorIndex === index ? { ...sector, value: newValue } : sector,
      );
    },

    setSectors: (state, action) => {
      state.sectors = action.payload; // Assuming action.payload is the updated sectors array
    },

    setCurrentPrelandId: (state, action) => {
      state.currentPrelandId = action.payload; // Assuming action.payload is the updated sectors array
    },

    setPrelandingObjId: (state, action) => {
      state.prelandingObjId = action.payload; // Assuming action.payload is the updated sectors array
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFlowById.pending, (state) => {
        // state.redirectorListLoading = true;
        state.fetchObjLoading = true;
      })
      .addCase(getFlowById.fulfilled, (state, action) => {
        state.flowObj = action.payload;

        const prelanding = action.payload?.prelanding;

        state.prelandingObj = prelanding || state.prelandingObj;
        state.prelandingObjId = prelanding?.id || state.prelandingObjId;

        state.prelandTitle = "Welcome";
        state.selectedImage = "";
        state.spinCount = 1;
        state.prelandingName = "";
        state.popupText = "You get {bonus}";
        state.respinButtonText = "Spin";
        state.spinButtonText = "Spin";
        state.sectors = new Array(8).fill().map((_, index) => ({
          name: `Сектор ${index}`,
          value: "",
          is_win: index <= 0,
        }));
        state.prelandingStatus = true;
        state.styleId = 1;
        state.popupButtonText = "Get";
        state.popupTitle = "OK";

        state.currentFlowObjId = action.payload?.id;
        const settings = action.payload?.settings;
        state.offerLink = settings?.url || "";
        state.status = settings?.status || "Filter";
        state.selectedGeos = [];
        state.geoSelectInput = [];
        // state.ip = settings?.allowed_ips || ''
        state.ip = settings?.allowed_ips || [];
        // state.fb_type = settings?.cloack || "fb_lite";

        state.allowed_ips = settings
          ? settings?.allowed_ips?.map((ip) => ({ value: ip, label: ip }))
          : [];

        // state.allowed_ips = settings?.allowed_ips || []

        if (
          settings?.type_of_cloaca === "gp" ||
          settings?.type_of_cloaca === "ap"
        ) {
          state.whitePageUrl = settings?.url_for_whitepage_app;
        } else {
          state.whitePageUrl = "";
        }

        if (settings?.type_of_cloaca === "wp") {
          const foundOption = wordPressOptions.find(
            (item) => item.value === settings?.url_for_whitepage_app,
          );
          // state.wordPressLabel = settings?.url_for_whitepage_app || null;

          if (foundOption) {
            state.wordPressOption = foundOption;
            // state.wordPressName = beautyData.name;
            // setWordPressName(beautyData.name)
          }
        }

        if (settings?.geo) {
          settings?.geo?.forEach((obj) => {
            state.selectedGeos.push(obj.code);
            state.geoSelectInput.push({ value: obj.code, label: obj.name });
          });
        }

        // state.wordPressLabel = settings?.
        // state.wordPressName = settings?.

        // state.cloacaType = settings?.type_of_cloaca

        if (settings?.type_of_cloaca) {
          state.cloacaType = settings?.type_of_cloaca;
        } else {
          state.cloacaType = "gp";
        }

        state.facebook = "fb";
        // state.selectedGeos = settings?.
        // state.geoSelectInput = settings?.

        if (action.payload.redirector) {
          state.selectedRedirectors = action.payload.redirector;
        } else {
          state.selectedRedirectors = [];
        }
        state.fetchObjLoading = false;
        // state.redirectorListLoading = false;
        // state.popupActive = true
      })
      .addCase(getFlowById.rejected, (state, action) => {
        state.fetchObjLoading = false;
        // state.redirectorListLoading = false;
      })

      .addCase(fetchFlowsList.pending, (state) => {
        state.flowsListPending = true;
        state.flowsList = [];
      })
      .addCase(fetchFlowsList.fulfilled, (state, { payload }) => {
        const { results, count } = payload;

        state.flowsList = results;
        state.totalCount = count;
        state.flowsListPending = false;
      })
      .addCase(fetchFlowsList.rejected, (state, { error }) => {
        const { message } = error;

        state.flowsListPending = message === ABORTED_ERROR;
        state.flowsList = [];

        if (message !== ABORTED_ERROR) {
          state.totalCount = 0;
        }
      })

      .addCase(fetchFlowList.pending, (state) => {
        state.flowListLoading = true;
      })
      .addCase(fetchFlowList.fulfilled, (state, action) => {
        state.flowList = action.payload.results;
        state.totalCount = action.payload.count;
        state.flowListLoading = false;
      })
      .addCase(fetchFlowList.rejected, (state, action) => {
        if (action.error?.message === "Invalid page.") {
          state.currentPage = 1;
        }

        console.log("action.error", action.error);

        state.flowList = [];
        state.totalCount = null;
        state.flowListLoading = false;
      })

      ////////////////////////////////////
      .addCase(updateFlowSettings.pending, (state) => {
        state.dataLoading = true;
      })
      .addCase(updateFlowSettings.fulfilled, (state, action) => {
        state.dataLoading = false;
      })
      .addCase(updateFlowSettings.rejected, (state, action) => {
        state.dataLoading = false;
      })

      ////////////////////////////////////
      .addCase(setNote.pending, (state) => {
        state.setNotePending = true;
      })
      .addCase(setNote.fulfilled, (state, action) => {
        state.setNotePending = false;
      })
      .addCase(setNote.rejected, (state, action) => {
        state.setNotePending = false;
      })

      ////////////////////////////////////
      .addCase(changeFlowRedirectById.pending, (state) => {
        state.changeRedirectLoading = true;
      })
      .addCase(changeFlowRedirectById.fulfilled, (state, action) => {
        // action.payload.onSuccessAction()
        state.changeRedirectLoading = false;
      })
      .addCase(changeFlowRedirectById.rejected, (state) => {
        state.changeRedirectLoading = false;
      })

      ////////////////////////////////////
      .addCase(duplicateFlow.pending, (state) => {
        state.duplicateFlowPending = true;
      })
      .addCase(duplicateFlow.fulfilled, (state) => {
        state.duplicateFlowPending = false;
      })
      .addCase(duplicateFlow.rejected, (state) => {
        state.duplicateFlowPending = false;
      });
  },
});

export const {
  setFlowObj,
  setCurrentPage,
  setSelectedRedirectors,
  setOfferLink,
  setStatus,
  setFacebook,
  setSelectedGeos,
  setGeoSelectInput,
  setCloacaType,
  setWhitePageUrl,
  setWordPressLabel,
  setWordPressName,
  setCurrentEditRedirectorId,
  setCurrentFlowObjId,
  setPrelandTypeLabel,
  setPrelandTypeName,
  setIp,
  setAllowedIps,
  addAllowedIp,
  setIpList,
  setCustomPrelandName,
  setSelectedImage,
  setSectors,
  setSpinCount,
  setPrelandingName,
  setPrelandTitle,
  setSpinButtonText,
  setRespinButtonText,
  setPopupTitle,
  setPopupText,
  setPopupButtonText,
  setStyleId,
  setCurrentWheel,
  setPrelandingStatus,
  updateSectorValue,
  setCurrentPrelandId,
  setPrelandingObjId,
  setTotalCount,
  setWordPressOption,
  setFbType,
} = flowSlice.actions;

export default flowSlice.reducer;

export const flowSelector = (state) => state.flow;
