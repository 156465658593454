import { params, sortDateOptions } from "../../utils/vars/params";
import dayjs from "dayjs";

export function getTableDefaultParams({
  searchParams,
  ordering,
  defaultPageSize,
  sort,
  purpose,
}) {
  const getParams = new URLSearchParams(searchParams);
  if (!getParams.get(params.page)) getParams.set(params.page, "1");
  if (!getParams.get(params.page_size) && defaultPageSize)
    getParams.set(params.page_size, defaultPageSize);

  return getParams;
}

export function getDefaultParams({
  searchParams,
  ordering,
  defaultPageSize,
  sort,
  purpose,
}) {
  const getParams = new URLSearchParams(searchParams);
  if (!getParams.get(params.page)) getParams.set(params.page, "1");
  if (!getParams.get(params.ordering) && ordering)
    getParams.set(params.ordering, ordering);
  if (!getParams.get(params.page_size) && defaultPageSize)
    getParams.set(params.page_size, defaultPageSize);

  if (purpose) {
    getParams.set(params.purpose, purpose);
  }

  if (
    // !getParams.get(params.sort) &&
    !getParams.get(params.created_at_after) &&
    !getParams.get(params.created_at_before)
  ) {
    if (!getParams.get(params.sort) && sort)
      getParams.set(params.sort, sortDateOptions[0].value);
    // getParams.set(params.sort, sortDateOptions[0].value);
  } else {
    getParams.delete(params.sort);
  }

  // if (!getParams.get(params.sort) && sort)
  //   getParams.set(params.sort, sortDateOptions[0].value);

  const createdAtBefore = getParams.get(params.created_at_before);
  if (createdAtBefore && dayjs(createdAtBefore, "YYYY-MM-DD", true).isValid()) {
    const newDate = dayjs(createdAtBefore).add(1, "day").format("YYYY-MM-DD");
    getParams.set(params.created_at_before, newDate);
  }

  return getParams;
}

export const errorMessage = (error) => {
  return (
    error.response?.data?.detail ||
    error.response?.data?.details ||
    "An unexpected error occurred"
  );
};
