import { useSnackbar } from "notistack";

const useCustomSnackbar = () => {
  const { enqueueSnackbar } = useSnackbar();

  return (message, variant = "default", persist = false) => {
    enqueueSnackbar(message, { variant, persist });
  };
};

export default useCustomSnackbar;
