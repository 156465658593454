export const hasNonEmptyParams = (searchParams) => {
  let hasNonEmptyParam = false;

  for (let param of searchParams.keys()) {
    if (
      param !== "page" && // Виключення "page"
      param !== "purpose" && // Виключення "purpose"
      searchParams.get(param) &&
      searchParams.get(param).trim() !== ""
    ) {
      hasNonEmptyParam = true;
      break;
    }
  }

  return hasNonEmptyParam;
};
