import { useSelector } from "react-redux";
import { financeSelector } from "../../../../redux/slices/financeSlice";
import EmptyList from "../../../../components/EmptyList/EmptyList";
import s from "../transactions.module.scss";
import CustomSkeleton from "../../../../components/CustomSkeleton/CustomSkeleton";
import { convertDate } from "../../../../utils/helpers/formatTime";
import { Link } from "react-router-dom";
import MuiTooltip from "../../../../components/UiKit/MuiTooltip/MuiTooltip";
import GlobalSvgSelector from "../../../../components/GlobalSvgSelector/GlobalSvgSelector";
import React from "react";

const WriteOffOption = () => {
  const { transactionsLoading, transactionsList, totalCount } =
    useSelector(financeSelector);

  return (
    <>
      {totalCount === 0 && !transactionsLoading ? (
        <EmptyList />
      ) : transactionsLoading ? (
        <section className={s.body}>
          <table>
            <thead>
              <tr>
                <th>Дата</th>
                <th>Инсталы</th>
                <th>Сумма</th>
                <th>Услуга</th>
                <th>Отчет</th>
              </tr>
            </thead>
          </table>
          <div className={s.skeletonWrapper}>
            <CustomSkeleton styles={s.skeletonTable} />
          </div>
        </section>
      ) : (
        <section className={s.body}>
          <table>
            <thead>
              <tr>
                <th>Дата</th>
                <th>Инсталы</th>
                <th>Сумма</th>
                <th>Услуга</th>
                <th>Отчет</th>
              </tr>
            </thead>
            <tbody>
              {transactionsList.map(
                ({ id, created_at, amount, description, installs }) => (
                  <tr key={id}>
                    <td className={s.dateTd}>
                      <span>{convertDate(created_at, "DMY")}</span>{" "}
                      <span>{convertDate(created_at, "HM")}</span>
                    </td>
                    <td>{installs ? installs : ""}</td>
                    <td>$ {amount}</td>
                    <td>{description}</td>

                    <td>
                      <div className={s.actionsWrapper}>
                        {description.includes("Списание средств за домен") ? (
                          ""
                        ) : (
                          <div className={s.actionsContainer}>
                            <div className={s.actions}>
                              <Link
                                to={`/stats/clicks?created_at_after=${convertDate(created_at, "YMD")}&created_at_before=${convertDate(created_at, "YMD")}`}
                                className={`${s.manageTableFillBtn} ${s.statsBtn}`}
                              >
                                <MuiTooltip title="Cтатистика по кликам">
                                  <GlobalSvgSelector id="new-stats-icon" />
                                </MuiTooltip>
                              </Link>
                            </div>
                          </div>
                        )}
                      </div>
                    </td>
                  </tr>
                ),
              )}
            </tbody>
          </table>
        </section>
      )}
    </>
  );
};

export default WriteOffOption;
