import React, { useEffect, useState } from "react";
import s from "./prelanding.module.scss";
import GradientBorder from "../../../../../components/Blocks/GradientBorder/GradientBorder";
import ChoosePrelanding from "./TabContent/ChoosePrelanding/ChoosePrelanding";
import CreatePrelanding from "./TabContent/CreatePrelanding/CreatePrelanding";
import UploadPrelanding from "./TabContent/UploadPrelanding/UploadPrelanding";
import EditPrelanding from "./TabContent/EditPrelanding/EditPrelanding";
import { useDispatch } from "react-redux";
import UploadPrelandingPopup from "./UploadPrelandingPopup/UploadPrelandingPopup";
import GlobalSvgSelector from "../../../../../components/GlobalSvgSelector/GlobalSvgSelector";

const Prelanding = ({
  choosePrelandingFormik,
  createPrelandingFormik,
  resetCreatePrelandingForm,
  uploadPrelandingFormik,
  content,
  setContent,
  uploadPrelandingPopupIsActive,
  setUploadPrelandingPopupIsActive,
}) => {
  return (
    <div className={s.prelandingWrapper}>
      <UploadPrelandingPopup
        active={uploadPrelandingPopupIsActive}
        setActive={setUploadPrelandingPopupIsActive}
        icon={<GlobalSvgSelector id="prelandings" />}
        title="Загрузить прелендинг"
        confirmBtnText="Подтвердить"
        formik={uploadPrelandingFormik}
      />
      <div className={s.contentButtonsContainer}>
        <div className={s.contentButtonsWrapper}>
          <button
            onClick={() => setContent("choose")}
            className={`${s.contentBtn} ${content === "choose" ? s.active : ""}`}
          >
            Выбрать
          </button>
          <button
            onClick={() => setContent("create")}
            className={`${s.contentBtn} ${content === "create" ? s.active : ""}`}
          >
            Создать
          </button>
          <button
            onClick={() => setUploadPrelandingPopupIsActive(true)}
            className={`${s.contentBtn} ${content === "upload" ? s.active : ""}`}
          >
            Загрузить
          </button>
          {content === "edit" && (
            <button
              onClick={() => setContent("edit")}
              className={`${s.contentBtn} ${content === "edit" ? s.active : ""}`}
            >
              Редактировать
            </button>
          )}
        </div>
      </div>
      <GradientBorder className={s.gradientBorderCustom} />
      {content === "choose" && (
        <ChoosePrelanding
          formik={choosePrelandingFormik}
          content={content}
          setContent={setContent}
        />
      )}
      {content === "upload" && (
        <UploadPrelanding formik={uploadPrelandingFormik} />
      )}
      {content === "create" && (
        <CreatePrelanding
          formik={createPrelandingFormik}
          resetForm={resetCreatePrelandingForm}
        />
      )}
      {content === "edit" && (
        <EditPrelanding
          setContent={setContent}
          choosePrelandingFormik={choosePrelandingFormik}
        />
      )}
    </div>
  );
};

export default Prelanding;
