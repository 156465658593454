import { useDispatch, useSelector } from "react-redux";
import {
  globalDataSelector,
  setAdaptiveMenu,
} from "../../../redux/slices/globalDataSlice";
import { Link, useSearchParams } from "react-router-dom";
import useGetSearchParams from "../../../hooks/useGetSearchParams";
import useSetSearchParams from "../../../hooks/useSetSearchParams";
import { useDebounce } from "../../../hooks/useDebounce";
import { page, search } from "../Statistics/ClicksStats/ClicksStats";
import React, { useCallback, useEffect } from "react";
import {
  deletePrelanding,
  fetchPrelandingsList,
} from "../../../redux/slices/prelandingSlice";
import { getTableDefaultParams } from "../../../api/helpers/scripts";
import {
  INVALID_PAGE_ERROR,
  snackbar_error,
  snackbar_info,
  snackbar_success,
} from "../../../utils/vars/staticVariables";
import { toast } from "react-hot-toast";
import { handleContinue } from "../../../utils/helpers/scrollTop";
import s from "./prelandings.module.scss";
import { setTopUpBalanceActive } from "../../../redux/slices/financeSlice";
import GlobalSvgSelector from "../../../components/GlobalSvgSelector/GlobalSvgSelector";
import GradientSearch from "../../../components/partials/GradientSearch/GradientSearch";
import EmptyList from "../../../components/EmptyList/EmptyList";
import CustomSkeleton from "../../../components/CustomSkeleton/CustomSkeleton";
import PrelandingTd from "./PrelandingTd/PrelandingTd";
import Pagination from "../../../components/Pagination/Pagination";
import { params } from "../../../utils/vars/params";
import useCustomSnackbar from "../../../hooks/useCustomSnackbar";
import { useSnackbar } from "notistack";

const Prelandings = () => {
  const dispatch = useDispatch();

  const { prelandingsList, totalCount, prelandingsPending } = useSelector(
    (state) => state.prelanding,
  );

  const { isCollapsedSidebar, isAdaptiveMenuActive } =
    useSelector(globalDataSelector);

  const [searchParams, setSearchParams] = useSearchParams();

  const { search: searchValue, page: pageValue } = useGetSearchParams();

  const updateSearchParams = useSetSearchParams(setSearchParams);

  const showSnackbar = useCustomSnackbar();
  const { closeSnackbar } = useSnackbar();

  const [name, setName] = useDebounce((value) => {
    if (value) {
      updateSearchParams(search, value);
    } else {
      updateSearchParams(search, value, true);
    }
  }, 600);

  useEffect(() => {
    if (!searchValue) {
      setName("");
    } else {
      setName(searchValue);
    }
  }, [searchParams]);

  const fetchPrelandingsData = useCallback(() => {
    const promise = dispatch(
      fetchPrelandingsList(
        getTableDefaultParams({
          searchParams,
          defaultPageSize: 25,
        }),
      ),
    );

    promise.unwrap().catch((err) => {
      if (err?.message === INVALID_PAGE_ERROR) {
        updateSearchParams(page, 1);
      }
    });

    return promise;
  }, [dispatch, searchParams, updateSearchParams]);

  useEffect(() => {
    const promise = fetchPrelandingsData();

    return () => {
      promise.abort();
    };
  }, [dispatch, searchParams]);

  const onDeleteHandler = useCallback(
    async (id) => {
      const pendingDeleteSnackbar = showSnackbar(
        "Обработка запроса удаления ...",
        snackbar_info,
        true,
      );

      const prelandingDeleteResult = await dispatch(deletePrelanding(id));

      if (prelandingDeleteResult?.payload?.status === 204) {
        fetchPrelandingsData();
        closeSnackbar(pendingDeleteSnackbar);
        showSnackbar(`Прелендинг был удален`, snackbar_success);
      } else {
        closeSnackbar(pendingDeleteSnackbar);
        showSnackbar(`Не удалось удалить прелендинг`, snackbar_error);
      }
    },
    [closeSnackbar, dispatch, fetchPrelandingsData, showSnackbar],
  );

  return (
    <div
      className={`${s.prelandingsTableWrapper} ${isCollapsedSidebar === "true" ? s.collapsedSidebar : ""}`}
    >
      <div className={s.pageHeaderWrapper}>
        <div className={s.pageHeaderContainer}>
          <div className={s.staticElements}>
            <div className={s.leftSideWrapper}>
              <button
                className={s.walletBtn}
                onClick={() => {
                  dispatch(setAdaptiveMenu(false));
                  dispatch(setTopUpBalanceActive(true));
                }}
              >
                <GlobalSvgSelector id="wallet" />
              </button>
              <Link className={s.walletBtn} to="/account">
                <GlobalSvgSelector id="account-gear-icon" />
              </Link>
            </div>

            <button
              className={s.menuBtn}
              onClick={() => dispatch(setAdaptiveMenu(!isAdaptiveMenuActive))}
            >
              <GlobalSvgSelector id="burger-menu" />
            </button>
          </div>

          <div className={s.changingElements}>
            <GradientSearch value={name} setValue={setName} />
            <Link to="/prelandings/create" className={s.primaryBtn}>
              <GlobalSvgSelector id="plus-button" />
              Создать
            </Link>
          </div>
        </div>
      </div>

      <div className={s.pageContent}>
        <div className={s.pageInnerContent}>
          {totalCount === 0 && !prelandingsPending ? (
            <EmptyList />
          ) : prelandingsPending ? (
            <section className={s.body}>
              <table>
                <thead>
                  <tr>
                    <th>Название</th>
                    <th>Тип</th>
                    <th>Действия</th>
                  </tr>
                </thead>
              </table>
              <div className={s.skeletonWrapper}>
                <CustomSkeleton styles={s.skeletonTable} />
              </div>
            </section>
          ) : (
            <section className={s.body}>
              <table>
                <thead>
                  <tr>
                    <th>Название</th>
                    <th>Тип</th>
                    <th>Действия</th>
                  </tr>
                </thead>
                <tbody>
                  {prelandingsList.map((item) => (
                    <PrelandingTd
                      key={item.id}
                      item={item}
                      onDeleteHandler={onDeleteHandler}
                    />
                  ))}
                </tbody>
              </table>
            </section>
          )}
        </div>

        <div
          className={`${s.paginationContainer} ${isCollapsedSidebar === "true" ? s.collapsed : ""}`}
        >
          <div className={s.newRecordsCount}>
            Всего: {!prelandingsPending && <span>{totalCount}</span>}
          </div>
          {totalCount > 25 && (
            <Pagination
              currentPage={parseInt(pageValue) || 1}
              setCurrentPage={(page) => {
                updateSearchParams(params.page, page);
              }}
              total={totalCount}
              limit={25}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Prelandings;
