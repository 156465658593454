import React, {useRef, useEffect, useState} from 'react';
import s from './spinWheel.module.scss'


import lightDecorator from '../../../assets/wheelsThemes/blue/additions/lightDecorator.svg'

import stoneTL from '../../../assets/wheelsThemes/blue/additions/stoneWheelTL.svg'
import stoneTR from '../../../assets/wheelsThemes/blue/additions/stoneWheelTR.svg'
import stoneBL from '../../../assets/wheelsThemes/blue/additions/stoneWheelBL.svg'
import stoneBR from '../../../assets/wheelsThemes/blue/additions/stoneWheelBR.svg'

import bomb from '../../../assets/wheelsThemes/blue/additions/bombBackgroundBL.svg'
import fog from '../../../assets/wheelsThemes/red/additions/fogButtonBL.svg'

import purpleCenter from '../../../assets/wheelsThemes/purple/additions/decoratorWheelCenter.svg'

import decoratorButton from '../../../assets/wheelsThemes/purple/additions/decoratorButtonTC.svg'
import decoratorPointer from '../../../assets/wheelsThemes/purple/additions/pointerDecoratorBC.svg'
import decoratorPurpleBackground from '../../../assets/wheelsThemes/purple/additions/decoratorPurpleBackgroundBL.svg'

import coin from '../../../assets/wheelsThemes/orange/additions/coinDecorator.svg'
import decoratorCenterWheel from '../../../assets/wheelsThemes/orange/additions/decoratorCenterWheel.svg'

// src={currentWheel?.pointer}
import cwheel from '../../../assets/wheelsThemes/bonanza/bonanzaWheel.svg'
import cpointer from '../../../assets/wheelsThemes/bonanza/bonanzaPointer.svg'
import cbtn from '../../../assets/wheelsThemes/bonanza/bonanzaButton.svg'
import cbg from '../../../assets/wheelsThemes/leprechaun/leprechaunBg.jpg'
import cpopup from '../../../assets/wheelsThemes/leprechaun/leprechaunPopup.svg'




export const calculateTextRadiusAdjustment = (currentWheel) => {
    if (!currentWheel || !currentWheel.background) {
        return -10;
    }

    const { background } = currentWheel;

    if (background.includes('plinko')) {
        return -30;
    } else if (background.includes('red') || background.includes('fruits') || background.includes('aviator')) {
        return -26;
    } else if (background.includes('bonanza')) {
        return -4
    } else {
        return -10;
    }
}


export const determineTextColor = (currentWheel, index) => {
    const background = currentWheel?.background || "";
    const isEvenIndex = index % 2 === 0;

    if (background.includes('orange') || background.includes('blue')) {
        return isEvenIndex ? 'black' : 'white';
    } else if (
        background.includes('red') ||
        background.includes('purple') ||
        background.includes('fruits') ||
        background.includes('leprechaun') ||
        background.includes('bonanza') ||
        background.includes('zeus') ||
        background.includes('aviator') ||
        background.includes('wolf') ||
        background.includes('bulls') ||
        background.includes('tiger') ||
        background.includes('egypt') ||
        background.includes('plinko')) {
        return 'white';
    } else {
        return 'black';
    }
}

export const getPointerStyle = (currentWheel) => {
    if (!currentWheel || !currentWheel.background) {
        return null; // Return null if currentWheel or background is not defined
    }

    const { background } = currentWheel;
    const targets = ['purple', 'red', 'fruits', 'aviator']; // List of backgrounds that trigger the style

    // Check if any of the target backgrounds are included
    const styleTriggered = targets.some(target => background.includes(target));

    if (currentWheel.background.includes('bonanza')) {
        return {top: -2}
    }

    return styleTriggered ? { top: -10 } : null;
}

export const drawWheel = (ctx, radius, wheelNumbers, currentWheel, angle) => {
    const image = new Image();
    image.src = currentWheel?.wheel;
    // image.src = cwheel;
    const segments = wheelNumbers.length;
    const degreesPerSegment = 360 / segments;

    image.onload = () => {
        ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
        ctx.save();
        ctx.translate(radius, radius);
        ctx.rotate((Math.PI / 180) * (angle - 90));
        ctx.drawImage(image, -radius, -radius, radius * 2, radius * 2);

        wheelNumbers.forEach((number, index) => {
            const sectorAngle = degreesPerSegment * index - 27;
            const angleRad = (Math.PI / 180) * sectorAngle;

            const baseRadius = radius - 45;
            const textRadiusAdjustment = calculateTextRadiusAdjustment(currentWheel);
            const textRadius = baseRadius - textRadiusAdjustment;

            const x = textRadius * Math.cos(angleRad);
            const y = textRadius * Math.sin(angleRad);

            if (currentWheel.background.includes('bonanza')) {
                ctx.font = '17px Arial';
            } else {
                ctx.font = '20px Arial';
            }
            ctx.fillStyle = determineTextColor(currentWheel, index);

            ctx.save();
            ctx.translate(x, y);
            ctx.rotate((Math.PI / 180) * (sectorAngle + 180));
            ctx.fillText(number, 0, 0, textRadius - 10);
            ctx.restore();
        });

        ctx.restore();
    };
}


const SpinWheel = ({
                       currentWheel,
                       wheelNumbers,
                       prelandTitle,
                       spinButtonText,
                       respinButtonText,
                       winSector,
                       popupTitle,
                       popupButtonText,
                       popupText,
                       spinCount
                   }) => {

    const canvasRef = useRef(null);
    const [angle, setAngle] = useState(0);
    const [isSpinning, setIsSpinning] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const popupRef = useRef(null);
    const [remainingSpins, setRemainingSpins] = useState(1);
    const [hasSpunOnce, setHasSpunOnce] = useState(false);
    const [currentRotation, setCurrentRotation] = useState(0);


    const wheelObj = {
        currentWheel: currentWheel,
        wheelNumbers: wheelNumbers,
        prelandTitle: prelandTitle,
        spinButtonText: spinButtonText,
        respinButtonText: respinButtonText,
        winSector: winSector,
        popupTitle: popupTitle,
        popupButtonText: popupButtonText,
        popupText: popupText,
        spinCount: spinCount,
    }

    console.log('wheelObj', wheelObj)

    useEffect(() => {
        setRemainingSpins(spinCount);
        setIsSpinning(false);
        setShowPopup(false);
        setHasSpunOnce(false);
    }, [spinCount, currentWheel]);



    useEffect(() => {
        if (canvasRef.current) {
            const ctx = canvasRef.current.getContext('2d');
            const radius = canvasRef.current.width / 2;
            drawWheel(ctx, radius, wheelNumbers, currentWheel, angle);
        }
    }, [wheelNumbers, currentWheel, angle]);

    // useEffect(() => {
    //     drawWheel();
    // }, [angle, currentRotation, wheelNumbers]);

    const spinWheel = () => {
        if (isSpinning || remainingSpins <= 0) return;

        setIsSpinning(true);
        setRemainingSpins(remainingSpins - 1);

        const degreesPerSegment = 360 / wheelNumbers.length; // Скільки градусів на один сектор
        const halfSegmentOffset = degreesPerSegment / 2; // Додавання половини кута сектора для центрування

        // Враховуємо, що потрібен сектор ліворуч від поточного вибору
        const adjustmentForCorrectSector = degreesPerSegment; // Відняти кут одного сектора

        const winningSegmentDegrees = (winSector * degreesPerSegment) + halfSegmentOffset - adjustmentForCorrectSector;
        const offsetForWinningSegment = 360 - winningSegmentDegrees;

        const fullSpins = 5 * 360; // Кількість повних обертів для ефекту спіна
        const totalRotation = currentRotation + fullSpins + offsetForWinningSegment - (currentRotation % 360);

        setWheelStyle({
            transform: `rotate(${totalRotation}deg)`,
            transition: 'transform 4s ease-in-out',
        });

        setTimeout(() => {
            setIsSpinning(false);
            setShowPopup(true);

            const newCurrentRotation = totalRotation % 360;
            setCurrentRotation(newCurrentRotation);

            setWheelStyle({
                transform: `rotate(${newCurrentRotation}deg)`,
                transition: 'none',
            });
        }, 4000);
    };

    const processedPopupText = popupText?.replace("{bonus}", wheelNumbers[winSector]);

    const [wheelStyle, setWheelStyle] = useState({});


    const [isAnimated, setAnimated] = useState(false)

    return (
        <div className={s.prelandPreview}
             style={
                 {
                     backgroundImage: `url(${currentWheel?.background})`,
                     // backgroundImage: `url(${cbg})`,
                     backgroundSize: 'cover',
                     backgroundPosition: 'center',
                     backgroundRepeat: 'no-repeat'
                 }}>

            {
                currentWheel?.wheel?.includes('blue') &&
                <img src={bomb} style={{position: "absolute", bottom: -20, left: -10, zIndex: 100}}/>
            }

            {
                currentWheel?.wheel?.includes('red') &&
                <img src={fog} style={{position: "absolute", bottom: -20, left: -10, zIndex: 1}}/>
            }

            {
                currentWheel?.wheel?.includes('purple') &&
                <img src={decoratorPurpleBackground}
                     style={{position: "absolute", bottom: -20, left: -10, zIndex: 100}}/>
            }


            <div className={s.header}>
                {/*<img src={logo} alt="" className={s.prelandLogo}/>*/}
                <div className={s.call}>{prelandTitle}</div>
            </div>
            <div className={s.content}>
                {/*<img src={lightDecoratorBR} style={{position: "absolute", bottom: 0}}/>*/}

                {
                    currentWheel?.wheel?.includes('blue') && <>
                        <img src={stoneTL} style={{position: "absolute", top: 0, left: 0, zIndex: 100}}/>
                        <img src={stoneTR} style={{position: "absolute", top: 0, right: 0, zIndex: 100}}/>
                        <img src={stoneBL} style={{position: "absolute", bottom: 0, left: 0, zIndex: 100}}/>
                        <img src={stoneBR} style={{position: "absolute", bottom: 0, right: 0, zIndex: 100}}/>

                        <img src={lightDecorator}
                             style={{position: "absolute", marginLeft: 30, marginBottom: 10, zIndex: 100}}/>

                    </>
                }

                {
                    currentWheel?.wheel?.includes('red') && <>
                        <img src={lightDecorator} style={{position: "absolute", top: -50, marginRight: 35, zIndex: 100}}/>

                        <img src={lightDecorator} style={{position: "absolute", marginRight: 23, zIndex: 100}}/>

                        <img src={lightDecorator} style={{position: "absolute", left: -40, marginBottom: 90, zIndex: 100}}/>

                        <img src={lightDecorator} style={{position: "absolute", right: -10, bottom: 20, zIndex: 100}}/>

                    </>
                }

                {
                    currentWheel?.wheel?.includes('purple') && <>
                        <img src={purpleCenter} style={{position: "absolute", zIndex: 100}}/>
                    </>
                }

                {
                    currentWheel?.wheel?.includes('orange') && <>
                        <img src={decoratorCenterWheel} style={{position: "absolute", zIndex: 100}}/>
                    </>
                }


                <img
                    className={s.pointer}
                    src={currentWheel?.pointer}
                    style={getPointerStyle(currentWheel)}
                    // src={cpointer}
                />
                <canvas
                    ref={canvasRef}
                    width="360"
                    height="360"
                    className={isAnimated ? `${s.wheel} ${s.animated}` : s.wheel}
                    style={wheelStyle}
                />
            </div>
            <div className={s.footer}>
                <div className={s.spinBtnWrapper} style={showPopup ? {cursor: "default"} : null} onClick={() => {
                    if (!showPopup) {
                        spinWheel()
                    } else {
                        setShowPopup(false)
                    }
                }} disabled={isSpinning}>
                    {
                        currentWheel?.wheel?.includes('purple') && <>
                            <img src={decoratorButton} style={{position: "absolute", top: -52, zIndex: 100}}/>
                            <img src={decoratorPointer} style={{position: "absolute", bottom: -45, zIndex: 100}}/>
                            <img src={lightDecorator} style={{position: "absolute", right: -50, top: -45, zIndex: 100}}/>
                            <img src={lightDecorator} style={{position: "absolute", left: -50, bottom: -45, zIndex: 100}}/>

                        </>
                    }

                    {
                        currentWheel?.wheel?.includes('orange') && <>
                            <img src={coin} style={{position: "absolute", bottom: -30, marginLeft: 70, zIndex: 100}}/>
                        </>
                    }

                    <button
                        className={s.spinBtn}
                        style={{
                            backgroundImage: `url(${currentWheel?.button})`,
                            // backgroundImage: `url(${cbtn})`,
                            width: 290,
                            height: 85,

                            // ...btnStyle(currentWheel?.background)

                        }}
                    >
                        {hasSpunOnce ? respinButtonText : spinButtonText}
                    </button>
                </div>
            </div>

            {showPopup && (
                <div className={s.prelandPopup}
                     onClick={() => setShowPopup(false)}
                >
                    <img src={currentWheel.popup} alt="" className={s.popupBg}/>
                    {/*<img src={cpopup} alt="" className={s.popupBg}/>*/}

                    <div
                        className={s.popupContent}
                        ref={popupRef}
                        // style={
                        //     {
                        //         backgroundImage: `url(${currentWheel.popup})`,
                        //         backgroundSize: 'cover',
                        //         backgroundPosition: 'center',
                        //         backgroundRepeat: 'no-repeat'
                        //     }}
                    >
                        {/*<img src={currentWheel.popup} alt="" className={s.popupBg}/>*/}
                        <div className={s.popupTitle}>{popupTitle}</div>
                        {/*<div className={s.popupTextContent}>You get {wheelNumbers[winSector].value}</div>*/}
                        <div className={s.popupTextContent}>{processedPopupText}</div>
                        <div
                            className={s.popupBtn}
                        >
                            <img src={currentWheel.button} alt="" className={s.btnBg}/>
                            {/*<img src={cbtn} alt="" className={s.btnBg}/>*/}

                            <a
                                // href={redirectLink}
                                disabled={isSpinning}
                                target='_blank'
                                className={s.popupSpinBtn}
                            >
                                {popupButtonText}
                            </a>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default SpinWheel;