import React, { useEffect, useRef } from "react";
import s from "./editPrelanding.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  editPrelanding,
  fetchWheelStylesList,
  getPrelandingById,
  patchEditPrelanding,
  prelandingSelector,
} from "../../../../redux/slices/prelandingSlice";
import {
  globalDataSelector,
  setAdaptiveMenu,
} from "../../../../redux/slices/globalDataSlice";
import useCustomSnackbar from "../../../../hooks/useCustomSnackbar";
import { useFormik } from "formik";
import {
  prelandingStatusList,
  prelandingTypesList,
  snackbar_error,
  snackbar_success,
} from "../../../../utils/vars/staticVariables";
import * as Yup from "yup";
import CustomSkeleton from "../../../../components/CustomSkeleton/CustomSkeleton";
import { setTopUpBalanceActive } from "../../../../redux/slices/financeSlice";
import GlobalSvgSelector from "../../../../components/GlobalSvgSelector/GlobalSvgSelector";
import GradientBorder from "../../../../components/Blocks/GradientBorder/GradientBorder";
import LimitedInput from "../../../../components/LimitedInput/LimitedInput";
import { Radio } from "@mui/material";
import Wheel from "../Wheel/Wheel";
import PrimaryBtn from "../../../../components/UiKit/Buttons/PrimaryBorderBtn/PrimaryBorderBtn";
import ReactSelectTheme from "../../../../components/Blocks/ReactSelectTheme/ReactSelectTheme";
import EditCustom from "./EditCustom/EditCustom";
import EditDefault from "./EditDefault/EditDefault";
import {
  noIndexHtml,
  noIndexHtmlFormikError,
  prelandingFileCyrillicError,
} from "../../../../utils/vars/errorMessages";

const EditPrelanding = () => {
  const errorRefs = useRef({}); // Додано для збереження рефів полів з помилками

  const { isCollapsedSidebar, isAdaptiveMenuActive } =
    useSelector(globalDataSelector);
  const { wheelStylesList, prelandingObj } = useSelector(prelandingSelector);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { id } = useParams();

  const showSnackbar = useCustomSnackbar();

  const initialValues = {
    status: prelandingObj?.status,
    name: prelandingObj?.name || "",
    num_of_revolutions: 1,
    prelanding_type:
      prelandingObj?.prelanding_type || prelandingTypesList[0].value,
    header_text: prelandingObj?.header_text || "",
    rotation_button_text: prelandingObj?.rotation_button_text || "",
    respin_button_text: prelandingObj?.respin_button_text || "",
    style: prelandingObj?.style || wheelStylesList[0]?.id,
    sectors:
      prelandingObj?.sectors ||
      new Array(8).fill().map((_, index) => ({
        name: `Сектор ${index}`,
        value: "",
        is_win: index <= 0,
      })),
    bonus_button_text: prelandingObj?.bonus_button_text || "",
    popup_win_text: prelandingObj?.popup_win_text || "",
    winning_button_text: prelandingObj?.winning_button_text || "",
    logo: null,
  };

  const editDefaultPrelandFormik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: Yup.object({
      name: Yup.string().required("Обязательное поле"),
      // header_text: Yup.string().required("Обязательное поле"),
      rotation_button_text: Yup.string().required("Обязательное поле"),
      respin_button_text: Yup.string().required("Обязательное поле"),
      sectors: Yup.array().of(
        Yup.object().shape({
          value: Yup.string().required("Заполните значение сектора"),
        }),
      ),
      bonus_button_text: Yup.string().required("Обязательное поле"),
      winning_button_text: Yup.string().required("Обязательное поле"),
      style: Yup.string().required("Обязательное поле"),
    }),
    onSubmit: async (data, { setFieldError, validateField, setFieldValue }) => {
      const editPrelandingResult = await dispatch(
        editPrelanding({ prelandObj: data, id }),
      );
      if (editPrelandingResult?.payload?.status === 200) {
        navigate("/prelandings");
        showSnackbar("Прелендинг был отредактирован", snackbar_success);
      } else {
        showSnackbar("Не удалось отредактировать прелендинг", snackbar_error);
      }
    },
    validateOnMount: true,
    validateOnBlur: true,
    validateOnChange: true,
  });

  const editCustomPrelandFormik = useFormik({
    initialValues: {
      prelanding_file: prelandingObj?.prelanding_file,
      prelanding_type: prelandingObj?.prelanding_type,
      name: prelandingObj?.name,
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      name: Yup.string().required("Обязательное поле"),
      prelanding_file: Yup.string().required("Загрузите .zip архив"),
    }),
    onSubmit: async (data, { setFieldError, validateField, setFieldValue }) => {
      if (
        data.prelanding_file === prelandingObj?.prelanding_file &&
        data.name === prelandingObj?.name
      ) {
        setFieldError("name", " ");
        setFieldError("prelanding_file", "Внесите изменения, чтобы сохранить");

        return;
      }

      if (
        data.prelanding_file === prelandingObj?.prelanding_file &&
        data.name !== prelandingObj?.name
      ) {
        const patchRequestObj = {
          name: data.name,
          prelanding_type: data.prelanding_type,
        };

        const editPrelandingResult = await dispatch(
          patchEditPrelanding({ prelandObj: patchRequestObj, id }),
        );

        if (editPrelandingResult?.payload?.status === 200) {
          navigate("/prelandings");
          showSnackbar(
            "Имя прелендинга было отредактировано",
            snackbar_success,
          );
        } else {
          showSnackbar("Не удалось отредактировать прелендинг", snackbar_error);
        }
        return;
      }

      const formData = new FormData();
      formData.append("prelanding_file", data.prelanding_file);
      formData.append("prelanding_type", data.prelanding_type);
      formData.append("name", data.name);

      const editPrelandingResult = await dispatch(
        editPrelanding({ prelandObj: formData, id }),
      );
      if (editPrelandingResult?.payload?.status === 200) {
        navigate("/prelandings");
        showSnackbar("Прелендинг был отредактирован", snackbar_success);
      } else if (editPrelandingResult?.payload?.data?.detail === noIndexHtml) {
        return setFieldError("prelanding_file", noIndexHtmlFormikError);
      } else if (
        editPrelandingResult?.payload?.data?.detail ===
        prelandingFileCyrillicError
      ) {
        return setFieldError("prelanding_file", prelandingFileCyrillicError);
      } else {
        showSnackbar("Не удалось отредактировать прелендинг", snackbar_error);
      }
    },
    validateOnMount: true,
    validateOnBlur: true,
    validateOnChange: true,
  });

  useEffect(() => {
    dispatch(fetchWheelStylesList());
  }, [dispatch]);

  useEffect(() => {
    // Функція для скролу до першого поля з помилкою з урахуванням фіксованого хедера
    const scrollToError = () => {
      const firstErrorKey = Object.keys(editDefaultPrelandFormik.errors)[0];
      if (firstErrorKey && errorRefs.current[firstErrorKey]) {
        const errorElement = errorRefs.current[firstErrorKey];
        const headerOffset = 80; // Висота фіксованого хедера
        const elementPosition =
          errorElement.getBoundingClientRect().top + window.pageYOffset;
        const offsetPosition = elementPosition - headerOffset;

        // Перевірка, чи поле з помилкою знаходиться поза зоною видимості
        const isOutOfView =
          elementPosition < window.pageYOffset ||
          elementPosition > window.pageYOffset + window.innerHeight;

        if (isOutOfView) {
          window.scrollTo({
            top: offsetPosition,
            behavior: "smooth",
          });
        }
      }
    };

    if (
      editDefaultPrelandFormik.isSubmitting &&
      Object.keys(editDefaultPrelandFormik.errors).length > 0
    ) {
      scrollToError();
    }
  }, [editDefaultPrelandFormik.isSubmitting, editDefaultPrelandFormik.errors]);

  useEffect(() => {
    dispatch(getPrelandingById(id));
  }, [dispatch, id]);

  return (
    <div
      className={`${s.editPrelandingWrapper} ${
        isCollapsedSidebar === "true" ? s.collapsedSidebar : ""
      }`}
    >
      <div className={s.pageHeaderWrapper}>
        <div className={s.pageHeaderContainer}>
          <div className={s.staticElements}>
            <div className={s.leftSideWrapper}>
              <button
                className={s.walletBtn}
                onClick={() => {
                  dispatch(setAdaptiveMenu(false));
                  dispatch(setTopUpBalanceActive(true));
                }}
              >
                <GlobalSvgSelector id="wallet" />
              </button>
              <Link className={s.walletBtn} to="/account">
                <GlobalSvgSelector id="account-gear-icon" />
              </Link>
            </div>

            <button
              type="button"
              className={s.menuBtn}
              onClick={() => dispatch(setAdaptiveMenu(!isAdaptiveMenuActive))}
            >
              <GlobalSvgSelector id="burger-menu" />
            </button>
          </div>
        </div>
      </div>

      <div className={s.pageContent}>
        <div className={s.pageMarking}>
          <Link to="/prelandings" className={s.link}>
            Прелендинги
          </Link>
          <GlobalSvgSelector id="arrow-right" />
          <span>Редактирование</span>
        </div>
        <GradientBorder className={s.gradientBorderCustom} />
        {prelandingObj?.prelanding_type === 1 && (
          <EditDefault formik={editDefaultPrelandFormik} />
        )}
        {prelandingObj?.prelanding_type === 4 && (
          <EditCustom formik={editCustomPrelandFormik} />
        )}
      </div>
    </div>
  );
};

export default EditPrelanding;
