import React, { useRef, useState } from "react";
import s from "./newAuth.module.scss";
import GlobalSvgSelector from "../../../components/GlobalSvgSelector/GlobalSvgSelector";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  createPrelanding,
  setAllPrelandingsList,
} from "../../../redux/slices/prelandingSlice";
import {
  snackbar_error,
  snackbar_info,
  snackbar_success,
} from "../../../utils/vars/staticVariables";
import { authSelector, login } from "../../../redux/slices/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { noActiveAccountFound } from "../../../utils/vars/errorMessages";

const NewAuth = () => {
  const [isShowedPassword, setShowedPassword] = useState(false);
  const passwordInputRef = useRef(null);

  const { loading } = useSelector(authSelector);
  const dispatch = useDispatch();

  const handleShowPasswordClick = (e) => {
    e.preventDefault();
    setShowedPassword(!isShowedPassword);

    if (passwordInputRef.current) {
      passwordInputRef.current.focus();
    }
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Некорректный формат электронной почты")
        .required("Обязательно поле"),
      password: Yup.string().required("Обязательно поле"),
    }),
    onSubmit: async (form) => {
      form.password = form.password.trim();
      const loginResult = await dispatch(login(form));

      if (loginResult?.payload?.status === 200) {
        localStorage.setItem("accessToken", loginResult?.payload?.data?.access);
        localStorage.setItem(
          "refreshToken",
          loginResult?.payload?.data?.refresh,
        );
        window.history.pushState({}, "", "/app-catalog");
        window.location.reload();
      }

      if (loginResult?.payload?.data?.detail === noActiveAccountFound) {
        formik.setFieldError("email", " ");
        formik.setFieldError("password", "Неверные почта или пароль");
      }
    },
    validateOnMount: true,
    validateOnBlur: true,
    validateOnChange: true,
  });

  return (
    <form className={s.authFormWrapper} onSubmit={formik.handleSubmit}>
      <div className={s.welcomeBlock}>
        С возвращением в <GlobalSvgSelector id="new-logo-icon" />
      </div>
      <div className={s.formDescription}>Войдите в учетную запись</div>

      <div className={s.formFieldsWrapper}>
        <div className={s.fieldWrapper}>
          <p className={s.fieldDescription}>Почта</p>
          <div
            className={`${s.inputWrapper} ${
              formik.touched.email && formik.errors.email ? s.wrapperError : ""
            }`}
          >
            <input
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={`${s.input} ${
                formik.touched.email && formik.errors.email ? s.error : ""
              }`}
              type="text"
              placeholder="Введите электронный адрес"
            />
          </div>
          {formik.touched.email && formik.errors.email ? (
            <div className={s.errorMessage}>{formik.errors.email}</div>
          ) : null}
        </div>
        <div className={s.fieldWrapper}>
          <p className={s.fieldDescription}>Пароль</p>
          <div
            className={`${s.inputWrapper} ${
              formik.touched.password && formik.errors.password
                ? s.wrapperError
                : ""
            }`}
          >
            <button
              type="button"
              className={s.showPasswordBtn}
              onClick={handleShowPasswordClick}
            >
              <GlobalSvgSelector
                id={
                  isShowedPassword ? "show-password-icon" : "hide-password-icon"
                }
              />
            </button>
            <input
              name="password"
              value={formik.values.password}
              ref={passwordInputRef}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={`${s.input} ${s.password} ${
                formik.touched.password && formik.errors.password ? s.error : ""
              }`}
              type={isShowedPassword ? "text" : "password"}
              placeholder="Введите пароль"
            />
          </div>
          {formik.touched.password && formik.errors.password ? (
            <div className={s.errorMessage}>{formik.errors.password}</div>
          ) : null}
        </div>
      </div>
      <div className={s.buttonWrapper}>
        <button className={s.enterBtn} type="submit" disabled={loading}>
          Войти
        </button>
      </div>
    </form>
  );
};

export default NewAuth;
