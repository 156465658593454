import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  globalDataSelector,
  setAdaptiveMenu,
} from "../../../../redux/slices/globalDataSlice";
import useCustomSnackbar from "../../../../hooks/useCustomSnackbar";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  checkPixelTokenValid,
  editPixelApiItem,
  fetchPixelApiItem,
  pixelApiSelector,
} from "../../../../redux/slices/pixelApiSlice";
import {
  snackbar_error,
  snackbar_success,
} from "../../../../utils/vars/staticVariables";
import { pixelIdExists } from "../../../../utils/vars/errorMessages";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getUser } from "../../../../storage/storage";
import s from "./editPixelApi.module.scss";
import { setTopUpBalanceActive } from "../../../../redux/slices/financeSlice";
import GlobalSvgSelector from "../../../../components/GlobalSvgSelector/GlobalSvgSelector";
import PrimaryBtn from "../../../../components/UiKit/Buttons/PrimaryBorderBtn/PrimaryBorderBtn";

const EditPixelApi = () => {
  const { isCollapsedSidebar, isAdaptiveMenuActive } =
    useSelector(globalDataSelector);

  const {
    pixelApiItem,
    pixelApiItemPending,
    editPixelApiPending,
    checkPixelApiPending,
  } = useSelector(pixelApiSelector);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id } = useParams();

  const showSnackbar = useCustomSnackbar();

  const { user_id } = getUser();

  useEffect(() => {
    dispatch(fetchPixelApiItem(id));
  }, [dispatch, id]);

  const formik = useFormik({
    initialValues: {
      pixel_id: pixelApiItem?.pixel_id || "",
      token: pixelApiItem?.token || "",
      owner: user_id,
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      pixel_id: Yup.string()
        .matches(/^\d+$/, "Pixel должен содержать только цифры")
        .matches(/^\S*$/, "Между символами не должно быть пробелов")
        .required("Обязательное поле"),
      token: Yup.string()
        .matches(
          /^[a-zA-Z0-9]+$/,
          "Token может содержать только цифры и латинсике буквы",
        )
        .required("Обязательное поле"),
    }),
    onSubmit: async (data, { setFieldError, validateField, setFieldValue }) => {
      const isValidTokenResult = await dispatch(
        checkPixelTokenValid(data.token),
      );

      if (isValidTokenResult?.payload?.status === 200) {
        const editPixelApiResult = await dispatch(
          editPixelApiItem({ pixelApiObj: data, id }),
        );

        if (editPixelApiResult?.payload?.status === 200) {
          navigate("/pixel-api");
          showSnackbar("Pixel Api был отредактирован", snackbar_success);
        } else {
          if (editPixelApiResult?.payload?.data?.details === pixelIdExists) {
            return setFieldError("pixel", "Pixel с таким ID уже существует");
          }

          showSnackbar("Не удалось отредактировать Pixel Api", snackbar_error);
        }
      } else {
        setFieldError("token", "Токен невалиден");
      }
    },
  });

  return (
    <div
      className={`${s.editPixelApiWrapper} ${isCollapsedSidebar === "true" ? s.collapsedSidebar : ""}`}
    >
      <div className={s.pageHeaderWrapper}>
        <div className={s.pageHeaderContainer}>
          <div className={s.staticElements}>
            <div className={s.leftSideWrapper}>
              <button
                className={s.walletBtn}
                onClick={() => {
                  dispatch(setAdaptiveMenu(false));
                  dispatch(setTopUpBalanceActive(true));
                }}
              >
                <GlobalSvgSelector id="wallet" />
              </button>
              <Link className={s.walletBtn} to="/account">
                <GlobalSvgSelector id="account-gear-icon" />
              </Link>
            </div>

            <button
              className={s.menuBtn}
              onClick={() => dispatch(setAdaptiveMenu(!isAdaptiveMenuActive))}
            >
              <GlobalSvgSelector id="burger-menu" />
            </button>
          </div>
        </div>
      </div>

      <div className={s.pageContent}>
        <div className={s.pageMarking}>
          <Link to="/pixel-api" className={s.link}>
            Pixel API
          </Link>
          <GlobalSvgSelector id="arrow-right" />
          <span>Редактирование</span>
        </div>
        <form className={s.pixelForm} onSubmit={formik.handleSubmit}>
          <div className={s.inputsWrapper}>
            <div className={s.inputWrapper}>
              <p className={s.inputName}>Token</p>
              <input
                name="pixel_id"
                type="text"
                className={`${s.textInput} ${
                  formik.touched.pixel_id && formik.errors.pixel_id
                    ? s.error
                    : ""
                }`}
                placeholder={
                  pixelApiItemPending ? "Загрузка..." : "Введите Pixel"
                }
                value={formik.values.pixel_id}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.pixel_id && formik.errors.pixel_id ? (
                <div className={s.error}>{formik.errors.pixel_id}</div>
              ) : null}
            </div>
            <div className={s.inputWrapper}>
              <p className={s.inputName}>Token</p>
              <input
                name="token"
                type="text"
                className={`${s.textInput} ${
                  formik.touched.token && formik.errors.token ? s.error : ""
                }`}
                placeholder={
                  pixelApiItemPending ? "Загрузка..." : "Введите Token"
                }
                value={formik.values.token}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.token && formik.errors.token ? (
                <div className={s.error}>{formik.errors.token}</div>
              ) : null}
            </div>
          </div>
          <div className={s.buttons}>
            <Link className={s.whiteBorderBtn} to="/pixel-api">
              Отменить
            </Link>
            <PrimaryBtn
              text="Сохранить"
              submit={true}
              pending={
                editPixelApiPending ||
                pixelApiItemPending ||
                checkPixelApiPending
              }
              className={s.customPrimaryBtn}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditPixelApi;
