import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { financeSelector } from "../../../../../redux/slices/financeSlice";
import { globalDataSelector } from "../../../../../redux/slices/globalDataSlice";
import s from "./createPixelApi.module.scss";
import GlobalSvgSelector from "../../../../../components/GlobalSvgSelector/GlobalSvgSelector";
import { useFormik } from "formik";
import * as Yup from "yup";
import CancelButton from "../../../../../components/UiKit/Buttons/CancelBtn/CancelBtn";
import PrimaryBtn from "../../../../../components/UiKit/Buttons/PrimaryBorderBtn/PrimaryBorderBtn";
import {
  checkPixelTokenValid,
  pixelApiSelector,
  postPixelApiItem,
} from "../../../../../redux/slices/pixelApiSlice";
import { getUser } from "../../../../../storage/storage";
import useCustomSnackbar from "../../../../../hooks/useCustomSnackbar";
import {
  snackbar_error,
  snackbar_success,
} from "../../../../../utils/vars/staticVariables";
import { pixelIdExists } from "../../../../../utils/vars/errorMessages";

const CreatePixelApi = ({ active, setActive, fetchPixelApiList }) => {
  const { topUpBalanceIsActive } = useSelector(financeSelector);
  const { exitPopup, isCollapsedSidebar } = useSelector(globalDataSelector);
  const { checkPixelApiPending, createPixelApiPending } =
    useSelector(pixelApiSelector);

  const dispatch = useDispatch();

  const showSnackbar = useCustomSnackbar();

  const { user_id } = getUser();

  useEffect(() => {
    if (topUpBalanceIsActive || exitPopup) {
      setActive(false);
    }
  }, [topUpBalanceIsActive, exitPopup]);

  useEffect(() => {
    const handleEscClose = (e) => {
      if (e.key === "Escape") {
        setActive(false);
        if (document.activeElement) {
          document.activeElement.blur();
        }
      }
    };

    document.addEventListener("keydown", handleEscClose);

    return () => {
      document.removeEventListener("keydown", handleEscClose);
    };
  }, [setActive]);

  const handleClosePopup = (event) => {
    if (event.target.classList.contains(s.popupWrapper)) {
      setActive(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      pixel: "",
      token: "",
    },
    validationSchema: Yup.object({
      pixel: Yup.string()
        .matches(/^\d+$/, "Pixel должен содержать только цифры")
        .matches(/^\S*$/, "Между символами не должно быть пробелов")
        .required("Обязательное поле"),
      token: Yup.string()
        .matches(
          /^[a-zA-Z0-9]+$/,
          "Token может содержать только цифры и латинсике буквы",
        )
        .required("Обязательное поле"),
    }),
    onSubmit: async (
      values,
      { setFieldError, validateField, setFieldValue },
    ) => {
      const isValidTokenResult = await dispatch(
        checkPixelTokenValid(values.token),
      );

      console.log("isValidTokenResult?.payload", isValidTokenResult?.payload);

      if (isValidTokenResult?.payload?.status === 200) {
        const pixelApiObj = {
          pixel_id: values.pixel,
          token: values.token,
          owner: user_id,
        };

        const createPixelApiResult = await dispatch(
          postPixelApiItem(pixelApiObj),
        );

        if (createPixelApiResult?.payload?.status === 201) {
          fetchPixelApiList();
          showSnackbar("Pixel Api был создан", snackbar_success);
          setActive(false);
          setFieldValue("pixel", "");
          setFieldValue("token", "");
        } else {
          if (createPixelApiResult?.payload?.data?.details === pixelIdExists) {
            return setFieldError("pixel", "Pixel с таким ID уже существует");
          }

          showSnackbar("Не удалось создать Pixel Api", snackbar_error);
          setActive(false);
        }
      } else {
        setFieldError("token", "Токен невалиден");
      }
    },
  });

  return (
    <div
      className={`${s.popupWrapper} ${active ? s.active : ""} ${isCollapsedSidebar === "true" ? s.collapsed : ""}`}
      onClick={handleClosePopup}
    >
      <form
        className={active ? `${s.popup} ${s.active}` : s.popup}
        onSubmit={formik.handleSubmit}
      >
        <div className={s.header}>
          <button
            onClick={() => setActive(false)}
            className={s.closePopup}
            type="button"
          >
            <GlobalSvgSelector id="close-popup" />
          </button>
          <div className={s.icon}>
            <GlobalSvgSelector id="pixeApi-popup-icon" />
          </div>
          <div className={s.popupInfo}>
            <p className={s.title}>Создание Pixel Api</p>
          </div>
        </div>
        <div className={s.body}>
          <div>
            <p className={s.inputName}>Pixel</p>
            <input
              type="text"
              name="pixel"
              className={`${s.textInput} ${
                formik.touched.pixel && formik.errors.pixel ? s.error : ""
              }`}
              placeholder="Введите Pixel"
              value={formik.values.pixel}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.pixel && formik.errors.pixel ? (
              <div className={s.error}>{formik.errors.pixel}</div>
            ) : null}
          </div>
          <div>
            <p className={s.inputName}>Token</p>
            <input
              name="token"
              type="text"
              className={`${s.textInput} ${
                formik.touched.token && formik.errors.token ? s.error : ""
              }`}
              placeholder="Введите Token"
              value={formik.values.token}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.token && formik.errors.token ? (
              <div className={s.error}>{formik.errors.token}</div>
            ) : null}
          </div>
        </div>
        <div className={s.footerContainer}>
          <CancelButton text="Отменить" action={() => setActive(false)} />
          <PrimaryBtn
            text="Создать"
            submit={true}
            pending={checkPixelApiPending || createPixelApiPending}
          />
        </div>
      </form>
    </div>
  );
};

export default CreatePixelApi;
