import React, { useState } from 'react';
import {Link, useNavigate} from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { TextField, Checkbox, FormControlLabel } from "@mui/material";
import { styled } from "@mui/material/styles";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { register as registerAction } from "../../../../redux/slices/authSlice";
import { authTextFieldStyles } from "../../Auth/Auth";
import GlobalSvgSelector from "../../../../components/GlobalSvgSelector/GlobalSvgSelector";
import Loader from "../../../../components/UiKit/Loader/Loader";
import s from "./registerForm.module.scss";


const CustomCheckbox = styled(Checkbox)(({ theme }) => ({
    color: 'white',
    margin: '0',
    '&.css-1t4pufu-MuiButtonBase-root-MuiCheckbox-root': {
        margin: '0',

        color: 'white',
    },
    '&.Mui-checked': {
        color: theme.palette.primary.main,
    },
}));

const validationSchema = yup.object().shape({
    email: yup
        .string()
        .email('Електронный адрес не валиден')
        .required('Обязательное поле'),
    newPassword1: yup
        .string()
        .matches(/^[A-Za-z0-9\s!@#$%^&*(),.?":{}|<>[\]\\/`~;'\-+=]*$/, 'Только латинские символы')
        .min(8, 'Минимум 8 символов')
        .max(80, 'Максимум 80 символов')
        .matches(/[0-9]/, 'Минимум одна цифра')
        .matches(/[A-Z]/, 'Минимум одна большая буква')
        .matches(/[a-z]/, 'Минимум одна маленькая буква')
        .required('Обязательное поле'),
    newPassword2: yup
        .string()
        .oneOf([yup.ref('newPassword1'), null], 'Пароли должны совпадать')
        .required('Обязательное поле'),
    // telegramUsername: yup
    //     .string()
    //     .matches(/^[A-Za-z0-9]*$/, 'Только латинские символы')
    //     .min(5, 'Username должен содержать минимум 5 символов')
    //     .max(32, 'Username должен содержать максимум 32 символа')
    //     .test('no-at-symbol', 'Username не должен начинаться с @', value => !/^@/.test(value))
    //     .matches(/^[a-zA-Z0-9_]*$/, 'Username может содержать только буквы, цифры и подчеркивания')
    //     .required('Обязательное поле'),
    pushsub: yup
        .string()
        .matches(/^[A-Za-z\s]*$/, 'Только латинские буквы')
        // .matches(/^[^ ]*$/, 'Без пробелов')
        .required('Обязательное поле'),
    teamName: yup
        .string()
        .matches(/^[A-Za-z\s]*$/, 'Только латинские буквы')
        .required('Обязательное поле'),

    agreeToTerms: yup
        .boolean()
        .oneOf([true], '')
        .required('')
});

const RegisterForm = () => {
    const { register, handleSubmit, setError, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema),
    });

    const [isShowedNewPassword1, setShowedNewPassword1] = useState(false);
    const [isShowedNewPassword2, setShowedNewPassword2] = useState(false);

    const navigate = useNavigate()
    const { loading } = useSelector((state) => state.auth);
    const dispatch = useDispatch();

    const onSubmit = async data => {
        const { email, newPassword1, newPassword2, pushsub, teamName } = data;

        const dataObj = {
            email: email?.trim(),
            password: newPassword1?.trim(),
            confirm_password: newPassword2?.trim(),
            pushsub: pushsub?.trim(),
            offer: teamName?.trim(),
        };

        const successAction = () => {
            navigate('/register/email-confirm')
        }

        dispatch(registerAction({ data: dataObj, successAction, setError }))
    };

    return (
        <form className={s.authContainer} onSubmit={handleSubmit(onSubmit)}>
            <div className={s.header}>
                <h5>Добро пожаловать в WW<span>A</span></h5>
                <span className={s.subtitle}>Создайте аккаунт</span>
            </div>

            <div className={s.content}>
                <div className={s.field}>
                    <TextField
                        {...register("email")}
                        label="Email"
                        type="text"
                        error={!!errors.email}
                        helperText={errors.email ? errors.email.message : ''}
                        autoComplete="new-email"
                        sx={authTextFieldStyles}
                    />
                </div>

                <div className={s.field}>
                    <TextField
                        {...register("pushsub")}
                        label="Pushsub"
                        type="text"
                        error={!!errors.pushsub}
                        helperText={errors.pushsub ? errors.pushsub.message : ''}
                        autoComplete="off"
                        sx={authTextFieldStyles}
                    />
                </div>
                <div className={s.field}>
                    <button
                        className={s.showPasswordIcon}
                        onClick={(e) => {
                            e.preventDefault();
                            setShowedNewPassword1(!isShowedNewPassword1);
                        }}
                    >
                        <GlobalSvgSelector
                            id={isShowedNewPassword1 ? "show-password-icon" : "hide-password-icon"}
                        />
                    </button>
                    <TextField
                        {...register('newPassword1')}
                        label="Новый пароль"
                        error={!!errors.newPassword1}
                        helperText={errors.newPassword1 ? errors.newPassword1.message : ''}
                        type={isShowedNewPassword1 ? "text" : "password"}
                        autoComplete="new-password"
                        sx={authTextFieldStyles}
                    />
                </div>
                <div className={s.field}>
                    <button
                        className={s.showPasswordIcon}
                        onClick={(e) => {
                            e.preventDefault();
                            setShowedNewPassword2(!isShowedNewPassword2);
                        }}
                    >
                        <GlobalSvgSelector
                            id={isShowedNewPassword2 ? "show-password-icon" : "hide-password-icon"}
                        />
                    </button>
                    <TextField
                        {...register('newPassword2')}
                        label="Повторите пароль"
                        error={!!errors.newPassword2}
                        helperText={errors.newPassword2 ? errors.newPassword2.message : ''}
                        type={isShowedNewPassword2 ? "text" : "password"}
                        autoComplete="new-password"
                        sx={authTextFieldStyles}
                    />
                </div>
                <div className={s.field}>
                    <TextField
                        {...register("teamName")}
                        label="Team name"
                        type="text"
                        error={!!errors.teamName}
                        helperText={errors.teamName ? errors.teamName.message : ''}
                        autoComplete="off"
                        sx={authTextFieldStyles}
                    />
                </div>
                <div className={s.field}>
                    <TextField
                        {...register("telegramUsername")}
                        label="Telegram username"
                        type="text"
                        error={!!errors.telegramUsername}
                        helperText={errors.telegramUsername ? errors.telegramUsername.message : ''}
                        autoComplete="off"
                        sx={authTextFieldStyles}
                    />
                </div>

                <div className={s.checkBoxContainer}>
                    <FormControlLabel
                        style={{margin: 0, display: 'flex', justifyContent: 'center', alignItems: 'center'}}
                        control={<CustomCheckbox {...register("agreeToTerms")} />}
                        label={<span style={{margin: '0px', color: errors.agreeToTerms ? '#d32f2f' : '#d0d3d9'}}
                        >
                                Я принимаю условия предоставления услуг
                        </span>}
                    />
                </div>
            </div>
            <div className={s.footer}>
                <button className={s.loginBtn} type="submit" disabled={loading}>
                    {loading ? <Loader/> : 'Подтвердить'}
                </button>
            </div>
            <div className={s.createAccountContainer}>
                Есть аккаунт?
                <Link to='/auth/login'>Войти</Link>
            </div>
        </form>
    );
};

export default RegisterForm;
