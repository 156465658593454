import s from "./cancelBtn.module.scss";
import React from "react";

const CancelButton = ({ action, text, pending }) => {
  return (
    <button
      className={s.whiteBorderBtn}
      onClick={action}
      disabled={pending}
      type="button"
    >
      {text}
    </button>
  );
};

export default CancelButton;
