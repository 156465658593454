import React, { useEffect, useMemo, useState } from "react";
import s from "./createFlow.module.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  globalDataSelector,
  setAdaptiveMenu,
} from "../../../redux/slices/globalDataSlice";
import { Link, useNavigate } from "react-router-dom";
import useCustomSnackbar from "../../../hooks/useCustomSnackbar";
import { setTopUpBalanceActive } from "../../../redux/slices/financeSlice";
import GlobalSvgSelector from "../../../components/GlobalSvgSelector/GlobalSvgSelector";
import {
  createFlow,
  fetchDomainZones,
  flowSettingsSelector,
  setSubDomain,
} from "../../../redux/slices/flowSettingsSlice";
import { useFormik } from "formik";
import {
  snackbar_error,
  snackbar_warning,
} from "../../../utils/vars/staticVariables";
import * as Yup from "yup";
import ReactSelectTheme from "../../../components/Blocks/ReactSelectTheme/ReactSelectTheme";
import PrimaryBtn from "../../../components/UiKit/Buttons/PrimaryBorderBtn/PrimaryBorderBtn";
import NewConfirmPopup from "../../../components/UiKit/Popups/NewConfirmPopup/NewConfirmPopup";
import { authSelector } from "../../../redux/slices/authSlice";
import ConfirmCreateFlowPopup from "./ConfirmCreateFlowPopup/ConfirmCreateFlowPopup";
import {
  flowCreateLimit,
  noDomains,
  notEnoughMoney,
} from "../../../utils/vars/errorMessages";

const CreateFlow = () => {
  const [isConfirmPopupActive, setConfirmPopupActive] = useState(false);
  const [isPopupLimitActive, setPopupLimitActive] = useState(false);
  const { isCollapsedSidebar, isAdaptiveMenuActive } =
    useSelector(globalDataSelector);

  const { domainZonesList, createFlowPending, setSubDomainPending } =
    useSelector(flowSettingsSelector);
  const { isPartner } = useSelector(authSelector);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const showSnackbar = useCustomSnackbar();

  useEffect(() => {
    dispatch(fetchDomainZones());
  }, [dispatch]);

  const domainsZoneListOptions = useMemo(() => {
    return domainZonesList?.map((domainZone) => ({
      value: domainZone,
      label: domainZone,
    }));
  }, [domainZonesList]);

  const formik = useFormik({
    initialValues: {
      domainZoneValue: null,
      subDomainValue: "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      subDomainValue: Yup.string()
        .matches(/^\S*$/, "Между символами не должно быть пробелов")
        .matches(
          /^[a-zA-Z0-9]+$/,
          "Поле может содержать только цифры и латинсике буквы",
        )
        .min(2, "Минимум 2 символа")
        .max(20, "Максимум 20 символов"),
    }),
    onSubmit: async (form) => {
      const createFlowResult = await dispatch(createFlow(form.domainZoneValue));
      const { status, data } = createFlowResult?.payload;
      if (status === 200) {
        if (formik.values.subDomainValue) {
          const subDomainObj = {
            flow: data?.flow?.id,
            address: form.subDomainValue.trim().toLowerCase(),
          };

          const setSubDomainResults = await dispatch(
            setSubDomain(subDomainObj),
          );

          if (setSubDomainResults?.payload?.status === 201) {
            navigate(`/my-flows/edit/${data?.flow?.id}`);
          } else {
            showSnackbar(
              "Не удалось создать Sub домен. Обратитесь в поддержку.",
              snackbar_error,
            );
          }
        } else {
          navigate(`/my-flows/edit/${data?.flow?.id}`);
        }
      } else {
        if (data.details === noDomains) {
          showSnackbar(noDomains, snackbar_warning);
        }
        if (data.details === notEnoughMoney) {
          showSnackbar("Недостаточно средств на балансе", snackbar_warning);
        }
        if (data.details === flowCreateLimit) {
          setPopupLimitActive(true);
        }
      }
    },
    validateOnMount: true,
    validateOnBlur: true,
  });

  return (
    <div
      className={`${s.createFlowWrapper} ${
        isCollapsedSidebar === "true" ? s.collapsedSidebar : ""
      }`}
    >
      <NewConfirmPopup
        icon={<GlobalSvgSelector id="flow-popup-icon" />}
        active={isPopupLimitActive}
        setActive={setPopupLimitActive}
        action={() => navigate("/my-flows")}
        confirmBtnText="Продолжить"
        title="Лимит создания потоков"
        description={
          <div className={s.support}>
            Вы достигли дневного лимита по количеству новых потоков. Чтобы
            расширить лимит, обратитесь в
            <a
              href="https://t.me/yaroslava_support_wwa"
              target="_blank"
              rel="noreferrer"
            >
              Поддержку
            </a>
          </div>
        }
      />
      <div className={s.pageHeaderWrapper}>
        <div className={s.pageHeaderContainer}>
          <div className={s.staticElements}>
            <div className={s.leftSideWrapper}>
              <button
                className={s.walletBtn}
                onClick={() => {
                  dispatch(setAdaptiveMenu(false));
                  dispatch(setTopUpBalanceActive(true));
                }}
              >
                <GlobalSvgSelector id="wallet" />
              </button>
              <Link className={s.walletBtn} to="/account">
                <GlobalSvgSelector id="account-gear-icon" />
              </Link>
            </div>

            <button
              type="button"
              className={s.menuBtn}
              onClick={() => dispatch(setAdaptiveMenu(!isAdaptiveMenuActive))}
            >
              <GlobalSvgSelector id="burger-menu" />
            </button>
          </div>
        </div>
      </div>

      <div className={s.pageContent}>
        <div className={s.pageMarking}>
          <Link to="/my-flows" className={s.link}>
            Потоки
          </Link>
          {/*<GlobalSvgSelector id="arrow-right" />*/}
          {/*<span>Создание</span>*/}
        </div>
        <div className={s.switchPageContentContainer}>
          <div className={s.buttonsContainer}>
            <button className={`${s.pageBtn} ${s.active} ${s.hardCoded}`}>
              <GlobalSvgSelector id="domain-select-icon" />
              Домен
            </button>

            <button className={`${s.pageBtn} ${s.disabled}`}>
              <GlobalSvgSelector id="os-select-icon" />
              Настройки
            </button>

            <button className={`${s.pageBtn} ${s.disabled}`}>
              <GlobalSvgSelector id="preland-indicator-icon" />
              Преленд
            </button>

            <button className={`${s.pageBtn} ${s.disabled}`}>
              <GlobalSvgSelector id="redirect-indicator-icon" />
              Редирект
            </button>
          </div>
        </div>
        <div className={s.domainInfoWrapper}>
          <div className={s.domainInfo}>
            <GlobalSvgSelector id="check-primary-circle" />
            Вам будет предоставлен уже готовый к работе домен
          </div>
        </div>
        <form className={s.form} onSubmit={formik.handleSubmit}>
          <ConfirmCreateFlowPopup
            icon={<GlobalSvgSelector id="flow-popup-icon" />}
            active={isConfirmPopupActive}
            setActive={(value) => setConfirmPopupActive(value)}
            action={() => formik.handleSubmit()}
            confirmBtnText="Создать"
            title="Создание потока"
            description={
              isPartner
                ? "Вы уверены, что хотите создать поток?"
                : "При создании потока с баланса спишется 6$ за домен"
            }
            forcedClose={false}
          />
          <div className={s.formFieldsWrapper}>
            <div className={s.fieldWrapper}>
              <p className={s.fieldName}>
                Зона домена <span>( не обязательно )</span>
              </p>
              <ReactSelectTheme
                isClearable={true}
                darkTheme={true}
                options={domainsZoneListOptions}
                placeholder="Выберите пункт"
                value={formik.values.domainZoneValue}
                action={(selectedOption) =>
                  formik.setFieldValue("domainZoneValue", selectedOption)
                }
              />
              <p className={s.fieldName}>
                <span>
                  Выберите доменную зону из доступных, или она будет выбрана
                  случайно
                </span>
              </p>
            </div>

            <div className={s.fieldWrapper}>
              <p className={s.fieldName}>
                Sub домен <span>( не обязательно )</span>
              </p>
              <input
                type="text"
                name="subDomainValue"
                className={`${s.textInput} ${
                  formik.touched.name && formik.errors.name ? s.error : ""
                }`}
                placeholder="Введите текст"
                value={formik.values.subDomainValue}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.subDomainValue && formik.errors.subDomainValue ? (
                <div className={s.error}>{formik.errors.subDomainValue}</div>
              ) : null}
            </div>
          </div>

          <div className={s.buttons}>
            <Link className={s.whiteBorderBtn} to="/my-flows">
              Отменить
            </Link>
            <PrimaryBtn
              action={() => {
                if (Object.keys(formik.errors).length === 0) {
                  setConfirmPopupActive(true);
                }
              }}
              text="Создать"
              submit={false}
              pending={createFlowPending || setSubDomainPending}
              className={s.customPrimaryBtn}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateFlow;
