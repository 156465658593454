import { useDispatch, useSelector } from "react-redux";
import {
  fetchBalances,
  financeSelector,
  setTopUpBalanceActive,
} from "../../../redux/slices/financeSlice";
import {
  fetchTechnicalUsersList,
  fetchUsersList,
  globalDataSelector,
  setAdaptiveMenu,
} from "../../../redux/slices/globalDataSlice";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import useGetSearchParams from "../../../hooks/useGetSearchParams";
import useSetSearchParams from "../../../hooks/useSetSearchParams";
import React, { useEffect, useMemo, useState } from "react";
import { useSelectOptions } from "../../../utils/helpers/useSelectHelpers";
import { useDebounce } from "../../../hooks/useDebounce";
import {
  email,
  ordering,
  page,
  search,
} from "../../Authorized/Statistics/ClicksStats/ClicksStats";
import { getTableDefaultParams } from "../../../api/helpers/scripts";
import {
  INVALID_PAGE_ERROR,
  snackbar_error,
  snackbar_info,
  snackbar_success,
} from "../../../utils/vars/staticVariables";
import s from "./management.module.scss";
import GlobalSvgSelector from "../../../components/GlobalSvgSelector/GlobalSvgSelector";
import GradientSearch from "../../../components/partials/GradientSearch/GradientSearch";
import MultiSelect from "../../../components/MultiSelect/MultiSelect";
import EmptyList from "../../../components/EmptyList/EmptyList";
import { balancesOrderingList, params } from "../../../utils/vars/params";
import ParamsSort from "../../../components/Blocks/ParamsSort/ParamsSort";
import CustomSkeleton from "../../../components/CustomSkeleton/CustomSkeleton";
import Pagination from "../../../components/Pagination/Pagination";
import GradientBorder from "../../../components/Blocks/GradientBorder/GradientBorder";
import useCustomSnackbar from "../../../hooks/useCustomSnackbar";
import {
  authSelector,
  getImpersonateUser,
} from "../../../redux/slices/authSlice";
import { checkAllTokens } from "../../../redux/slices/pixelApiSlice";
import { useSnackbar } from "notistack";
import SetNotePopup from "../../../components/UiKit/Popups/SetNotePopup/SetNotePopup";
import AddNewTeam from "../../../components/UiKit/Popups/AddNewTeam/AddNewTeam";

const Management = () => {
  const [isAddNewTeamPopupActive, setAddNewTeamPopupActive] = useState(false);

  const { balancesList, balancesPending, totalCount } =
    useSelector(financeSelector);

  const {
    technicalUsersList,
    technicalUsersListPending,
    technicalUsersCount,
    isCollapsedSidebar,
    isAdaptiveMenuActive,
  } = useSelector(globalDataSelector);

  const { impersonateUserPending } = useSelector(authSelector);

  const dispatch = useDispatch();

  const showSnackbar = useCustomSnackbar();
  const { closeSnackbar } = useSnackbar();

  const [searchParams, setSearchParams] = useSearchParams();

  const { search: searchValue, page: pageValue } = useGetSearchParams();

  const updateSearchParams = useSetSearchParams(setSearchParams);

  const [name, setName] = useDebounce((value) => {
    if (value) {
      updateSearchParams(search, value);
    } else {
      updateSearchParams(search, value, true);
    }
  }, 600);

  useEffect(() => {
    dispatch(fetchUsersList());
  }, [dispatch]);

  useEffect(() => {
    if (!searchValue) {
      setName("");
    } else {
      setName(searchValue);
    }
  }, [searchParams]);

  useEffect(() => {
    const promise = dispatch(
      fetchTechnicalUsersList(
        getTableDefaultParams({
          searchParams,
          defaultPageSize: 25,
        }),
      ),
    );

    promise.unwrap().catch((err) => {
      if (err?.message === INVALID_PAGE_ERROR) {
        updateSearchParams(page, 1);
      }
    });

    return () => {
      promise.abort();
    };
  }, [dispatch, searchParams]);

  const impersonateUser = async (email) => {
    const impersonateUserPendingSnackbar = showSnackbar(
      `Выполняется вход в ${email}`,
      snackbar_info,
      true,
    );

    const impersonateUserResult = await dispatch(getImpersonateUser(email));

    const res = impersonateUserResult?.payload;

    console.log("res", res);

    if (res?.status === 200 && res?.data?.access) {
      // setTimeout(() => {
      localStorage.setItem("impersonateUserToken", res?.data?.access);
      // navigate("/app-catalog", { replace: true });
      // dispatch(checkAllTokens());
      window.location.href = "/app-catalog";
      // }, 1000);
    } else if (res?.data?.detail === "Юзер не найден") {
      closeSnackbar(impersonateUserPendingSnackbar);
      showSnackbar(`Юзер ${email} не был найден`, snackbar_error);
    } else if (res?.data?.detail === "Этот юзер неактивний") {
      closeSnackbar(impersonateUserPendingSnackbar);
      showSnackbar("Этот пользователь неактивен", snackbar_error);
    } else {
      closeSnackbar(impersonateUserPendingSnackbar);
      showSnackbar(`При входе в аккаунт произошла ошибка`, snackbar_error);
    }
  };

  return (
    <div
      className={`${s.balancesWrapper} ${isCollapsedSidebar === "true" ? s.collapsedSidebar : ""}`}
    >
      {/*<AddNewTeam*/}
      {/*  active={isAddNewTeamPopupActive}*/}
      {/*  setActive={setAddNewTeamPopupActive}*/}
      {/*/>*/}

      <div className={s.pageHeaderWrapper}>
        <div className={s.pageHeaderContainer}>
          <div className={s.staticElements}>
            <div className={s.leftSideWrapper}>
              <button
                className={s.walletBtn}
                onClick={() => {
                  dispatch(setAdaptiveMenu(false));
                  dispatch(setTopUpBalanceActive(true));
                }}
              >
                <GlobalSvgSelector id="wallet" />
              </button>
              <Link className={s.walletBtn} to="/account">
                <GlobalSvgSelector id="account-gear-icon" />
              </Link>
            </div>

            <button
              className={s.menuBtn}
              onClick={() => dispatch(setAdaptiveMenu(!isAdaptiveMenuActive))}
            >
              <GlobalSvgSelector id="burger-menu" />
            </button>
          </div>

          <div className={s.changingElements}>
            <GradientSearch value={name} setValue={setName} />

            {/*<button*/}
            {/*  onClick={() => setAddNewTeamPopupActive(true)}*/}
            {/*  className={s.primaryBtn}*/}
            {/*>*/}
            {/*  <GlobalSvgSelector id="plus-button" />*/}
            {/*  Создать команду*/}
            {/*</button>*/}
          </div>
        </div>
      </div>

      <div className={s.pageContent}>
        <div className={s.tabsWrapper}>
          <button className={s.tabItem}>
            <GlobalSvgSelector id="management-user-tab-icon" />
            <span>Пользователи</span>
          </button>
          <div className={s.gradientContainer}>
            <GradientBorder className={s.gradientBorderCustom} />
          </div>
        </div>
        <div className={s.pageInnerContent}>
          {technicalUsersCount === 0 && !technicalUsersListPending ? (
            <EmptyList />
          ) : technicalUsersListPending ? (
            <section className={s.body}>
              <table>
                <tr>
                  <th>Email</th>
                  <th>Команда</th>
                  <th>Цена за инстал</th>
                </tr>
              </table>
              <div className={s.skeletonWrapper}>
                <CustomSkeleton styles={s.skeletonTable} />
              </div>
            </section>
          ) : (
            <section className={s.body}>
              <table>
                <thead>
                  <tr>
                    <th>Email</th>
                    <th>Команда</th>
                    <th>Цена за инстал</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {technicalUsersList?.map(
                    ({ email, install_price_bonus, offer }) => {
                      return (
                        <tr key={email}>
                          <td>{email}</td>
                          <td>{offer}</td>
                          <td>{install_price_bonus}</td>
                          <td className={s.enterBtnTd}>
                            <button
                              onClick={() => impersonateUser(email)}
                              className={s.primaryBtn}
                              disabled={impersonateUserPending}
                            >
                              Войти
                            </button>
                          </td>
                        </tr>
                      );
                    },
                  )}
                </tbody>
              </table>
            </section>
          )}
        </div>

        <div
          className={`${s.paginationContainer} ${isCollapsedSidebar === "true" ? s.collapsed : ""}`}
        >
          <div className={s.newRecordsCount}>
            Всего:{" "}
            {!technicalUsersListPending && <span>{technicalUsersCount}</span>}
          </div>
          {technicalUsersCount > 25 && (
            <Pagination
              currentPage={parseInt(pageValue) || 1}
              setCurrentPage={(page) => {
                updateSearchParams(params.page, page);
              }}
              total={technicalUsersCount}
              limit={25}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Management;
