import {useEffect, useState} from "react";

export function useDebounce(callback, customDelay) {
    const [value, setValue] = useState("");

    useEffect(() => {
        const delay = customDelay || 500;
        const timer = setTimeout(() => {
            callback(value);
        }, delay);

        return () => clearTimeout(timer);

    }, [value]);

    return [value, setValue];
}
