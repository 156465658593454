import React from "react";
import Loader from "../../Loader/Loader";
import s from "./primaryBorderBtn.module.scss";

const PrimaryBtn = ({ action, text, pending, submit, disabled, className }) => {
  return (
    <button
      type={submit ? "submit" : "button"}
      className={`${s.blueBorderBtn} ${className || ""}`}
      disabled={!disabled ? pending : disabled}
      onClick={action}
      // onClick={() => {
      //   if (!submit && action) {
      //     action(); // Викликаємо тільки якщо це не submit
      //   }
      // }}
    >
      {text}
    </button>
  );
};

export default PrimaryBtn;
