import React, {useState} from 'react';
import {Link, useLocation, useNavigate} from "react-router-dom";
import {useForm} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import {TextField} from "@mui/material";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {authTextFieldStyles} from "../../Auth/Auth";
import {resetPasswordConfirm} from "../../../../redux/slices/authSlice";
import GlobalSvgSelector from "../../../../components/GlobalSvgSelector/GlobalSvgSelector";
import Loader from "../../../../components/UiKit/Loader/Loader";
import s from "./resetNewPassword.module.scss";

const validationSchema = yup.object().shape({

    newPassword1: yup
        .string()
        // .matches(/^[A-Za-z0-9]*$/, 'Только латинские символы')
        .matches(/^[A-Za-z0-9\s!@#$%^&*(),.?":{}|<>[\]\\/`~;'\-+=]*$/, 'Только латинские символы')
        .min(8, 'Минимум 8 символов')
        .max(80, 'Максимум 80 символов')
        .matches(/[0-9]/, 'Минимум одна цифра')
        .matches(/[A-Z]/, 'Минимум одна большая буква')
        .matches(/[a-z]/, 'Минимум одна маленькая буква')
        .required('Обязательное поле'),

    newPassword2: yup
        .string()
        .oneOf([yup.ref('newPassword1'), null], 'Пароли должны совпадать')
        .required('Обязательное поле'),
});


const ResetNewPassword = () => {
    // const {register, handleSubmit, setError, formState: {errors}} = useForm();
    const {
        register,
        handleSubmit,
        control,
        formState: {errors},
    } = useForm({
        resolver: yupResolver(validationSchema),
    });
    // const {cid} = useParams()
    const [isShowedNewPassword1, setShowedNewPassword1] = useState(false);
    const [isShowedNewPassword2, setShowedNewPassword2] = useState(false);

    // const params = useParams();

    const navigate = useNavigate()
    const location = useLocation();

    // Отримати шлях після "/reset/new-password/"
    const path = location.pathname.replace('/reset/new-password/', '');
    const decodedUrlList = decodeURIComponent(path)?.split('/');

    const uid = decodedUrlList[0]
    const token = decodedUrlList[1]

    console.log('uid', uid)
    console.log('token', token)

    const {loading} = useSelector((state) => state.auth)
    const dispatch = useDispatch()

    const onSubmit = async data => {
        const {newPassword1, newPassword2} = data

        const dataObj = {
            uid: uid?.trim(),
            token: token?.trim(),
            new_password: newPassword1?.trim(),
            re_new_password: newPassword2?.trim()
        }

        const onSuccessAction = () => {
            navigate('/register/success')
        }

        const onErrorAction = () => {
            navigate('/register/error')
        }

        dispatch(resetPasswordConfirm({data: dataObj, onSuccessAction, onErrorAction}))
    };


    return (
        <form
            className={s.authContainer}
            onSubmit={handleSubmit(onSubmit)}
        >
            <div className={s.header}>
                <h5>Смена пароля</h5>
                <span className={s.subtitle}>Введите новый пароль</span>
            </div>

            <div className={s.content}>

                <div className={s.field}>
                    <button
                        className={s.showPasswordIcon}
                        onClick={(e) => {
                            e.preventDefault();
                            setShowedNewPassword1(!isShowedNewPassword1);
                        }}
                    >
                        <GlobalSvgSelector
                            id={
                                isShowedNewPassword1
                                    ? "show-password-icon"
                                    : "hide-password-icon"
                            }
                        />
                    </button>
                    <TextField
                        {...register('newPassword1', {
                            required: 'Обязательное поле',
                        })}
                        label="Новый пароль"

                        error={!!errors.newPassword1}
                        helperText={errors.newPassword1 ? errors.newPassword1.message : ''}
                        type={isShowedNewPassword1 ? "text" : "password"}
                        autoComplete="current-password"
                        sx={authTextFieldStyles}
                    />
                </div>
                <div className={s.field}>
                    <button
                        className={s.showPasswordIcon}
                        onClick={(e) => {
                            e.preventDefault();
                            setShowedNewPassword2(!isShowedNewPassword2);
                        }}
                    >
                        <GlobalSvgSelector
                            id={
                                isShowedNewPassword2
                                    ? "show-password-icon"
                                    : "hide-password-icon"
                            }
                        />
                    </button>
                    <TextField
                        {...register('newPassword2', {
                            required: 'Обязательное поле',
                        })}
                        label="Повторите пароль"

                        error={!!errors.newPassword2}
                        helperText={errors.newPassword2 ? errors.newPassword2.message : ''}
                        type={isShowedNewPassword2 ? "text" : "password"}
                        autoComplete="current-password"
                        sx={authTextFieldStyles}
                    />
                </div>
            </div>
            <div className={s.footer}>
                <button
                    className={s.loginBtn}
                    type="submit"
                    disabled={loading}
                >
                    {loading ? <Loader/> : 'Подтвердить'}
                </button>
            </div>
            <div className={s.createAccountContainer}>
                <Link to='/auth/login'>Вернуться к авторизации</Link>
            </div>
        </form>
    );
};

export default ResetNewPassword;