import { getAccessToken } from "../storage/storage";
import { getApiUrl } from "./url";

export const MAX_WS_RECONNECT_ATTEMPTS = 5; // Максимальна кількість спроб підключення

function getWebSocketApiUrl() {
  return getApiUrl()
    .replace("https:", "wss:")
    .replace("http:", "wss:")
    .replace("/api", "/websocket");
}

/// GLOBAL
export const wsOptions = {
  share: true,
  shouldReconnect: () => false,
};

/// Finance
export const getFinanceWebSocket = () =>
  getWebSocketApiUrl() + "/finance-websocket/?token=" + getAccessToken();

export function decodeFinanceEvent(event) {
  const { message } = JSON.parse(event.data);

  return message;
}

// source
//     :
//     "update_user_balance_notification"

export const wsSource = {
  balance: "update_user_balance_notification",
};
