import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { prelandingSelector } from "../../../../../../../../redux/slices/prelandingSlice";
import CustomSkeleton from "../../../../../../../../components/CustomSkeleton/CustomSkeleton";
import s from "../../../../../../Prelandings/EditPrelanding/EditDefault/editDefault.module.scss";
import ReactSelectTheme from "../../../../../../../../components/Blocks/ReactSelectTheme/ReactSelectTheme";
import {
  prelandingStatusList,
  prelandingTypesList,
} from "../../../../../../../../utils/vars/staticVariables";
import LimitedInput from "../../../../../../../../components/LimitedInput/LimitedInput";
import { Radio } from "@mui/material";
import AdaptiveWheelPreviewBtn from "../../../../../../Prelandings/AdaptiveWheelPreviewBtn/AdaptiveWheelPreviewBtn";
import PopupWheel from "../../../../../../Prelandings/PopupWheel/PopupWheel";
import Wheel from "../../../../../../Prelandings/Wheel/Wheel";

import PrimaryBtn from "../../../../../../../../components/UiKit/Buttons/PrimaryBorderBtn/PrimaryBorderBtn";

const EditDefault = ({ formik, setContent }) => {
  const [activeAdaptiveWheelPreview, setActiveAdaptiveWheelPreview] =
    useState(false);

  const errorRefs = useRef({});

  const {
    wheelStylesList,
    wheelStylesLoading,
    editPrelandPending,
    getPrelandPending,
  } = useSelector(prelandingSelector);

  const {
    header_text,
    rotation_button_text,
    respin_button_text,
    style,
    sectors,
    bonus_button_text,
    popup_win_text,
    winning_button_text,
  } = formik.values;

  const skeletons = (styles) => {
    return [...new Array(15)].map((_, index) => (
      <CustomSkeleton styles={styles} key={index} />
    ));
  };

  return (
    <form className={s.createPrelandingForm} onSubmit={formik.handleSubmit}>
      <div className={s.formContentWrapper}>
        <div className={s.formFieldsWrapper}>
          <div className={s.fieldWrapper}>
            <p className={s.fieldName}>Преленд</p>
            <ReactSelectTheme
              darkTheme={true}
              options={prelandingStatusList}
              placeHolder="Выберите отображение преленда"
              value={prelandingStatusList.find(
                ({ value }) => value === formik.values.status,
              )}
              action={(selectedOption) =>
                formik.setFieldValue("status", selectedOption.value)
              }
            />
          </div>
          <div
            className={s.fieldWrapper}
            ref={(el) => (errorRefs.current.name = el)}
          >
            <p className={s.fieldName}>Название</p>
            <input
              type="text"
              name="name"
              className={`${s.textInput} ${
                formik.touched.name && formik.errors.name ? s.error : ""
              }`}
              placeholder="Название прелендинга"
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.name && formik.errors.name ? (
              <div className={s.error}>{formik.errors.name}</div>
            ) : null}
          </div>
          <div className={s.fieldWrapper}>
            <p className={s.fieldName}>Тип преленда</p>
            <ReactSelectTheme
              darkTheme={true}
              options={prelandingTypesList}
              placeHolder="Выберите тип преленда"
              value={prelandingTypesList.find(
                ({ value }) => value === formik.values.prelanding_type,
              )}
              action={(selectedOption) =>
                formik.setFieldValue("prelanding_type", selectedOption.value)
              }
            />
          </div>
          <div
            className={s.fieldWrapper}
            ref={(el) => (errorRefs.current.header_text = el)}
          >
            <p className={s.fieldName}>Заголовок</p>
            <LimitedInput
              value={formik.values.header_text}
              fieldName="header_text"
              action={formik.handleChange}
              onBlur={formik.handleBlur}
              formik={formik}
              maxLength={20}
            />
            {formik.touched.header_text && formik.errors.header_text ? (
              <div className={s.error}>{formik.errors.header_text}</div>
            ) : null}
          </div>
          <div
            className={s.fieldWrapper}
            ref={(el) => (errorRefs.current.rotation_button_text = el)}
          >
            <p className={s.fieldName}>Текст кнопки вращения</p>
            <LimitedInput
              value={formik.values.rotation_button_text}
              fieldName="rotation_button_text"
              action={formik.handleChange}
              onBlur={formik.handleBlur}
              formik={formik}
              maxLength={10}
            />
            {formik.touched.rotation_button_text &&
            formik.errors.rotation_button_text ? (
              <div className={s.error}>
                {formik.errors.rotation_button_text}
              </div>
            ) : null}
          </div>
          <div
            className={s.fieldWrapper}
            ref={(el) => (errorRefs.current.respin_button_text = el)}
          >
            <p className={s.fieldName}>Текст кнопки повторного вращения</p>
            <LimitedInput
              value={formik.values.respin_button_text}
              fieldName="respin_button_text"
              action={formik.handleChange}
              onBlur={formik.handleBlur}
              formik={formik}
              maxLength={10}
            />
            {formik.touched.respin_button_text &&
            formik.errors.respin_button_text ? (
              <div className={s.error}>{formik.errors.respin_button_text}</div>
            ) : null}
          </div>
          <div
            className={s.fieldWrapper}
            ref={(el) => (errorRefs.current.style = el)}
          >
            <p className={s.fieldName}>Стиль колеса</p>
            <ul className={s.wheelStylesWrapper}>
              {wheelStylesLoading
                ? skeletons(s.wheelSkeleton)
                : wheelStylesList.map(({ id, wheel }) => (
                    <button
                      type="button"
                      key={id}
                      onClick={() => formik.setFieldValue("style", id)}
                    >
                      <img
                        src={wheel}
                        alt="wheel picture"
                        className={`${s.wheelImg} ${
                          id === formik.values.style ? s.active : ""
                        }`}
                      />
                    </button>
                  ))}
            </ul>
          </div>
          <div className={s.fieldWrapper}>
            <p className={s.fieldName}>
              Значения секторов и сектор финального выигрыша
            </p>
            <div className={s.sectorsWrapper}>
              {formik.values.sectors.map(({ is_win, value }, index) => (
                <div className={s.sectorInputWrapper} key={index}>
                  <div className={s.markWinSector}>
                    <label htmlFor={`radio-${index}`}>WIN</label>
                    <Radio
                      id={`radio-${index}`}
                      value={index}
                      sx={{
                        height: 48,
                        boxSizing: "border-box",
                        padding: "3px",
                        color: "white",
                      }}
                      checked={is_win}
                      onChange={() => {
                        const updatedSectors = formik.values.sectors.map(
                          (sector, sectorIndex) => ({
                            ...sector,
                            is_win: index === sectorIndex,
                          }),
                        );
                        formik.setFieldValue("sectors", updatedSectors);
                      }}
                    />
                  </div>
                  <input
                    type="text"
                    className={`${s.textInput} ${
                      formik.touched.sectors?.[index]?.value &&
                      formik.errors.sectors?.[index]?.value
                        ? s.error
                        : ""
                    }`}
                    value={value}
                    onChange={(e) => {
                      const updatedSectors = formik.values.sectors.map(
                        (sector, sectorIndex) =>
                          sectorIndex === index
                            ? { ...sector, value: e.target.value }
                            : sector,
                      );
                      formik.setFieldValue("sectors", updatedSectors);
                    }}
                    placeholder="Введите текст"
                    maxLength={10}
                    onBlur={() =>
                      formik.setFieldTouched(`sectors.${index}.value`)
                    }
                  />
                  {formik.touched.sectors?.[index]?.value &&
                  formik.errors.sectors?.[index]?.value ? (
                    <div className={s.error}>
                      {formik.errors.sectors[index].value}
                    </div>
                  ) : null}
                  <div className={s.lengthIndicator}>{value.length}/10</div>
                </div>
              ))}
            </div>
          </div>
          <div
            className={s.fieldWrapper}
            ref={(el) => (errorRefs.current.bonus_button_text = el)}
          >
            <p className={s.fieldName}>Заголовок в попапе выигрыша</p>
            <LimitedInput
              value={formik.values.bonus_button_text}
              fieldName="bonus_button_text"
              action={formik.handleChange}
              onBlur={formik.handleBlur}
              formik={formik}
              maxLength={16}
            />
            {formik.touched.bonus_button_text &&
            formik.errors.bonus_button_text ? (
              <div className={s.error}>{formik.errors.bonus_button_text}</div>
            ) : null}
          </div>
          <div
            className={s.fieldWrapper}
            ref={(el) => (errorRefs.current.popup_win_text = el)}
          >
            <p className={s.fieldName}>Текст в попапе выигрыша</p>
            <LimitedInput
              value={formik.values.popup_win_text}
              fieldName="popup_win_text"
              action={formik.handleChange}
              onBlur={formik.handleBlur}
              formik={formik}
              maxLength={30}
            />
            {formik.touched.popup_win_text && formik.errors.popup_win_text ? (
              <div className={s.error}>{formik.errors.popup_win_text}</div>
            ) : null}
            <p className={s.fieldName} style={{ color: "#99A0AE" }}>
              Используйте {`{bonus}`} для того, чтобы подставить значение
              выигрыша в тексте поздравления
            </p>
          </div>
          <div
            className={s.fieldWrapper}
            ref={(el) => (errorRefs.current.winning_button_text = el)}
          >
            <p className={s.fieldName}>Текст кнопки в попапе выигрыша</p>
            <LimitedInput
              value={formik.values.winning_button_text}
              fieldName="winning_button_text"
              action={formik.handleChange}
              onBlur={formik.handleBlur}
              formik={formik}
              maxLength={10}
            />
            {formik.touched.winning_button_text &&
            formik.errors.winning_button_text ? (
              <div className={s.error}>{formik.errors.winning_button_text}</div>
            ) : null}
          </div>
        </div>
        <AdaptiveWheelPreviewBtn setActive={setActiveAdaptiveWheelPreview} />
        <PopupWheel
          setActive={setActiveAdaptiveWheelPreview}
          active={activeAdaptiveWheelPreview}
          wheelNumbers={sectors.map((sector) => sector.value)}
          prelandTitle={header_text}
          spinButtonText={rotation_button_text}
          respinButtonText={respin_button_text}
          winSector={sectors.findIndex((item) => item.is_win == true)}
          popupTitle={bonus_button_text}
          currentWheel={wheelStylesList.find((item) => item.id === style)}
          popupText={popup_win_text}
          popupButtonText={winning_button_text}
        />
        <div className={s.iPhoneWrapper}>
          <Wheel
            wheelNumbers={sectors.map((sector) => sector.value)}
            prelandTitle={header_text}
            spinButtonText={rotation_button_text}
            respinButtonText={respin_button_text}
            winSector={sectors.findIndex((item) => item.is_win == true)}
            popupTitle={bonus_button_text}
            currentWheel={wheelStylesList.find((item) => item.id === style)}
            popupText={popup_win_text}
            popupButtonText={winning_button_text}
          />
        </div>
      </div>
      <div className={s.buttons}>
        <button
          className={s.whiteBorderBtn}
          type="button"
          onClick={() => {
            setContent("choose");
          }}
        >
          Отменить
        </button>
        <PrimaryBtn
          text="Сохранить"
          submit={true}
          pending={editPrelandPending || getPrelandPending}
          className={s.customPrimaryBtn}
        />
      </div>
    </form>
  );
};

export default EditDefault;
