import React from "react";
import s from "./page404.module.scss";
import {
  globalDataSelector,
  setAdaptiveMenu,
} from "../../../redux/slices/globalDataSlice";
import { setTopUpBalanceActive } from "../../../redux/slices/financeSlice";
import GlobalSvgSelector from "../../../components/GlobalSvgSelector/GlobalSvgSelector";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

const Page404 = () => {
  const { isCollapsedSidebar, isAdaptiveMenuActive } =
    useSelector(globalDataSelector);

  const dispatch = useDispatch();

  return (
    <div
      className={`${s.page404Wrapepr} ${
        isCollapsedSidebar === "true" ? s.collapsedSidebar : ""
      }`}
    >
      <div className={s.pageHeaderWrapper}>
        <div className={s.pageHeaderContainer}>
          <div className={s.staticElements}>
            <div className={s.leftSideWrapper}>
              <button
                className={s.walletBtn}
                onClick={() => {
                  dispatch(setAdaptiveMenu(false));
                  dispatch(setTopUpBalanceActive(true));
                }}
              >
                <GlobalSvgSelector id="wallet" />
              </button>
              <Link className={s.walletBtn} to="/account">
                <GlobalSvgSelector id="account-gear-icon" />
              </Link>
            </div>

            <button
              type="button"
              className={s.menuBtn}
              onClick={() => dispatch(setAdaptiveMenu(!isAdaptiveMenuActive))}
            >
              <GlobalSvgSelector id="burger-menu" />
            </button>
          </div>
        </div>
      </div>
      <div className={s.pageContent}>
        <div className={s.contentWrapper}>
          <span className={s.loader}></span>
          <div className={s.letters404}>404</div>
          <div className={s.messages}>
            <p>Кажется, мы не можем найти страницу, которую вы ищете.</p>
            <p>
              Возможно, вы ввели неверный адрес или страница переместилась.
              Попробуйте еще раз.
            </p>
          </div>
          <div className={s.buttonContainer}>
            <Link to="/app-catalog" className={s.primaryBtn}>
              Вернутся на главную
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Page404;
