import { pageSizeOptions } from "../../components/UiKit/PageSizeDropdown/PageSizeDropdown";
import { setPageSizeValue } from "../../redux/slices/globalDataSlice";
import { setPageSize } from "../../storage/statisticsPageSize";

export const onSetPageSize = (dispatch, selectedSize) => {
  let size = selectedSize;
  if (!pageSizeOptions.includes(Number(size))) {
    size = pageSizeOptions[0];
  }
  dispatch(setPageSizeValue(size));
  setPageSize(size);
};
