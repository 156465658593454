import React, { useEffect, useState } from "react";
import s from "./whitePage.module.scss";
import { Link } from "react-router-dom";
import PrimaryBtn from "../../../../../components/UiKit/Buttons/PrimaryBorderBtn/PrimaryBorderBtn";
import ReactSelectTheme from "../../../../../components/Blocks/ReactSelectTheme/ReactSelectTheme";
import { useSelector } from "react-redux";
import { authSelector } from "../../../../../redux/slices/authSlice";

import {
  cloacaModeOptions,
  cloacaTypesOptions,
  whitePageTypeOptions,
  wordPressOptions,
} from "../../../../../utils/vars/staticVariables";
import GlobalSvgSelector from "../../../../../components/GlobalSvgSelector/GlobalSvgSelector";
import { globalDataSelector } from "../../../../../redux/slices/globalDataSlice";
import IpSelect from "../../IpSelect/IpSelect";
import { flowSettingsSelector } from "../../../../../redux/slices/flowSettingsSlice";
import FlowSettingsGeoSelect from "../../../../../components/Blocks/Selects/FlowSettingsGeoSelect";

const WhitePage = ({ formik }) => {
  const [geoListOptions, setGeoListOptions] = useState([]);
  const [ipSelectErrorMessage, setIpSelectErrorMessage] = useState("");
  const [isVisibleCloacaModeTooltip, setIsVisibleCloacaModeTooltip] =
    useState(false);

  const { setWhitePageSettingsPending, fetchFlowByIdPending } =
    useSelector(flowSettingsSelector);
  const { isPartner } = useSelector(authSelector);
  const { geoList } = useSelector(globalDataSelector);

  const tdsClickIdMessage = isPartner
    ? "Если вы заливаете напрямую на WWPartners, используя личный кабинет, а не свой трекер, обязательно передавайте в ссылке sub8={tds_clickid}"
    : `Обязательно передайте значение {tds_clickid} в одном из своих параметров`;

  useEffect(() => {
    let geoListArr = geoList.map((item) => ({
      label: item.name,
      value: item.id,
      icon: item.flag,
    }));

    if (geoListArr.length > 0) {
      if (formik.values.geo.length !== geoListArr.length) {
        setGeoListOptions([
          { value: "all_geos", label: "Все гео" },
          ...geoListArr,
        ]);
      } else {
        setGeoListOptions(geoListArr);
      }
    }
  }, [geoList, formik.values.geo]);

  return (
    <form className={s.whitePageForm} onSubmit={formik.handleSubmit}>
      <div className={s.pageInnerContent}>
        <div className={s.fieldsWrapper}>
          <div className={s.fieldsColumn}>
            <div className={s.fieldColumn}>
              <div className={s.fieldWrapper}>
                <p className={s.fieldName}>
                  Ссылка на оффер/ваш трекер{" "}
                  {/*<GlobalSvgSelector id="info-primary-circle-icon" />*/}
                </p>
                <input
                  type="text"
                  name="url"
                  className={`${s.textInput} ${
                    formik.touched.url && formik.errors.url ? s.error : ""
                  }`}
                  placeholder={
                    fetchFlowByIdPending ? "Загрузка..." : "https://example.com"
                  }
                  value={fetchFlowByIdPending ? "" : formik.values.url}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <p className={s.tipMessage}>
                  <span>{tdsClickIdMessage}</span>
                </p>
                {formik.touched.url && formik.errors.url ? (
                  <div className={s.error}>{formik.errors.url}</div>
                ) : null}
              </div>
              <div className={s.fieldWrapper}>
                <p className={s.fieldName}>
                  Название
                  <span>( обязательное поле )</span>
                </p>
                <input
                  type="text"
                  name="notes"
                  className={`${s.textInput} ${
                    formik.touched.notes && formik.errors.notes ? s.error : ""
                  }`}
                  placeholder={
                    fetchFlowByIdPending ? "Загрузка..." : "Название потока"
                  }
                  value={fetchFlowByIdPending ? "" : formik.values.notes}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.notes && formik.errors.notes ? (
                  <div className={s.error}>{formik.errors.notes}</div>
                ) : null}
              </div>
            </div>

            <div>
              <p className={s.rowtitle}>
                <GlobalSvgSelector id="db-icon" /> Настройки клоаки
              </p>
              <div className={s.rowsWrapper}>
                <div className={s.fieldRow}>
                  <div className={s.fieldWrapper}>
                    <p className={s.fieldName}>Тип клоаки</p>
                    <ReactSelectTheme
                      darkTheme={true}
                      options={cloacaTypesOptions}
                      placeHolder="Выберите пункт"
                      value={cloacaTypesOptions[0]}
                      action={() => {
                        return;
                      }}
                    />
                  </div>
                  <div className={s.fieldWrapper}>
                    {isVisibleCloacaModeTooltip && (
                      <div className={s.cloacaModeTooltipWrapper}>
                        <p>
                          <span>Включена</span> - работает в режиме фильтрации
                          трафика
                        </p>
                        <p>
                          <span>Выключена</span> - пропускает весь трафик
                        </p>
                        <p>
                          <span>Режим модерации</span> - не пропускает трафик,
                          рекомендуем использовать во время модерации рекламной
                          кампании, после отключать этот режим
                        </p>
                      </div>
                    )}
                    <p className={s.fieldName}>
                      Режим работы клоаки
                      <button
                        type="button"
                        onMouseEnter={() => setIsVisibleCloacaModeTooltip(true)}
                        onMouseLeave={() =>
                          setIsVisibleCloacaModeTooltip(false)
                        }
                        onClick={() =>
                          setIsVisibleCloacaModeTooltip((prev) => !prev)
                        }
                      >
                        <GlobalSvgSelector id="info-primary-circle-icon" />
                      </button>
                    </p>
                    <ReactSelectTheme
                      darkTheme={true}
                      options={cloacaModeOptions}
                      placeholder={
                        fetchFlowByIdPending ? "Загрузка..." : "Выберите пункт"
                      }
                      value={fetchFlowByIdPending ? null : formik.values.status}
                      action={(selectedOption) =>
                        formik.setFieldValue("status", selectedOption)
                      }
                    />
                  </div>
                </div>
                <div className={s.fieldRow}>
                  <div className={s.fieldWrapper}>
                    <p className={s.fieldName}>
                      Гео
                      <span>( обязательное поле )</span>
                    </p>

                    <FlowSettingsGeoSelect
                      darkTheme={true}
                      isClearable={true}
                      isMulti={true}
                      options={geoListOptions}
                      placeholder={
                        fetchFlowByIdPending ? "Загрузка..." : "Выберите гео"
                      }
                      value={fetchFlowByIdPending ? null : formik.values.geo}
                      isInvalid={formik.touched.geo && formik.errors.geo}
                      action={(selectedOption) => {
                        if (
                          selectedOption.some((obj) => obj.value === "all_geos")
                        ) {
                          const allOptions = geoListOptions.slice(1);
                          formik.setFieldValue("geo", allOptions);
                          setGeoListOptions(allOptions);
                        } else {
                          if (
                            !geoListOptions.some(
                              (item) => item.value === "all_geos",
                            )
                          ) {
                            setGeoListOptions((prev) => [
                              { value: "all_geos", label: "Все гео" },
                              ...prev,
                            ]);
                          }
                          formik.setFieldValue("geo", selectedOption);
                        }
                      }}
                    />
                    {formik.touched.geo && formik.errors.geo ? (
                      <div className={s.error}>{formik.errors.geo}</div>
                    ) : null}
                  </div>

                  <div className={s.fieldWrapper}>
                    <p className={s.fieldName}>
                      Белый список IP
                      {/*<GlobalSvgSelector id="info-primary-circle-icon" />*/}
                    </p>
                    <IpSelect
                      setErrorMessage={setIpSelectErrorMessage}
                      placeholder={
                        fetchFlowByIdPending ? "Загрузка..." : "IP адрес"
                      }
                      selectedOption={
                        fetchFlowByIdPending ? null : formik.values.allowed_ips
                      }
                      onChange={(value) => {
                        formik.setFieldValue("allowed_ips", value);
                      }}
                      onCreate={(ip) => {
                        const updatedIps = [
                          ...formik.values.allowed_ips,
                          { value: ip, label: ip },
                        ];

                        formik.setFieldValue("allowed_ips", updatedIps);
                      }}
                      isInvalid={ipSelectErrorMessage}
                    />
                    {ipSelectErrorMessage && (
                      <div className={s.error}>{ipSelectErrorMessage}</div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div>
              <p className={s.rowtitle}>
                <GlobalSvgSelector id="db-icon" /> Настройки WhitePage
              </p>
              <div className={s.rowsWrapper}>
                <div className={s.fieldRow}>
                  <div className={s.fieldWrapper}>
                    <p className={s.fieldName}>Тип WhitePage</p>
                    <ReactSelectTheme
                      darkTheme={true}
                      options={whitePageTypeOptions}
                      placeholder={
                        fetchFlowByIdPending ? "Загрузка..." : "Выберите пункт"
                      }
                      value={
                        fetchFlowByIdPending ? "" : formik.values.type_of_cloaca
                      }
                      action={(option) => {
                        formik.setFieldValue("type_of_cloaca", option);
                      }}
                    />
                  </div>
                  <div className={s.fieldWrapper}>
                    <p className={s.fieldName}>
                      URL на приложение из {formik.values.type_of_cloaca.label}{" "}
                      для Whitepage
                    </p>

                    {formik.values.type_of_cloaca.value === "ap" && (
                      <input
                        type="text"
                        name="appStoreUrl"
                        className={s.textInput}
                        placeholder={
                          fetchFlowByIdPending
                            ? "Загрузка..."
                            : "Ссылка на AppStore"
                        }
                        value={
                          fetchFlowByIdPending ? "" : formik.values.appStoreUrl
                        }
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    )}

                    {formik.values.type_of_cloaca.value === "gp" && (
                      <input
                        type="text"
                        name="googlePlayUrl"
                        className={`${s.textInput} ${
                          formik.touched.googlePlayUrl &&
                          formik.errors.googlePlayUrl
                            ? s.error
                            : ""
                        }`}
                        placeholder={
                          fetchFlowByIdPending
                            ? "Загрузка..."
                            : "Ссылка на GooglePlay"
                        }
                        value={
                          fetchFlowByIdPending
                            ? ""
                            : formik.values.googlePlayUrl
                        }
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    )}
                    {formik.touched.googlePlayUrl &&
                    formik.errors.googlePlayUrl ? (
                      <div className={s.error}>
                        {formik.errors.googlePlayUrl}
                      </div>
                    ) : null}

                    {formik.values.type_of_cloaca.value === "wp" && (
                      <ReactSelectTheme
                        darkTheme={true}
                        options={wordPressOptions}
                        placeholder={
                          fetchFlowByIdPending
                            ? "Загрузка..."
                            : "Выберите пункт"
                        }
                        value={
                          fetchFlowByIdPending
                            ? ""
                            : formik.values.wordPressOption
                        }
                        action={(selectedOption) =>
                          formik.setFieldValue(
                            "wordPressOption",
                            selectedOption,
                          )
                        }
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={s.buttons}>
        <Link className={s.whiteBorderBtn} to="/my-flows">
          Отменить
        </Link>
        <PrimaryBtn
          text="Сохранить"
          submit={true}
          pending={fetchFlowByIdPending || setWhitePageSettingsPending}
          className={s.customPrimaryBtn}
        />
      </div>
    </form>
  );
};

export default WhitePage;
