import React from "react";
import { useSelector } from "react-redux";
import { financeSelector } from "../../../../redux/slices/financeSlice";
import { convertDate } from "../../../../utils/helpers/formatTime";
import EmptyList from "../../../../components/EmptyList/EmptyList";
import CustomSkeleton from "../../../../components/CustomSkeleton/CustomSkeleton";
import s from "../transactions.module.scss";

const RefillOption = () => {
  const { transactionsLoading, transactionsList, totalCount } =
    useSelector(financeSelector);

  return (
    <>
      {totalCount === 0 && !transactionsLoading ? (
        <EmptyList />
      ) : transactionsLoading ? (
        <section className={s.body}>
          <table>
            <thead>
              <tr>
                <th>Дата</th>
                <th>Сумма</th>
                <th>Hash Транзакции</th>
              </tr>
            </thead>
          </table>
          <div className={s.skeletonWrapper}>
            <CustomSkeleton styles={s.skeletonTable} />
          </div>
        </section>
      ) : (
        <section className={s.body}>
          <table>
            <thead>
              <tr>
                <th>Дата</th>
                <th>Сумма</th>
                <th>Hash Транзакции</th>
              </tr>
            </thead>
            <tbody>
              {transactionsList.map(
                ({ id, created_at, amount, crypto_currency_transaction }) => (
                  <tr key={id}>
                    <td className={s.dateTd}>
                      <span>{convertDate(created_at, "DMY")}</span>{" "}
                      <span>{convertDate(created_at, "HM")}</span>
                    </td>
                    <td>$ {amount}</td>
                    <td>
                      {crypto_currency_transaction?.txid ? (
                        <a
                          target="_blank"
                          href={`https://tronscan.org/#/transaction/${crypto_currency_transaction?.txid}`}
                          className={s.hashContent}
                          rel="noreferrer"
                        >
                          {crypto_currency_transaction?.txid}
                        </a>
                      ) : (
                        ""
                      )}
                    </td>
                  </tr>
                ),
              )}
            </tbody>
          </table>
        </section>
      )}
    </>
  );
};

export default RefillOption;
