import React from "react";
import GlobalSvgSelector from "../GlobalSvgSelector/GlobalSvgSelector";
import MuiTooltip from "../UiKit/MuiTooltip/MuiTooltip";
import useCustomSnackbar from "../../hooks/useCustomSnackbar";
import s from "./baseTextInput.module.scss";

const BaseTextInput = ({
  value,
  placeholder,
  action,
  copy,
  redirect,
  alertMessage,
  alertType,
}) => {
  const showSnackbar = useCustomSnackbar();

  const copyText = async () => {
    await navigator.clipboard.writeText(value);
    showSnackbar(alertMessage, alertType);
  };

  return (
    <div className={s.mainInputWrapper}>
      <input
        readOnly={!action}
        type="text"
        value={value}
        onChange={action}
        className={s.textInput}
        placeholder={placeholder}
        style={redirect ? { paddingRight: 95 } : null}
      />
      {copy && value && (
        <div className={s.copyRedirectContainer}>
          <button className={s.copyBtn} onClick={copyText}>
            <MuiTooltip title="Скопировать">
              <GlobalSvgSelector id="copy-link-icon" />
            </MuiTooltip>
          </button>
          {redirect && (
            <a
              href={value}
              target="_blank"
              className={s.followLink}
              rel="noreferrer"
            >
              <MuiTooltip title="Открыть ссылку">
                <GlobalSvgSelector id="follow-link-icon" />
              </MuiTooltip>
            </a>
          )}
        </div>
      )}
    </div>
  );
};

export default BaseTextInput;
