import {
  fetchCryptoWallet,
  fetchUserBalance,
} from "../../redux/slices/financeSlice";

export const fetchInitialData = async (dispatch) => {
  try {
    await Promise.all([
      dispatch(fetchCryptoWallet()).unwrap(),
      dispatch(fetchUserBalance()).unwrap(),
    ]);
  } catch (error) {
    console.error("Failed to fetch data:", error);
  }
};
