import React, { useEffect, useRef, useState } from "react";
import s from "../appSettings.module.scss";
import GlobalSvgSelector from "../../../GlobalSvgSelector/GlobalSvgSelector";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  addAllowedIp,
  getFlowById,
  // getFlowById,
  setAllowedIps,
  setCloacaType,
  setFbType,
  setFlowObj,
  setGeoSelectInput,
  setOfferLink,
  setSelectedGeos,
  setStatus,
  setWhitePageUrl,
  setWordPressLabel,
  setWordPressName,
  setWordPressOption,
  updateFlowSettings,
} from "../../../../redux/slices/flowSlice";
import AppSettingsDropdown from "../AppSettingsDropdown/AppSettingsDropdown";
import { wordPressOptions } from "../../../../utils/vars/staticVariables";
import CustomSkeleton from "../../../CustomSkeleton/CustomSkeleton";
import { toast } from "react-hot-toast";
import { handleContinue } from "../../../../utils/helpers/scrollTop";
import MultiSelect, { selectStyles } from "../../../MultiSelect/MultiSelect";
import BaseTextInput from "../../../BaseTextInput/BaseTextInput";
import RedButton from "../../../UiKit/Buttons/RedButton/RedButton";
import BlueButton from "../../../UiKit/Buttons/BlueButton/BlueButton";
import { isIPv6 } from "../../../UiKit/Select/IpCreatableSelect/IpCreatableSelect";
import CreatableSelect from "react-select/creatable";
import { globalDataSelector } from "../../../../redux/slices/globalDataSlice";
import { fetchWhitePageFilters } from "../../../../api/helpers/fetchFilters";
import { authSelector } from "../../../../redux/slices/authSlice";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { user } from "../../../Statistics/ClicksStats/ClicksStats";
import { errorToastMessage } from "../../../../utils/toastMessages";

const SettingsStep = ({ setStep }) => {
  // // const [ipValidation, setIpValidation] = useState(true);
  // const ipInputRef = useRef(null);
  // const [errorMessage, setErrorMessage] = useState("");
  // const [geoListOptions, setGeoListOptions] = useState([]);
  //
  // const {
  //   flowObj,
  //   dataLoading,
  //   offerLink,
  //   status,
  //   whitePageUrl,
  //   wordPressLabel,
  //   wordPressName,
  //   selectedGeos,
  //   geoSelectInput,
  //   cloacaType,
  //   fetchObjLoading,
  //   currentFlowObjId,
  //   allowed_ips,
  //   fb_type,
  //   wordPressOption,
  // } = useSelector((state) => state.flow);
  //
  // const { isPartner } = useSelector(authSelector);
  //
  // const dispatch = useDispatch();
  // const { id } = useParams();
  // const navigate = useNavigate();
  //
  // const idsFromSelectedGeos = geoList
  //   .filter((geo) => selectedGeos.includes(geo.code))
  //   .map((geo) => geo.id);
  //
  // useEffect(() => {
  //   if (!flowObj || Number(id) !== currentFlowObjId) {
  //     dispatch(getFlowById(id));
  //   }
  // }, [flowObj]);
  //
  // const onChangeAllowedIps = (newValue) => {
  //   dispatch(setAllowedIps(newValue));
  // };
  //
  // const onCreateAllowedIps = (inputValue) => {
  //   const newValue = { label: inputValue, value: inputValue.toLowerCase() };
  //   dispatch(addAllowedIp(newValue));
  // };
  //
  // useEffect(() => {
  //   fetchWhitePageFilters(dispatch);
  // }, [dispatch, id]);
  //
  // const settingsObj = {
  //   flow_id: id,
  //   url: offerLink.trim(),
  //   status: status,
  //   type_of_cloaca: cloacaType,
  //   url_for_whitepage_app:
  //     cloacaType === "gp" || cloacaType === "ap"
  //       ? whitePageUrl
  //       : wordPressOption?.value,
  //   geo: idsFromSelectedGeos,
  //   allowed_ips: allowed_ips?.map((item) => item.value),
  //   cloack: "fb_lite",
  // };
  //
  // function isStringStartsWithHttp(url) {
  //   return /^https?:\/\/[^ ]*$/.test(url);
  // }
  //
  // const containsClickId = (str) => {
  //   const pattern = /{tds_clickid}/;
  //   return pattern.test(str);
  // };
  //
  // const onUpdateSettings = () => {
  //   // if (!ipValidation) {
  //   //   ipInputRef.current.scrollIntoView({ behavior: "smooth" });
  //   //   return;
  //   // }
  //
  //   if (offerLink.trim().length < 1) {
  //     toast.error("Передайте ссылку в оффер");
  //     return;
  //   }
  //
  //   if (!isStringStartsWithHttp(offerLink.trim())) {
  //     toast.error("Некоректный формат ссылки в поле оффера", {
  //       style: {
  //         minWidth: "400px",
  //       },
  //     });
  //     return;
  //   }
  //
  //   if (!containsClickId(offerLink.trim())) {
  //     toast.error(
  //       <div>
  //         Обязательно передайте значение <strong>{"{tds_clickid}"}</strong> в
  //         одном из своих параметров
  //       </div>,
  //       {
  //         style: {
  //           minWidth: "650px",
  //         },
  //       },
  //     );
  //     return;
  //   }
  //
  //   if (idsFromSelectedGeos.length < 1) {
  //     toast.error("Заполните Гео");
  //     return;
  //   }
  //
  //   if (
  //     cloacaType === "gp" &&
  //     !whitePageUrl?.includes("https://play.google.com/")
  //   ) {
  //     toast.error("Некоректный URL на приложение из GooglePlay", {
  //       style: {
  //         minWidth: "400px",
  //       },
  //     });
  //     return;
  //   }
  //
  //   if (
  //     cloacaType === "ap" &&
  //     !whitePageUrl?.includes("https://apps.apple.com/")
  //   ) {
  //     toast.error("Некоректный URL на приложение из AppStore", {
  //       style: {
  //         minWidth: "400px",
  //       },
  //     });
  //     return;
  //   }
  //
  //   if (cloacaType === "wp" && !wordPressOption) {
  //     toast.error("Выберите параметр для WordPress", {
  //       style: {
  //         minWidth: "400px",
  //       },
  //     });
  //     return;
  //   }
  //
  //   const onSuccessAction = (obj) => {
  //     dispatch(setFlowObj(obj));
  //     setStep("prelanding");
  //   };
  //
  //   const onErrorAction = (text) => {
  //     // toast.error("Произошла ошибка");
  //     errorToastMessage(text);
  //   };
  //
  //   dispatch(
  //     updateFlowSettings({ data: settingsObj, onSuccessAction, onErrorAction }),
  //   );
  // };
  //
  // // let geoListOptions = geoList.map(({ code, name }) => ({
  // //   value: code,
  // //   label: name,
  // // }));
  //
  // // let geoListOptions = [{ value: "all_geos", label: "Все гео" }].concat(
  // //   geoList.map(({ code, name }) => ({
  // //     value: code,
  // //     label: name,
  // //   })),
  // // );
  //
  // useEffect(() => {
  //   let geoListArr = geoList.map(({ code, name }) => ({
  //     value: code,
  //     label: name,
  //   }));
  //
  //   if (idsFromSelectedGeos.length !== geoListArr.length) {
  //     // { value: "all_geos", label: "Все гео" }
  //     setGeoListOptions([
  //       { value: "all_geos", label: "Все гео" },
  //       ...geoListArr,
  //     ]);
  //   } else {
  //     setGeoListOptions(geoListArr);
  //   }
  //
  //   // setGeoListOptions(geoListArr);
  // }, [geoList, idsFromSelectedGeos]);
  //
  // // useEffect(() => {
  // //   geoListOptions.unshift({ value: "all_geos", label: "Все гео" });
  // // }, [geoList]);
  //
  // const tdsClickIdMessage = isPartner
  //   ? "Если вы заливаете напрямую на WWPartners, используя личный кабинет, а не свой трекер, обязательно передавайте в ссылке sub8={tds_clickid}"
  //   : `Обязательно передайте значение {tds_clickid} в одном из своих параметров`;

  return (
    <div>
      {/*<div*/}
      {/*  className={s.settingsStep}*/}
      {/*  style={fetchObjLoading ? { overflow: "hidden" } : null}*/}
      {/*>*/}
      {/*  {fetchObjLoading ? (*/}
      {/*    <CustomSkeleton styles={s.settingsStepSkeleton} />*/}
      {/*  ) : (*/}
      {/*    <div className={s.settingsStepContent}>*/}
      {/*      <h5 className={s.subtitleBorderBottom}>Оффер</h5>*/}
      {/*      <h5 className={s.subTitle}>Ссылка</h5>*/}

      {/*      <BaseTextInput*/}
      {/*        action={(e) => dispatch(setOfferLink(e.target.value))}*/}
      {/*        value={offerLink}*/}
      {/*        placeholder="https://offer"*/}
      {/*      />*/}

      {/*      <h5 className={s.inputTip}>{tdsClickIdMessage}</h5>*/}
      {/*      <h5 className={s.subtitleBorderBottom}>Клоака</h5>*/}
      {/*      <h5 className={s.subTitle}>Статус</h5>*/}
      {/*      <div className={s.checkBoxInputWrapper}>*/}
      {/*        <RadioGroup*/}
      {/*          row*/}
      {/*          aria-label="options"*/}
      {/*          name="options"*/}
      {/*          value={status}*/}
      {/*          onChange={(e) => dispatch(setStatus(e.target.value))}*/}
      {/*          className={s.radioGroupCustom}*/}
      {/*        >*/}
      {/*          <FormControlLabel*/}
      {/*            sx={{*/}
      {/*              color: "#fff",*/}
      {/*            }}*/}
      {/*            value="Filter"*/}
      {/*            control={*/}
      {/*              <Radio*/}
      {/*                sx={{*/}
      {/*                  "& .MuiSvgIcon-root": { fontSize: 26 },*/}
      {/*                  color: "#fff",*/}
      {/*                }}*/}
      {/*              />*/}
      {/*            }*/}
      {/*            label="Включена"*/}
      {/*          />*/}
      {/*          <FormControlLabel*/}
      {/*            sx={{*/}
      {/*              color: "#fff",*/}
      {/*              // marginLeft: 1,*/}
      {/*              // marginRight: 4,*/}
      {/*            }}*/}
      {/*            value="Review"*/}
      {/*            control={*/}
      {/*              <Radio*/}
      {/*                sx={{*/}
      {/*                  "& .MuiSvgIcon-root": { fontSize: 26 },*/}
      {/*                  color: "#fff",*/}
      {/*                }}*/}
      {/*              />*/}
      {/*            }*/}
      {/*            label="Режим модерации"*/}
      {/*          />*/}

      {/*          <FormControlLabel*/}
      {/*            disabled*/}
      {/*            sx={{*/}
      {/*              "& .MuiFormControlLabel-label.Mui-disabled": {*/}
      {/*                color: "rgba(255, 255, 255, 0.5)",*/}
      {/*              },*/}
      {/*              "& .MuiRadio-root.Mui-disabled": {*/}
      {/*                color: "rgba(255, 255, 255, 0.5)",*/}
      {/*              },*/}
      {/*            }}*/}
      {/*            value="Money"*/}
      {/*            control={*/}
      {/*              <Radio*/}
      {/*                sx={{*/}
      {/*                  "& .MuiSvgIcon-root": { fontSize: 26 },*/}
      {/*                  color: "rgba(255, 255, 255, 0.5)",*/}
      {/*                  cursor: "not-allowed",*/}
      {/*                }}*/}
      {/*              />*/}
      {/*            }*/}
      {/*            label="Тестирование"*/}
      {/*          />*/}
      {/*        </RadioGroup>*/}
      {/*      </div>*/}
      {/*      <h5 className={s.subTitle}>Тип клоаки</h5>*/}

      {/*      <div className={s.checkBoxInputWrapper}>*/}
      {/*        <RadioGroup*/}
      {/*          row*/}
      {/*          aria-label="options"*/}
      {/*          name="options"*/}
      {/*          value={fb_type}*/}
      {/*          onChange={(e) => dispatch(setFbType(e.target.value))}*/}
      {/*          className={s.radioGroupCustom}*/}
      {/*        >*/}
      {/*          /!*<FormControlLabel*!/*/}
      {/*          /!*  sx={{*!/*/}
      {/*          /!*    marginRight: 3,*!/*/}
      {/*          /!*    color: "#fff",*!/*/}
      {/*          /!*  }}*!/*/}
      {/*          /!*  value="fb"*!/*/}
      {/*          /!*  control={*!/*/}
      {/*          /!*    <Radio*!/*/}
      {/*          /!*      sx={{*!/*/}
      {/*          /!*        "& .MuiSvgIcon-root": { fontSize: 26 },*!/*/}
      {/*          /!*        color: "#fff",*!/*/}
      {/*          /!*      }}*!/*/}
      {/*          /!*    />*!/*/}
      {/*          /!*  }*!/*/}
      {/*          /!*  label="Facebook"*!/*/}
      {/*          /!*/
      /*/}
      {/*          <FormControlLabel*/}
      {/*            sx={{*/}
      {/*              color: "#fff",*/}
      {/*              // marginLeft: 1,*/}
      {/*            }}*/}
      {/*            value="fb_lite"*/}
      {/*            control={*/}
      {/*              <Radio*/}
      {/*                sx={{*/}
      {/*                  "& .MuiSvgIcon-root": { fontSize: 26 },*/}
      {/*                  color: "#fff",*/}
      {/*                }}*/}
      {/*              />*/}
      {/*            }*/}
      {/*            label="Facebook"*/}
      {/*          />*/}
      {/*        </RadioGroup>*/}
      {/*      </div>*/}
      {/*      <h5 className={s.subtitleBorderBottom}>ГЕО</h5>*/}

      {/*      <div className={s.alertMessage}>*/}
      {/*        <div className={s.iconWrapper}>*/}
      {/*          <GlobalSvgSelector id="invalid-icon" />*/}
      {/*        </div>*/}
      {/*        <div className={s.messageContent}>*/}
      {/*          <h6>Запуск на гео US и AE недоступен</h6>*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*      <div className={s.geoInputWrapper}>*/}
      {/*        <h5 className={s.subTitle}>Выберите нужные вам ГЕО</h5>*/}
      {/*        <MultiSelect*/}
      {/*          isClearable={true}*/}
      {/*          value={geoSelectInput}*/}
      {/*          options={geoListOptions}*/}
      {/*          setSelectedOptions={(o) => {*/}
      {/*            if (o.some((obj) => obj.value === "all_geos")) {*/}
      {/*              const allOptions = geoListOptions.slice(1);*/}
      {/*              dispatch(setSelectedGeos(allOptions.map((i) => i.value)));*/}
      {/*              dispatch(setGeoSelectInput(allOptions));*/}
      {/*              setGeoListOptions(allOptions);*/}
      {/*            } else {*/}
      {/*              if (*/}
      {/*                !geoListOptions.some((item) => item.value === "all_geos")*/}
      {/*              ) {*/}
      {/*                setGeoListOptions((prev) => [*/}
      {/*                  { value: "all_geos", label: "Все гео" },*/}
      {/*                  ...prev,*/}
      {/*                ]);*/}
      {/*              }*/}
      {/*              dispatch(setSelectedGeos(o.map((i) => i.value)));*/}
      {/*              dispatch(setGeoSelectInput(o));*/}
      {/*            }*/}
      {/*          }}*/}
      {/*          placeholder="Гео"*/}
      {/*          isMulti={true}*/}
      {/*        />*/}
      {/*      </div>*/}
      {/*      <h5 className={s.subtitleBorderBottom} ref={ipInputRef}>*/}
      {/*        IP*/}
      {/*      </h5>*/}

      {/*      <div className={s.allowedIpsWrapper}>*/}
      {/*        <CreatableSelect*/}
      {/*          formatCreateLabel={(inputValue) => `Создать "${inputValue}"`}*/}
      {/*          noOptionsMessage={() => "Добавьте IP"}*/}
      {/*          styles={selectStyles}*/}
      {/*          value={allowed_ips}*/}
      {/*          onCreateOption={(ip) => {*/}
      {/*            if (*/}
      {/*              /^((25[0-5]|(2[0-4]|1\d|[1-9]|)\d)\.?\b){4}$/.test(ip) ||*/}
      {/*              isIPv6(ip)*/}
      {/*            ) {*/}
      {/*              onCreateAllowedIps(ip);*/}
      {/*              setErrorMessage("");*/}
      {/*            } else {*/}
      {/*              setErrorMessage(*/}
      {/*                "IP должен быть правильного формата: 0.0.0.0 (IPv4) или 0:0:0:0:0:0:0:0 (IPv6)",*/}
      {/*              );*/}
      {/*            }*/}
      {/*          }}*/}
      {/*          onChange={onChangeAllowedIps}*/}
      {/*          isClearable={true}*/}
      {/*          isMulti*/}
      {/*          placeholder="IP адрес"*/}
      {/*        />*/}
      {/*        {errorMessage && (*/}
      {/*          <div className={s.errorMessage}>{errorMessage}</div>*/}
      {/*        )}*/}
      {/*      </div>*/}

      {/*      <h5 className={s.subtitleBorderBottom}>Whitepage</h5>*/}

      {/*      <h5 className={s.subTitle}>Тип приложения</h5>*/}

      {/*      <div className={s.appTypeInputWrapper}>*/}
      {/*        <RadioGroup*/}
      {/*          row*/}
      {/*          aria-label="options"*/}
      {/*          name="options"*/}
      {/*          value={cloacaType}*/}
      {/*          onChange={(e) => dispatch(setCloacaType(e.target.value))}*/}
      {/*          className={s.radioGroupCustom}*/}
      {/*        >*/}
      {/*          <FormControlLabel*/}
      {/*            sx={{*/}
      {/*              color: "#fff",*/}
      {/*            }}*/}
      {/*            value="gp"*/}
      {/*            control={*/}
      {/*              <Radio*/}
      {/*                sx={{*/}
      {/*                  "& .MuiSvgIcon-root": { fontSize: 26 },*/}
      {/*                  color: "#fff",*/}
      {/*                }}*/}
      {/*              />*/}
      {/*            }*/}
      {/*            label="Приложение GooglePlay"*/}
      {/*          />*/}
      {/*          <FormControlLabel*/}
      {/*            sx={{*/}
      {/*              // marginLeft: 1,*/}
      {/*              color: "#fff",*/}
      {/*            }}*/}
      {/*            value="ap"*/}
      {/*            control={*/}
      {/*              <Radio*/}
      {/*                sx={{*/}
      {/*                  "& .MuiSvgIcon-root": { fontSize: 26 },*/}
      {/*                  color: "#fff",*/}
      {/*                }}*/}
      {/*              />*/}
      {/*            }*/}
      {/*            label="Приложение AppStore"*/}
      {/*          />*/}
      {/*          <FormControlLabel*/}
      {/*            sx={{*/}
      {/*              // marginLeft: 1,*/}
      {/*              color: "#fff",*/}
      {/*            }}*/}
      {/*            value="wp"*/}
      {/*            control={*/}
      {/*              <Radio*/}
      {/*                sx={{*/}
      {/*                  "& .MuiSvgIcon-root": { fontSize: 26 },*/}
      {/*                  color: "#fff",*/}
      {/*                }}*/}
      {/*              />*/}
      {/*            }*/}
      {/*            label="WordPress"*/}
      {/*          />*/}
      {/*        </RadioGroup>*/}
      {/*      </div>*/}
      {/*      <h5 className={s.subTitle}>*/}
      {/*        URL на приложение из*/}
      {/*        {cloacaType === "gp" && " GooglePlay "}*/}
      {/*        {cloacaType === "ap" && " AppStore "}*/}
      {/*        {cloacaType === "wp" && " Wordpress "}*/}
      {/*        для Whitepage*/}
      {/*      </h5>*/}

      {/*      <div className={s.whitePageWrapper}>*/}
      {/*        {cloacaType === "gp" || cloacaType === "ap" ? (*/}
      {/*          <BaseTextInput*/}
      {/*            action={(e) => dispatch(setWhitePageUrl(e.target.value))}*/}
      {/*            value={whitePageUrl}*/}
      {/*            placeholder="Введите текст"*/}
      {/*          />*/}
      {/*        ) : null}*/}

      {/*        {cloacaType === "wp" && (*/}
      {/*          <MultiSelect*/}
      {/*            isClearable={true}*/}
      {/*            options={wordPressOptions}*/}
      {/*            value={wordPressOption}*/}
      {/*            setSelectedOptions={(option) =>*/}
      {/*              dispatch(setWordPressOption(option))*/}
      {/*            }*/}
      {/*            placeholder="Выберите параметр"*/}
      {/*            isMulti={false}*/}
      {/*          />*/}

      {/*          // <AppSettingsDropdown*/}
      {/*          //   desc="Выберите параметр"*/}
      {/*          //   selected={wordPressName}*/}
      {/*          //   setLabel={(item) => dispatch(setWordPressLabel(item))}*/}
      {/*          //   setName={(item) => dispatch(setWordPressName(item))}*/}
      {/*          //   options={wordPressData}*/}
      {/*          // />*/}
      {/*        )}*/}
      {/*      </div>*/}

      {/*      <h5 className={s.subtitleBorderBottom}>Postback urls</h5>*/}

      {/*      <div className={s.postbackInputsWrapper}>*/}
      {/*        <div>*/}
      {/*          <h5 className={s.subTitle}>Lead</h5>*/}

      {/*          <BaseTextInput*/}
      {/*            value="https://servapi.wwa-ios.com/event/?event=lead&payout={revenue}&tds_clickid={tds_clickid}"*/}
      {/*            placeholder="Ссылка"*/}
      {/*            copy={true}*/}
      {/*          />*/}
      {/*        </div>*/}

      {/*        <div>*/}
      {/*          <h5 className={s.subTitle}>Sale</h5>*/}

      {/*          <BaseTextInput*/}
      {/*            value="https://servapi.wwa-ios.com/event/?event=sale&payout={revenue}&tds_clickid={tds_clickid}"*/}
      {/*            placeholder="Ссылка"*/}
      {/*            copy={true}*/}
      {/*          />*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  )}*/}
      {/*</div>*/}
      {/*<div className={s.buttonsWrapper} style={{ marginBottom: 100 }}>*/}
      {/*  <RedButton*/}
      {/*    width={120}*/}
      {/*    text="Отменить"*/}
      {/*    action={() => {*/}
      {/*      navigate("/my-flows");*/}
      {/*      handleContinue();*/}
      {/*    }}*/}
      {/*  />*/}
      {/*  <BlueButton*/}
      {/*    width={120}*/}
      {/*    text="Далее"*/}
      {/*    pending={dataLoading}*/}
      {/*    action={onUpdateSettings}*/}
      {/*  />*/}
      {/*</div>*/}
    </div>
  );
};

export default SettingsStep;
