import { useDispatch, useSelector } from "react-redux";
import { financeSelector } from "../../../../redux/slices/financeSlice";
import { globalDataSelector } from "../../../../redux/slices/globalDataSlice";
import useCustomSnackbar from "../../../../hooks/useCustomSnackbar";
import { getUser } from "../../../../storage/storage";
import React, { useEffect, useMemo, useRef, useState } from "react";
import s from "./createRedirectorPopup.module.scss";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  snackbar_error,
  snackbar_success,
} from "../../../../utils/vars/staticVariables";
import { sub7Exists } from "../../../../utils/vars/errorMessages";
import GlobalSvgSelector from "../../../../components/GlobalSvgSelector/GlobalSvgSelector";
import CancelButton from "../../../../components/UiKit/Buttons/CancelBtn/CancelBtn";
import PrimaryBtn from "../../../../components/UiKit/Buttons/PrimaryBorderBtn/PrimaryBorderBtn";
import {
  createRedirector,
  redirectorSelector,
} from "../../../../redux/slices/redirectorSlice";
import Select from "react-select";
import {
  dropdownStyles,
  redirectDropdownStyles,
} from "../../../../components/UiKit/Popups/GetLinksPopup/GetLinksPopup";
import PercentageBtn from "../PercentageBtn/PercentageBtn";
import MuiTooltip from "../../../../components/UiKit/MuiTooltip/MuiTooltip";
import GradientBorder from "../../../../components/Blocks/GradientBorder/GradientBorder";
import { distributePercentages } from "../../../../utils/helpers/distributePercentages";
import ApplicationSelect from "../../../../components/Blocks/Selects/ApplicationSelect";
import CreateRedirectorPopupSelect from "../../../../components/Blocks/Selects/CreateRedirectorPopupSelect";

const CreateRedirectorPopup = ({
  active,
  setActive,
  fetchRedirectors,
  appList,
}) => {
  const [newFieldTouched, setNewFieldTouched] = useState(false);
  const [selectTouched, setSelectTouched] = useState([]);

  const bottomRef = useRef(null);

  const applicationRefs = useRef([]);

  const { topUpBalanceIsActive } = useSelector(financeSelector);
  const { exitPopup, isCollapsedSidebar } = useSelector(globalDataSelector);
  const { createRedirectorPending } = useSelector(redirectorSelector);

  const dispatch = useDispatch();

  const showSnackbar = useCustomSnackbar();

  const { user_id } = getUser();

  useEffect(() => {
    if (topUpBalanceIsActive || exitPopup) {
      setActive(false);
    }
  }, [topUpBalanceIsActive, exitPopup]);

  useEffect(() => {
    const handleEscClose = (e) => {
      if (e.key === "Escape") {
        setActive(false);
        if (document.activeElement) {
          document.activeElement.blur();
        }
      }
    };

    document.addEventListener("keydown", handleEscClose);

    return () => {
      document.removeEventListener("keydown", handleEscClose);
    };
  }, [setActive]);

  const handleClosePopup = (event) => {
    if (event.target.classList.contains(s.popupWrapper)) {
      setActive(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      user_id,
      application_percentages: [
        {
          percentage: null,
          application: null,
          name: null,
          is_alive: null,
          isDisabled: null,
        },
      ],
      sub7: "",
    },
    // validateOnChange: false, // Не викликаємо валідацію при зміні
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      sub7: Yup.string()
        .trim() // Видаляємо пробіли на початку та в кінці
        .test(
          "is-single-word",
          "Слово не должно содержать пробелов",
          (value) => !/\s/.test(value), // Перевірка на наявність пробілів всередині
        )
        .matches(/^[a-zA-Z0-9]+$/, "Введите латинские буквы и цифры") // Перевірка на альфанумерик
        .required("Обязательное поле"),
      application_percentages: Yup.array()
        .of(
          Yup.object().shape({
            percentage: Yup.number()
              .min(0, "Процент не может быть меньше 0") // Дозволяємо 0
              .nullable()
              .required("Установите процент"), // Вимагаємо значення для відсотків
            application: Yup.string()
              .required("Выберите приложение")
              .test(
                "isAliveCheck",
                "Неактивное приложение не может иметь проценты",
                function (value) {
                  const { percentage } = this.parent; // Отримуємо значення поля `percentage` з поточного об'єкта
                  const is_alive = this.parent.is_alive; // Отримуємо значення поля `is_alive`
                  // Перевірка: якщо додаток неактивний і процент більше 0
                  if (!is_alive && percentage > 0) {
                    return false;
                  }
                  return true;
                },
              ),
          }),
        )
        .test("totalPercentage", function (application_percentages) {
          const totalPercentage = application_percentages.reduce(
            (sum, current) => sum + (current.percentage || 0),
            0,
          );

          if (totalPercentage > 100) {
            return this.createError({
              message: "Количество процентов больше 100",
            });
          } else if (totalPercentage < 100) {
            return this.createError({
              message: "Количество процентов меньше 100",
            });
          }

          return true;
        }),
    }),
    onSubmit: async (data, { setTouched, setFieldError }) => {
      const touchedFields = {
        application_percentages: formik.values.application_percentages.map(
          () => ({
            percentage: true,
            application: true,
          }),
        ),
      };

      // setTouched(touchedFields, true);

      const filteredData = {
        ...data,
        application_percentages: data.application_percentages.map(
          ({ percentage, application }) => ({
            percentage,
            application,
          }),
        ),
      };

      const createRedirectorResult = await dispatch(
        createRedirector(filteredData),
      );

      if (createRedirectorResult?.payload?.status === 201) {
        fetchRedirectors();
        showSnackbar("Редирект был создан", snackbar_success);
        setActive(false);
        formik.resetForm();
        setNewFieldTouched(false);
        setSelectTouched([]);
      } else {
        if (createRedirectorResult?.payload?.data?.details === sub7Exists) {
          return setFieldError("sub7", "Редирект с таким sub7 уже существует");
        } else {
          showSnackbar(
            `${createRedirectorResult?.payload?.data?.details}`,
            snackbar_error,
          );
        }

        // showSnackbar("Не удалось создать редирект", snackbar_error);
        // setActive(false);
      }
    },
  });

  useEffect(() => {
    if (bottomRef.current) {
      bottomRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [formik.values.application_percentages.length]);

  const cutAppListOptions = useMemo(() => {
    return appList
      .filter(
        (item) =>
          !formik.values.application_percentages.some(
            ({ application }) => application === item.id,
          ),
      )
      .map((item) => ({
        label: item.name,
        value: item.id,
        is_alive: item.is_alive,
        name: "app_list",
        isDisabled: !item.is_alive,
        icon: item.icon,
      }));
  }, [appList, formik.values.application_percentages]);

  const addNewApplicationPercentage = () => {
    const newApplication = {
      percentage: null,
      application: null,
      name: null,
    };

    formik.setFieldValue("application_percentages", [
      ...formik.values.application_percentages,
      newApplication,
    ]);

    setSelectTouched([...selectTouched, false]); // Додаємо новий запис у selectTouched
    setNewFieldTouched(false); // Скидаємо стан торкання для нового поля

    // Скидаємо touched для нового поля percentage, щоб валідація не спрацьовувала одразу
    formik.setTouched({
      ...formik.touched,
      application_percentages: [
        ...(formik.touched.application_percentages || []),
        { percentage: false, application: false },
      ],
    });
  };

  const onChangeApplication = (index, selectedOption) => {
    const copiedApplicationPercentages = [
      ...formik.values.application_percentages,
    ];

    copiedApplicationPercentages[index] = {
      ...copiedApplicationPercentages[index],
      application: selectedOption?.value || null,
      name: selectedOption?.label || null,
      is_alive: selectedOption?.is_alive || null,
    };

    formik.setFieldValue(
      "application_percentages",
      copiedApplicationPercentages,
    );

    const updatedTouched = [...selectTouched];
    updatedTouched[index] = true; // Встановлюємо, що цей Select було торкнуто
    setSelectTouched(updatedTouched);
  };

  const onChangePercentage = (index, value) => {
    const copiedApplicationPercentages = [
      ...formik.values.application_percentages,
    ];

    copiedApplicationPercentages[index] = {
      ...copiedApplicationPercentages[index],
      percentage: value,
    };

    formik.setFieldValue(
      "application_percentages",
      copiedApplicationPercentages,
    );

    const updatedTouched = [...selectTouched];
    updatedTouched[index] = true; // Встановлюємо, що поле було торкнуто
    setSelectTouched(updatedTouched);
  };

  const removeApplication = (indexToRemove) => {
    const updatedApplicationPercentages =
      formik.values.application_percentages.filter(
        (_, index) => index !== indexToRemove,
      );

    formik.setFieldValue(
      "application_percentages",
      updatedApplicationPercentages,
    );
  };

  const scrollToElement = (index) => {
    if (applicationRefs.current[index]) {
      applicationRefs.current[index].scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  const redistributePercentages = () => {
    // Обчислюємо загальний відсоток
    const totalPercentage = formik.values.application_percentages.reduce(
      (sum, current) => sum + (current.percentage || 0),
      0,
    );

    // Перевіряємо, чи є вже розподілені всі 100%
    if (totalPercentage >= 100) {
      return;
    }

    // Обчислюємо залишковий відсоток
    let remainingPercentage = 100 - totalPercentage;

    // Фільтруємо додатки, де процент не заданий або дорівнює 0
    const appsToDistribute = formik.values.application_percentages.filter(
      (item) => !item.percentage || item.percentage === 0,
    );

    if (appsToDistribute.length > 0) {
      // Розподіляємо залишковий відсоток між додатками
      const newPercentage = Math.floor(
        remainingPercentage / appsToDistribute.length,
      );

      // Перевірка, чи залишкова частина правильно розподілена
      let totalDistributed = newPercentage * appsToDistribute.length;
      let remainder = remainingPercentage - totalDistributed;

      const updatedApplicationPercentages =
        formik.values.application_percentages.map((item) => {
          if (!item.percentage || item.percentage === 0) {
            const addExtra = remainder > 0 ? 1 : 0; // Додаємо 1 якщо залишок більше 0
            remainder -= addExtra; // Зменшуємо залишок
            return {
              ...item,
              percentage: newPercentage + addExtra,
            };
          }
          return item;
        });

      // Оновлюємо значення у Formik
      formik.setFieldValue(
        "application_percentages",
        updatedApplicationPercentages,
      );
    }
  };

  // Перевірка, чи кнопка повинна бути активна
  const totalPercentage = formik.values.application_percentages.reduce(
    (sum, current) => sum + (current.percentage || 0),
    0,
  );

  const canRedistribute =
    formik.values.application_percentages.some(
      (item) => !item.percentage || item.percentage === 0,
    ) && totalPercentage < 100; // Дизейблимо, якщо сума відсотків вже 100

  return (
    <div
      className={`${s.popupWrapper} ${active ? s.active : ""} ${isCollapsedSidebar === "true" ? s.collapsed : ""}`}
      onClick={handleClosePopup}
    >
      <form
        className={active ? `${s.popup} ${s.active}` : s.popup}
        onSubmit={formik.handleSubmit}
      >
        <div className={s.header}>
          <button
            onClick={() => setActive(false)}
            className={s.closePopup}
            type="button"
          >
            <GlobalSvgSelector id="close-popup" />
          </button>
          <div className={s.icon}>
            <GlobalSvgSelector id="redirector-popup-icon" />
          </div>
          <div className={s.popupInfo}>
            <p className={s.title}>Создание редиректа</p>
            <p className={s.description}>
              Обратите внимание, что общая сумма процентов должна быть 100%.
            </p>
          </div>
        </div>
        <div className={s.body}>
          <div>
            <p className={s.inputName}>Sub7</p>
            <input
              type="text"
              name="sub7"
              className={`${s.textInput} ${
                formik.touched.sub7 && formik.errors.sub7 ? s.error : ""
              }`}
              placeholder="Введите Sub7"
              value={formik.values.sub7}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.sub7 && formik.errors.sub7 ? (
              <div className={s.error}>{formik.errors.sub7}</div>
            ) : null}
          </div>
          <div>
            <div className={s.applicationsTitleContainer}>
              <GradientBorder className={s.gradientBorderCustom} />
              <div className={s.applicationsTitle}>Приложения</div>
            </div>
            <div className={s.distributePercentageContainer}>
              <button
                className={s.primaryBtn}
                onClick={() => {
                  const distributedArr = distributePercentages(
                    formik.values.application_percentages,
                  );

                  formik.setFieldValue(
                    "application_percentages",
                    distributedArr,
                  );
                }}
                type="button"
              >
                Распределить %
              </button>
              <button
                className={s.primaryBtn}
                disabled={!canRedistribute}
                onClick={redistributePercentages}
                type="button"
              >
                Автодополнение %
              </button>
            </div>
            <div className={s.applicationsContainer}>
              <div className={s.applicationsWrapper}>
                {formik.values.application_percentages?.map(
                  ({ percentage, application, name, is_alive }, index) => {
                    const selectValue =
                      application && name
                        ? {
                            value: application,
                            label: name,
                            is_alive: is_alive,
                          }
                        : null;

                    const isInvalidSelect = Boolean(
                      formik.errors.application_percentages?.[index]
                        ?.application &&
                        (formik.touched.application_percentages?.[index]
                          ?.application ||
                          selectTouched[index]),
                    );

                    const isInvalidPercentage = Boolean(
                      formik.errors.application_percentages?.[index]
                        ?.percentage &&
                        (formik.touched.application_percentages?.[index]
                          ?.percentage ||
                          newFieldTouched),
                    );

                    return (
                      <div
                        key={index}
                        className={s.applicationOptionWrapper}
                        ref={(el) => (applicationRefs.current[index] = el)}
                      >
                        <div className={s.selectWrapper}>
                          <CreateRedirectorPopupSelect
                            value={selectValue}
                            isClearable={false}
                            options={cutAppListOptions}
                            onChange={(selectedOption) =>
                              onChangeApplication(index, selectedOption)
                            }
                            isInvalid={isInvalidSelect}
                            isMulti={false}
                            placeholder="Выберите приложение"
                            styles={redirectDropdownStyles}
                            onMenuOpen={() => scrollToElement(index)}
                            noOptionsMessage={() => "Нет опций"}
                            scrollToElement={scrollToElement}
                            index={index}
                          />
                          {isInvalidSelect && (
                            <div className={s.errorMessage}>
                              {
                                formik.errors.application_percentages[index]
                                  .application
                              }
                            </div>
                          )}
                        </div>
                        <div className={s.wrapErrorContainer}>
                          <div className={s.wrapContainer}>
                            <div className={s.percentageWrapper}>
                              <PercentageBtn
                                percentageValue={percentage}
                                onChange={(value) =>
                                  onChangePercentage(index, value)
                                }
                                hasError={isInvalidPercentage}
                                isAlive={selectValue?.is_alive}
                              />
                            </div>

                            {index > 0 ? (
                              <div className={s.actionsWrapper}>
                                <div className={s.actionsContainer}>
                                  <div className={s.actions}>
                                    <button
                                      type="button"
                                      className={s.removeBtn}
                                      onClick={() => removeApplication(index)}
                                    >
                                      <MuiTooltip title="Убрать приложение">
                                        <GlobalSvgSelector id="new-remove-icon" />
                                      </MuiTooltip>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div className={s.mockContainer}></div>
                            )}
                          </div>
                          {isInvalidPercentage && (
                            <div className={s.errorMessage}>
                              {
                                formik.errors.application_percentages[index]
                                  .percentage
                              }
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  },
                )}
              </div>
              {typeof formik.errors.application_percentages === "string" && (
                <div className={s.errorMessageWrapper}>
                  <div className={s.errorMessage}>
                    {formik.errors.application_percentages}
                  </div>
                </div>
              )}
            </div>
            <div className={s.addApplicationContainer}>
              <button
                className={s.primaryBtn}
                onClick={addNewApplicationPercentage}
                type="button"
              >
                <GlobalSvgSelector id="plus-button" />
                Добавить
              </button>
            </div>
            <div ref={bottomRef} />
          </div>
        </div>
        <div className={s.footerContainer}>
          <CancelButton
            text="Отменить"
            action={() => {
              formik.resetForm();
              setNewFieldTouched(false);
              setSelectTouched([]);
              setActive(false);
            }}
          />
          <PrimaryBtn
            text="Создать"
            submit={true}
            pending={createRedirectorPending}
          />
        </div>
      </form>
    </div>
  );
};

export default CreateRedirectorPopup;
