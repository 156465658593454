import React from "react";
import { LocalizationProvider, DesktopDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";

const datePickerStyles = {
  width: "100%",

  "& .MuiOutlinedInput-root": {
    borderRadius: "40px",
    background: "#171A21",
    // paddingLeft: "34px", // Збільшуємо відступ ліворуч для іконки
    display: "flex",
    alignItems: "center",
    "& fieldset": {
      borderColor: "#171A21",
    },
    "&:hover fieldset": {
      borderColor: "rgba(208, 211, 217, .75)",
    },
    "&.Mui-focused fieldset": {
      borderColor: "rgba(93, 135, 255, .7)",
    },
    "&.Mui-focused:hover fieldset": {
      borderColor: "rgba(93, 135, 255, 1)",
    },
    "& .MuiInputBase-input": {
      color: "#F2F5FA",
      height: "40px",
      padding: "0 0 0 10px",
      boxSizing: "border-box",
      display: "flex",
      alignItems: "center",
      lineHeight: "normal",
    },
    "& .MuiInputBase-input::placeholder": {
      color: "#99A0AE",
      opacity: 1,
    },
  },
  "& .MuiInputLabel-root": {
    color: "#99A0AE",
    top: "-4px",
    // paddingBottom: "10px",
    transform: "translate(14px, 12px) scale(1)",
    "&.MuiInputLabel-shrink": {
      transform: "translate(14px, -6px) scale(0.75)",
    },
  },

  "& .MuiSvgIcon-root": {
    color: "#fff",
  },

  "& .Mui-focused .MuiInputLabel-root": {
    color: "#476CFF",
  },
};

const darkTheme = createTheme({
  palette: {
    mode: "dark",
    background: {
      default: "#222632",
      paper: "#222632", // Ensure the background color to match the second image
    },
    primary: {
      main: "#476CFF",
    },
    text: {
      primary: "#F2F5FA",
      secondary: "#d0d3d9",
    },
  },
  components: {
    // MuiPickersDay: {
    //   styleOverrides: {
    //     root: {
    //       color: "#F2F5FA",
    //       "&.Mui-selected": {
    //         backgroundColor: "#5d87ff",
    //       },
    //       "&.Mui-selected:hover": {
    //         backgroundColor: "#5d87ff",
    //       },
    //     },
    //   },
    // },
    // MuiOutlinedInput: {
    //   styleOverrides: {
    //     root: datePickerStyles["& .MuiOutlinedInput-root"],
    //   },
    // },
    // MuiInputLabel: {
    //   styleOverrides: {
    //     root: datePickerStyles["& .MuiInputLabel-root"],
    //   },
    // },
    // MuiSvgIcon: {
    //   styleOverrides: {
    //     root: datePickerStyles["& .MuiSvgIcon-root"],
    //   },
    // },
    MuiPaper: {
      styleOverrides: {
        root: {
          // backgroundColor: "#1b2633", // Ensure the background color of the picker matches the second image
          borderRadius: "18px", // Apply border radius
        },
      },
    },
  },
});

const StyledDatePicker = styled(DesktopDatePicker)({
  ...datePickerStyles,
});

export const MuiDatePicker = ({
  defaultValue,
  onChange,
  value,
  label,
  className,
  name,
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ThemeProvider theme={darkTheme}>
        <StyledDatePicker
          name={name}
          className={className || ""}
          label={label}
          value={value || null}
          onChange={(e) => onChange(e)}
          defaultValue={defaultValue}
          format="DD-MM-YYYY"
          sx={datePickerStyles}
        />
      </ThemeProvider>
    </LocalizationProvider>
  );
};
