import moment from "moment-timezone";
import dayjs from "dayjs";

export const formatDateTime = (inputDateTime) => {
  const date = moment(inputDateTime).format("MMM. DD. YYYY");
  const time = moment(inputDateTime).format("hh:mm a");
  return { date, time };
};

export function stringToDayJs(date) {
  return date ? dayjs(date) : null;
}

export function formatTimestampToServerDate(timestamp) {
  const date = new Date(timestamp);
  if (date.toString() === "Invalid Date") return "";

  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();

  return `${year}-${month}-${day}`;
}

export const convertDate = (inputDate, dateType) => {
  const date = new Date(inputDate);
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();

  if (dateType === "DMY") {
    return `${day}.${month}.${year}`;
  }

  if (dateType === "YMD") {
    return `${year}-${month}-${day}`;
  }

  if (dateType === "HM") {
    return `${hours}:${minutes}`;
  }
};
