import React, { useEffect, useState } from "react";
import BaseTextInput from "../../../BaseTextInput/BaseTextInput";
import GlobalSvgSelector from "../../../GlobalSvgSelector/GlobalSvgSelector";
import s from "./getLinksPopup.module.scss";
import { useSelector } from "react-redux";
import { financeSelector } from "../../../../redux/slices/financeSlice";
import { globalDataSelector } from "../../../../redux/slices/globalDataSlice";
import Select from "react-select";
import { snackbar_info } from "../../../../utils/vars/staticVariables";
import AdaptiveMuiTooltip from "../../MuiTooltip/AdaptiveMuiTooltip";

export const dropdownStyles = {
  control: (baseStyles, { isSelected, selectProps }) => ({
    ...baseStyles,
    background: "rgba(255, 255, 255, 0.1)",
    minHeight: 48,
    borderRadius: 40,
    paddingLeft: 17,
    overflow: "hidden",
    cursor: "pointer",
    color: "#F2F5FA",
    "&:hover": {
      borderColor: selectProps.isInvalid ? "#D02533" : baseStyles.borderColor,
    },
    boxShadow: selectProps.isInvalid ? "none" : baseStyles.boxShadow,
    borderColor: selectProps.isInvalid
      ? "#D02533"
      : isSelected
        ? "#F2F5FA"
        : "#171a21",
  }),

  multiValueLabel: (provided, { data }) => ({
    ...provided,
    color: "#F2F5FA",
    // color: data.is_alive ? "#F2F5FA" : "#FF4136",
    // color: "#F2F5FA",
  }),

  multiValueRemove: (provided, state) => ({
    ...provided,
    color: "#476CFF",
    background: "none",
    // height: "40px",
    "&:hover": {
      background: "none",
      color: "#476CFF",
    },
    "& svg": {
      height: "20px", // Встановлення висоти SVG до 30 пікселів
      width: "20px", // За потреби, можна також встановити ширину
    },
  }),

  dropdownIndicator: (baseStyles, state) => ({
    ...baseStyles,
    background:
      state.isFocused || state.isHovered
        ? "rgba(255, 255, 255, 0.16)"
        : "rgba(255, 255, 255, 0.16)",
    padding: 4,
    marginRight: 16,
    borderRadius: "50%",
    svg: {
      fill: "#fff",
    },
  }),

  indicatorSeparator: () => ({
    display: "none", // Приховуємо палочку
  }),

  clearIndicator: (baseStyles) => ({
    ...baseStyles,
    color: "#476CFF", // Колір хрестика
    cursor: "pointer",
    padding: 4,
    marginRight: 16,

    borderRadius: "50%",
    "&:hover": {
      color: "#476CFF", // Колір хрестика при наведенні
    },
    // width: 24,
    // height: 25,
    background: "#FFFFFF29",

    "& svg": {
      // height: "25px", // Висота SVG-іконки
      // width: "25px", // Ширина SVG-іконки
      fill: "#fff", // Колір заливки SVG (впливає на колір хрестика)
      stroke: "#fff", // Колір обведення (якщо є)
    },
  }),

  multiValue: (provided, state) => ({
    ...provided,
    backgroundColor: "rgba(225, 231, 255, 0.2)",
    padding: "4px 6px 4px 3px",
    fontSize: "17px",
    color: "#5D87FF",
    borderRadius: "5px",
  }),

  singleValue: (provided, state) => ({
    ...provided,
    // backgroundColor: '#2c3c5e',
    color: "#F2F5FA",
    textAlign: "left",
    // borderRadius: '5px',
  }),

  singleValueRemove: (provided, state) => ({
    ...provided,
    color: "red",
  }),

  menu: (baseStyles) => ({
    ...baseStyles,
    backgroundColor: "#171a21",
    borderRadius: 25,
    zIndex: 1000 /* Високий z-index, щоб він накривав іконки */,
    // padding: "0 8px",
    // maxHeight: '250px', // Змініть на бажану висоту
    // overflowY: 'auto',
  }),

  menuList: (baseStyles) => ({
    ...baseStyles,
    // height: 380,
    borderRadius: 25,
    overflowX: "hidden",
    padding: "0 8px",

    backgroundColor: "#171a21",

    "&::-webkit-scrollbar": {
      width: 6,
      background: "#FFFFFF1A",
    },
    "&::-webkit-scrollbar-track": {
      borderRadius: 10,
      marginTop: "5px", // Відступ зверху для треку скролбару
      marginBottom: "5px", // Відступ знизу для треку скролбару
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: 10,
      background: "#FFFFFF33",
    },
  }),

  placeholder: (styles) => ({
    ...styles,
    color: "#99A0AE",
    fontWeight: 400,
    fontSize: 15,
    position: "absolute",
  }),

  input: (styles) => ({
    ...styles,
    color: "white",
    "&::placeholder": {
      color: "green", // Колір placeholder для одиночного селекту
    },
  }),

  valueContainer: (provided, state) => ({
    ...provided,
    padding: "2px 0 2px 0",
  }),

  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    const customStyle = {
      ...styles,
      color: "#F2F5FA",
      borderRadius: 20,
      backgroundColor: isFocused ? "#1E2437" : "#171a21",
      marginTop: 7,
      padding: "10px 8px 10px 16px",
      // borderBottom: "1px solid #5A6A85",
      cursor: "pointer",
      textAlign: "left",
      "&:last-child": {
        marginBottom: 7,
        // borderBottom: "none",
      },
      "&:active": {
        backgroundColor: "#1E2437",
      },
      "&:hover": {
        backgroundColor: "#1E2437",
      },
    };

    if (data?.name === "app_list" && !data.is_alive) {
      customStyle.color = "#d32f2f";
    }

    return customStyle;
  },
};

const getLinksPopupStyles = {
  control: (baseStyles, { isSelected, selectProps }) => ({
    ...baseStyles,
    background: "rgba(255, 255, 255, 0.1)",
    minHeight: 48,
    borderRadius: "40px 0 0 40px",
    paddingLeft: 17,
    overflow: "hidden",
    cursor: "pointer",
    color: "#F2F5FA",
    "&:hover": {
      borderColor: selectProps.isInvalid ? "#D02533" : baseStyles.borderColor,
    },
    boxShadow: selectProps.isInvalid ? "none" : baseStyles.boxShadow,
    borderColor: selectProps.isInvalid
      ? "#D02533"
      : isSelected
        ? "#F2F5FA"
        : "transparent",
  }),

  multiValueLabel: (provided, { data }) => ({
    ...provided,
    color: "#F2F5FA",
    // color: data.is_alive ? "#F2F5FA" : "#FF4136",
    // color: "#F2F5FA",
  }),

  multiValueRemove: (provided, state) => ({
    ...provided,
    color: "#476CFF",
    background: "none",
    // height: "40px",
    "&:hover": {
      background: "none",
      color: "#476CFF",
    },
    "& svg": {
      height: "20px", // Встановлення висоти SVG до 30 пікселів
      width: "20px", // За потреби, можна також встановити ширину
    },
  }),

  dropdownIndicator: (baseStyles, state) => ({
    ...baseStyles,
    background:
      state.isFocused || state.isHovered
        ? "rgba(255, 255, 255, 0.16)"
        : "rgba(255, 255, 255, 0.16)",
    padding: 4,
    marginRight: 16,
    borderRadius: "50%",
    svg: {
      fill: "#fff",
    },
  }),

  indicatorSeparator: () => ({
    display: "none", // Приховуємо палочку
  }),

  clearIndicator: (baseStyles) => ({
    ...baseStyles,
    color: "#476CFF", // Колір хрестика
    cursor: "pointer",
    padding: 4,
    marginRight: 16,

    borderRadius: "50%",
    "&:hover": {
      color: "#476CFF", // Колір хрестика при наведенні
    },
    // width: 24,
    // height: 25,
    background: "#FFFFFF29",

    "& svg": {
      // height: "25px", // Висота SVG-іконки
      // width: "25px", // Ширина SVG-іконки
      fill: "#fff", // Колір заливки SVG (впливає на колір хрестика)
      stroke: "#fff", // Колір обведення (якщо є)
    },
  }),

  multiValue: (provided, state) => ({
    ...provided,
    backgroundColor: "rgba(225, 231, 255, 0.2)",
    padding: "4px 6px 4px 3px",
    fontSize: "17px",
    color: "#5D87FF",
    borderRadius: "5px",
  }),

  singleValue: (provided, state) => ({
    ...provided,
    // backgroundColor: '#2c3c5e',
    color: "#F2F5FA",
    textAlign: "left",
    // borderRadius: '5px',
  }),

  singleValueRemove: (provided, state) => ({
    ...provided,
    color: "red",
  }),

  menu: (baseStyles) => ({
    ...baseStyles,
    backgroundColor: "#171a21",
    borderRadius: 25,
    zIndex: 1000 /* Високий z-index, щоб він накривав іконки */,
    // padding: "0 8px",
    // maxHeight: '250px', // Змініть на бажану висоту
    // overflowY: 'auto',
  }),

  menuList: (baseStyles) => ({
    ...baseStyles,
    // height: 380,
    borderRadius: 25,
    overflowX: "hidden",
    padding: "0 8px",

    backgroundColor: "#171a21",

    "&::-webkit-scrollbar": {
      width: 6,
      background: "#FFFFFF1A",
    },
    "&::-webkit-scrollbar-track": {
      borderRadius: 10,
      marginTop: "5px", // Відступ зверху для треку скролбару
      marginBottom: "5px", // Відступ знизу для треку скролбару
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: 10,
      background: "#FFFFFF33",
    },
  }),

  placeholder: (styles) => ({
    ...styles,
    color: "#99A0AE",
    fontWeight: 400,
    fontSize: 15,
    position: "absolute",
  }),

  input: (styles) => ({
    ...styles,
    color: "white",
    "&::placeholder": {
      color: "green", // Колір placeholder для одиночного селекту
    },
  }),

  valueContainer: (provided, state) => ({
    ...provided,
    padding: "2px 0 2px 0",
  }),

  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    const customStyle = {
      ...styles,
      color: "#F2F5FA",
      borderRadius: 20,
      backgroundColor: isFocused ? "#1E2437" : "#171a21",
      marginTop: 7,
      padding: "10px 8px 10px 16px",
      // borderBottom: "1px solid #5A6A85",
      cursor: "pointer",
      textAlign: "left",
      "&:last-child": {
        marginBottom: 7,
        // borderBottom: "none",
      },
      "&:active": {
        backgroundColor: "#1E2437",
      },
      "&:hover": {
        backgroundColor: "#1E2437",
      },
    };

    if (data?.name === "app_list" && !data.is_alive) {
      customStyle.color = "#d32f2f";
    }

    return customStyle;
  },
};

export const redirectDropdownStyles = {
  control: (baseStyles, { isSelected, selectProps }) => ({
    ...baseStyles,
    background: "rgba(255, 255, 255, 0.1)",
    minHeight: 48,
    borderRadius: 40,
    paddingLeft: 17,
    overflow: "hidden",
    cursor: "pointer",
    color: "#F2F5FA",
    "&:hover": {
      borderColor: selectProps.isInvalid ? "#D02533" : baseStyles.borderColor,
    },
    boxShadow: selectProps.isInvalid ? "none" : baseStyles.boxShadow,
    borderColor: selectProps.isInvalid
      ? "#D02533"
      : isSelected
        ? "#F2F5FA"
        : "#171a21",
  }),

  multiValueLabel: (provided, { data }) => ({
    ...provided,
    color: "#F2F5FA",
    // color: data.is_alive ? "#F2F5FA" : "#FF4136",
    // color: "#F2F5FA",
  }),

  multiValueRemove: (provided, state) => ({
    ...provided,
    color: "#476CFF",
    background: "none",
    // height: "40px",
    "&:hover": {
      background: "none",
      color: "#476CFF",
    },
    "& svg": {
      height: "20px", // Встановлення висоти SVG до 30 пікселів
      width: "20px", // За потреби, можна також встановити ширину
    },
  }),

  dropdownIndicator: (baseStyles, state) => ({
    ...baseStyles,
    background:
      state.isFocused || state.isHovered
        ? "rgba(255, 255, 255, 0.16)"
        : "rgba(255, 255, 255, 0.16)",
    padding: 4,
    marginRight: 16,
    borderRadius: "50%",
    svg: {
      fill: "#fff",
    },
  }),

  indicatorSeparator: () => ({
    display: "none", // Приховуємо палочку
  }),

  clearIndicator: (baseStyles) => ({
    ...baseStyles,
    color: "#476CFF", // Колір хрестика
    cursor: "pointer",
    padding: 4,
    marginRight: 16,

    borderRadius: "50%",
    "&:hover": {
      color: "#476CFF", // Колір хрестика при наведенні
    },
    // width: 24,
    // height: 25,
    background: "#FFFFFF29",

    "& svg": {
      // height: "25px", // Висота SVG-іконки
      // width: "25px", // Ширина SVG-іконки
      fill: "#fff", // Колір заливки SVG (впливає на колір хрестика)
      stroke: "#fff", // Колір обведення (якщо є)
    },
  }),

  multiValue: (provided, state) => ({
    ...provided,
    backgroundColor: "rgba(225, 231, 255, 0.2)",
    padding: "4px 6px 4px 3px",
    fontSize: "17px",
    color: "#5D87FF",
    borderRadius: "5px",
  }),

  singleValue: (provided, { data }) => {
    // console.log("FS data", data);
    return {
      ...provided,
      // backgroundColor: '#2c3c5e',
      color: data.is_alive === false ? "#d32f2f" : "#F2F5FA",
      textAlign: "left",
      // borderRadius: '5px',
    };
  },

  singleValueRemove: (provided, state) => ({
    ...provided,
    color: "red",
  }),

  menu: (baseStyles) => ({
    ...baseStyles,
    backgroundColor: "#171a21",
    borderRadius: 25,
    zIndex: 1000 /* Високий z-index, щоб він накривав іконки */,
    // padding: "0 8px",
    // maxHeight: '250px', // Змініть на бажану висоту
    // overflowY: 'auto',
  }),

  menuList: (baseStyles) => ({
    ...baseStyles,
    // height: 380,
    borderRadius: 25,
    overflowX: "hidden",
    padding: "0 8px",

    backgroundColor: "#171a21",

    "&::-webkit-scrollbar": {
      width: 6,
      background: "#FFFFFF1A",
    },
    "&::-webkit-scrollbar-track": {
      borderRadius: 10,
      marginTop: "5px", // Відступ зверху для треку скролбару
      marginBottom: "5px", // Відступ знизу для треку скролбару
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: 10,
      background: "#FFFFFF33",
    },
  }),

  placeholder: (styles) => ({
    ...styles,
    color: "#99A0AE",
    fontWeight: 400,
    fontSize: 15,
    position: "absolute",
  }),

  input: (styles) => ({
    ...styles,
    color: "white",
    "&::placeholder": {
      color: "green", // Колір placeholder для одиночного селекту
    },
  }),

  valueContainer: (provided, state) => ({
    ...provided,
    padding: "2px 0 2px 0",
  }),

  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    const customStyle = {
      ...styles,
      color: "#F2F5FA",
      borderRadius: 20,
      backgroundColor: isFocused ? "#1E2437" : "#171a21",
      marginTop: 7,
      padding: "10px 8px 10px 16px",
      // borderBottom: "1px solid #5A6A85",
      textAlign: "left",
      cursor: isDisabled ? "default" : "pointer",
      "&:last-child": {
        marginBottom: 7,
        // borderBottom: "none",
      },
      "&:active": {
        backgroundColor: !isDisabled && "#1E2437",
      },
      "&:hover": {
        backgroundColor: !isDisabled && "#1E2437",
      },
    };

    if (data?.name === "app_list" && data.is_alive === false) {
      customStyle.color = "#d32f2f";
    }

    return customStyle;
  },
};

const GetLinksPopup = ({ domain, active, setActive, redirectors }) => {
  const [applicationsPopupIsVisible, setApplicationsPopupIsVisible] =
    useState(false);
  const [currentSub7, setCurrentSub7] = useState(null);
  const { topUpBalanceIsActive } = useSelector(financeSelector);
  const { exitPopup, isCollapsedSidebar } = useSelector(globalDataSelector);

  useEffect(() => {
    if (topUpBalanceIsActive || exitPopup) {
      setActive(false);
    }
  }, [topUpBalanceIsActive, exitPopup]);

  useEffect(() => {
    if (redirectors && redirectors?.length > 0 && redirectors[0]?.sub7) {
      setCurrentSub7({
        value: redirectors[0]?.sub7,
        label: redirectors[0]?.sub7,
      });
    }

    const handleEscClose = (e) => {
      if (e.key === "Escape") {
        setActive(false);
        if (document.activeElement) {
          document.activeElement.blur();
        }
      }
    };

    document.addEventListener("keydown", handleEscClose);

    return () => {
      document.removeEventListener("keydown", handleEscClose);
    };
  }, [redirectors, setActive]);

  const domainLink = `https://${domain}/?pixel_id={PIXEL}&sub1={sub1}&sub2={sub2}&sub3={sub3}&sub4={sub4}&sub5={sub5}&sub6={sub6}&sub7=${currentSub7?.value}`;

  const handleClosePopup = (event) => {
    if (event.target.classList.contains(s.popupWrapper)) {
      setActive(false);
    }
  };

  const sub7ListOptions = redirectors.map(({ sub7 }) => ({
    value: sub7,
    label: sub7,
  }));

  const applications = redirectors.find(
    (item) => item.sub7 === currentSub7?.value,
  )?.application_percentages;

  return (
    <div
      className={`${s.popupWrapper} ${active ? s.active : ""} ${isCollapsedSidebar === "true" ? s.collapsed : ""}`}
      onClick={handleClosePopup}
    >
      <div className={active ? `${s.popup} ${s.active}` : s.popup}>
        <div className={s.header}>
          <button
            onClick={() => setActive(false)}
            className={s.closePopup}
            type="button"
          >
            <GlobalSvgSelector id="close-popup" />
          </button>
          <div className={s.icon}>
            <GlobalSvgSelector id="copy-link-icon" />
          </div>
          <div className={s.popupInfo}>
            <p className={s.title}>Получение ссылки</p>
            <p className={s.description}>
              Мы настоятельно рекомендуем проверять корректность создания каждой
              ссылки перед запуском рекламной кампании. Такая проверка позволит
              избежать потери трафика и гарантировать эффективность рекламной
              кампании.
            </p>
          </div>
        </div>
        <div className={s.body}>
          <div className={s.redirectorInputWrapper}>
            <div className={s.selectWrapper}>
              <p className={s.inputName}>Выберите редиректор</p>
              <Select
                value={currentSub7}
                isClearable={false}
                options={sub7ListOptions}
                onChange={(o) => {
                  setCurrentSub7(o);
                }}
                isMulti={false}
                placeholder="Sub7"
                styles={getLinksPopupStyles}
                noOptionsMessage={() => "Нет опций"}
              />
            </div>
            <div
              className={s.applicationsWrapper}
              onMouseEnter={() => setApplicationsPopupIsVisible(true)}
              onMouseLeave={() => setApplicationsPopupIsVisible(false)}
            >
              {applicationsPopupIsVisible && redirectors?.length > 0 && (
                <ul className={s.applicationsPercentagesPopup}>
                  {applications?.map(({ application, percentage }, index) => {
                    const { name, icon } = application;

                    return (
                      <li className={s.item} key={index}>
                        <span
                          className={s.iconWrapper}
                          style={{
                            backgroundImage: icon ? `url(${icon})` : "none",
                          }}
                        ></span>

                        <div className={s.appNameWrapper}>
                          <AdaptiveMuiTooltip title={name}>
                            {name}
                          </AdaptiveMuiTooltip>
                        </div>
                        <span className={s.percentage}>
                          {parseInt(percentage)}%
                        </span>
                      </li>
                    );
                  })}
                </ul>
              )}

              <div className={s.applicationsContainer}>
                {applications?.map(({ application, percentage }, index) => {
                  const { icon } = application;

                  return (
                    <div className={s.appValue} key={index}>
                      <span
                        className={s.iconWrapper}
                        style={{
                          backgroundImage: icon ? `url(${icon})` : "none",
                        }}
                      ></span>
                      <span>{parseInt(percentage)}%</span>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div>
            <p className={s.inputName}>Ссылка для залива в Facebook</p>
            <BaseTextInput
              value={currentSub7?.value && domain && domainLink}
              placeholder="Выберите sub7"
              copy={true}
              // redirect
              alertMessage="Ссылка для Facebook скопирована"
              alertType={snackbar_info}
            />
          </div>
          <div className={s.trafficMessage}>
            В вашей ссылке для залива замените служебные параметры, которые
            обернуты в скобки {`{ }`} на ваши значения, параметр sub7
            зафиксирован за редиректором, если вы его измените или удалите, есть
            вероятность потери вашего траффика
          </div>
          <p className={s.postbackTitle}>Постбэки</p>
          <div>
            <p className={s.inputName}>Регистрация</p>
            <BaseTextInput
              value="https://servapi.wwa-ios.com/event/?event=lead&payout={revenue}&tds_clickid={tds_clickid}"
              placeholder="Reg"
              copy={true}
              alertMessage="Ссылка на постбек скопирована"
              alertType={snackbar_info}
            />
          </div>
          <div>
            <p className={s.inputName}>Депозит</p>
            <BaseTextInput
              value="https://servapi.wwa-ios.com/event/?event=sale&payout={revenue}&tds_clickid={tds_clickid}"
              placeholder="Dep"
              copy={true}
              alertMessage="Ссылка на постбек скопирована"
              alertType={snackbar_info}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GetLinksPopup;
