import React from "react";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import { createTheme, ThemeProvider } from "@mui/material";

export const SidebarToolTipTheme = createTheme({
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          cursor: "default",
          marginTop: 35,
          backgroundColor: "#202936",
          color: "white",
          fontSize: 14,
          letterSpacing: 0.5,
          fontWeight: 400,
          pointerEvents: "none", // Додано властивість pointerEvents
        },
      },
    },
  },
});

const SidebarMuiTooltip = ({
  title,
  children,
  disabled,
  offsetValue = -16,
}) => {
  const popperProps = {
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [offsetValue, 0], // Зміщення тултипу праворуч на 5 пікселів
        },
      },
      {
        name: "flip",
        options: {
          fallbackPlacements: ["left", "top", "bottom"],
        },
      },
      {
        name: "preventOverflow",
        options: {
          boundary: "viewport",
        },
      },
    ],
    style: { pointerEvents: "none" }, // Додано властивість pointerEvents
  };

  return (
    <ThemeProvider theme={SidebarToolTipTheme}>
      <Tooltip
        title={title}
        PopperProps={popperProps}
        style={disabled ? { cursor: "not-allowed" } : null}
        placement="right" // Встановлюємо розташування праворуч
      >
        <IconButton>{children}</IconButton>
      </Tooltip>
    </ThemeProvider>
  );
};

export default SidebarMuiTooltip;
