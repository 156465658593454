import React from 'react';
import s from './emptyList.module.scss'
const EmptyList = () => {

    return (
        <div className={s.emptyList}>
            Записей не найдено
        </div>
    )
}

export default EmptyList;