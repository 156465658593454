import React, { useState, useEffect, useRef } from "react";
import s from "./flowTableGeoTooltip.module.scss";

const FlowTableGeoTooltip = ({ children, geos, geosList }) => {
  const [visible, setVisible] = useState(false);
  const tooltipRef = useRef(null);

  // Відображення або приховування тултипу
  const toggleTooltip = () => setVisible(!visible);

  // Закриття тултипу при кліку поза ним
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
        setVisible(false);
      }
    };

    if (visible) {
      document.addEventListener("click", handleOutsideClick);
    } else {
      document.removeEventListener("click", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [visible]);

  // Вибір подій в залежності від пристрою
  const eventHandlers = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
    ? { onClick: toggleTooltip }
    : {
        onMouseEnter: () => setVisible(true),
        onMouseLeave: () => setVisible(false),
      };

  return (
    <span className={s.tooltipContainer} {...eventHandlers} ref={tooltipRef}>
      {children}
      {visible && (
        <div className={s.tooltipContent}>
          {geos.map(({ id, code }) => {
            const foundFlag = geosList.find((item) => item.id === id)?.flag;

            return (
              <div className={s.geoValue} key={id}>
                <span
                  className={s.flagWrapper}
                  style={{
                    backgroundImage: foundFlag ? `url(${foundFlag})` : "none",
                  }}
                ></span>
                <span>{code}</span>
              </div>
            );
          })}
        </div>
      )}
    </span>
  );
};

export default FlowTableGeoTooltip;
