import React from "react";
import s from "./gradientBorder.module.scss";

const GradientBorder = ({ className }) => {
  return (
    <div className={`${s.gradientBorderContainer} ${className || ""}`}></div>
  );
};

export default GradientBorder;
