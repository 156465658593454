import React, { useEffect, useMemo, useRef, useState } from "react";
import { params, sortDateOptions } from "../../../../utils/vars/params";
import { useDispatch, useSelector } from "react-redux";
import {
  globalDataSelector,
  setAdaptiveMenu,
} from "../../../../redux/slices/globalDataSlice";
import {
  fetchCloacaStats,
  resetCloacaStats,
  statisticsSelector,
} from "../../../../redux/slices/statisticsSlice";
import { Link, useSearchParams } from "react-router-dom";
import useGetSearchParams from "../../../../hooks/useGetSearchParams";
import useSetSearchParams from "../../../../hooks/useSetSearchParams";
import {
  useSearchParamsOptions,
  useSelectOptions,
} from "../../../../utils/helpers/useSelectHelpers";
import {
  country_code,
  created_at_after,
  created_at_before,
  flow,
  ip_address,
  onSortDateHandler,
  os,
  page,
  sort,
} from "../ClicksStats/ClicksStats";
import { fetchCloacaStatsFilters } from "../../../../api/helpers/fetchFilters";
import { toast } from "react-hot-toast";
import { getDefaultParams } from "../../../../api/helpers/scripts";
import { INVALID_PAGE_ERROR } from "../../../../utils/vars/staticVariables";
import s from "./cloacaStats.module.scss";
import MultiSelect from "../../../../components/MultiSelect/MultiSelect";
import { MuiDatePicker } from "../../../../components/Blocks/MuiDatePicker/MuiDatePicker";
import {
  formatDateTime,
  formatTimestampToServerDate,
  stringToDayJs,
} from "../../../../utils/helpers/formatTime";
import { IpCreatableSelect } from "../../../../components/UiKit/Select/IpCreatableSelect/IpCreatableSelect";
import EmptyList from "../../../../components/EmptyList/EmptyList";
import CustomSkeleton from "../../../../components/CustomSkeleton/CustomSkeleton";
import GlobalSvgSelector from "../../../../components/GlobalSvgSelector/GlobalSvgSelector";
import Pagination from "../../../../components/Pagination/Pagination";
import { onSetPageSize } from "../../../../utils/helpers/statisticsHelper";
import { setTopUpBalanceActive } from "../../../../redux/slices/financeSlice";
import { PageSizeDropdown } from "../../../../components/UiKit/PageSizeDropdown/PageSizeDropdown";
import { hasNonEmptyParams } from "../../../../utils/helpers/hasNoneEmptyParams";
import { cleanDomain } from "../../../../utils/helpers/cleanDomain";
import GeoSelect from "../../../../components/Blocks/Selects/GeoSelect";

const CloacaStats = () => {
  const [ipList, setIpList] = useState([]);
  const [sortDate, setSortDate] = useState(sortDateOptions[0]);
  const [selectedGeos, setSelectedGeos] = useState([]);
  const [isFlowInvalid, setIsFlowInvalid] = useState(false);
  const [ipSelectErrorMessage, setIpSelectErrorMessage] = useState("");

  const promiseRef = useRef(null);
  const abortControllerRef = useRef(null);

  const dispatch = useDispatch();

  const {
    geoList,
    OSList,
    domainsList,
    isCollapsedSidebar,
    isAdaptiveMenuActive,
    pageSizeValue,
  } = useSelector(globalDataSelector);
  const { cloacaStatisticsList, cloacaLoading, cloacaTotalCount } =
    useSelector(statisticsSelector);

  const [searchParams, setSearchParams] = useSearchParams();
  const {
    sort: sortValue,
    page: pageValue,
    created_at_after: createdAtAfterParam,
    created_at_before: createdAtBeforeParam,
    flow: flowParam,
  } = useGetSearchParams();

  const updateSearchParams = useSetSearchParams(setSearchParams);

  useEffect(() => {
    const domainParam = searchParams.get("flow");

    if (domainParam) {
      const domains = domainParam.split(",");
      const cleanedDomains = domains.map((domain) =>
        cleanDomain(decodeURIComponent(domain)),
      );

      // Якщо очищені домени не збігаються з оригінальними, оновлюємо параметри
      if (cleanedDomains.join(",") !== domains.join(",")) {
        updateSearchParams("flow", cleanedDomains.join(","));
      }
    }
  }, [searchParams, updateSearchParams]);

  const [selectedOS, selectedIP] = useSearchParamsOptions(searchParams, [
    os,
    ip_address,
  ]);

  const selectedDomain = useMemo(() => {
    return flowParam ? { value: flowParam, label: flowParam } : null;
  }, [flowParam]);

  const domainsListOptions = useSelectOptions(
    domainsList,
    "address",
    "address",
  );

  const geoListOptions = useMemo(() => {
    return geoList.map((item) => ({
      label: item.name,
      value: item.code,
      icon: item.flag,
    }));
  }, [geoList]);

  const OSListOptions = useMemo(() => {
    return OSList.map((item) => ({
      label: item,
      value: item,
    }));
  }, [OSList]);

  useEffect(() => {
    fetchCloacaStatsFilters(dispatch);
  }, [dispatch]);

  useEffect(() => {
    if (
      createdAtAfterParam ||
      createdAtBeforeParam ||
      sortDate.value === "custom_date"
    ) {
      setSortDate(sortDateOptions.find((item) => item.value === "custom_date"));
    } else {
      if (!sortValue) {
        setSortDate(sortDateOptions[0]);
      } else {
        setSortDate(sortDateOptions.find(({ value }) => value === sortValue));
      }
    }
  }, [searchParams]);

  useEffect(() => {
    if (selectedIP) {
      setIpList(selectedIP);
    }
  }, []);

  useEffect(() => {
    if (ipList.length > 0)
      updateSearchParams(ip_address, ipList.map((el) => el.value).join(","));
  }, [ipList]);

  useEffect(() => {
    const newGeoListOptions = searchParams
      .get(country_code)
      ?.split(",")
      .filter((value) => value !== "")
      .map((value) => {
        return geoListOptions.find((item) => item.value === value);
      });

    if (!newGeoListOptions || newGeoListOptions.length === 0) {
      setSelectedGeos([]);
    } else {
      setSelectedGeos(newGeoListOptions);
    }
  }, [searchParams, geoListOptions]);

  const handleFetchCloacaStats = () => {
    if (!selectedDomain) {
      setIsFlowInvalid(true);
      return;
    }
    setIsFlowInvalid(false);

    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    abortControllerRef.current = new AbortController();
    const signal = abortControllerRef.current.signal;

    const promise = dispatch(
      fetchCloacaStats({
        params: getDefaultParams({
          searchParams,
          sort,
          defaultPageSize: pageSizeValue,
        }),
        signal,
      }),
    );

    promise.unwrap().catch((err) => {
      if (err?.message === INVALID_PAGE_ERROR) {
        updateSearchParams(page, 1);
        dispatch(
          fetchCloacaStats({
            params: getDefaultParams({
              searchParams,
              sort,
              defaultPageSize: pageSizeValue,
            }),
            signal,
          }),
        );
      }
    });

    promiseRef.current = promise;
  };

  useEffect(() => {
    return () => dispatch(resetCloacaStats());
  }, [dispatch]);

  const handleSetPageSize = (size) => {
    onSetPageSize(dispatch, size);
  };

  const clearSearchParams = () => {
    setSearchParams(new URLSearchParams());
    setIpList([]);
    setIsFlowInvalid(false);
    setIpSelectErrorMessage("");
    setSelectedGeos([]); // Додаємо очищення для selectedGeos
  };

  console.log("selectedGEOSSSSSSSS", selectedGeos);

  return (
    <div
      className={`${s.cloacaStatsWrapper} ${isCollapsedSidebar === "true" ? s.collapsedSidebar : ""}`}
    >
      <div className={s.pageHeaderWrapper}>
        <div className={s.pageHeaderContainer}>
          <div className={s.staticElements}>
            <div className={s.leftSideWrapper}>
              <button
                className={s.walletBtn}
                onClick={() => {
                  dispatch(setAdaptiveMenu(false));
                  dispatch(setTopUpBalanceActive(true));
                }}
              >
                <GlobalSvgSelector id="wallet" />
              </button>
              <Link className={s.walletBtn} to="/account">
                <GlobalSvgSelector id="account-gear-icon" />
              </Link>
            </div>

            <button
              className={s.menuBtn}
              onClick={() => dispatch(setAdaptiveMenu(!isAdaptiveMenuActive))}
            >
              <GlobalSvgSelector id="burger-menu" />
            </button>
          </div>
        </div>
      </div>

      <div className={s.pageContent}>
        <div className={s.filtersWrapper}>
          <div className={s.cloacaFilters}>
            <div className={s.selectDateWrapper}>
              <div className={s.selectIconWrapper}>
                <GlobalSvgSelector id="date-select-icon" />
              </div>
              <MultiSelect
                isClearable={false}
                options={sortDateOptions}
                setSelectedOptions={(value) =>
                  onSortDateHandler(value, updateSearchParams, setSortDate)
                }
                value={sortDate}
                placeholder="Дата"
                isMulti={false}
              />
            </div>

            {sortDate?.value === "custom_date" && (
              <div className={s.datePickersWrapper}>
                <div className={s.datePicker}>
                  <MuiDatePicker
                    label="Дата от"
                    value={stringToDayJs(createdAtAfterParam)}
                    onChange={(e) =>
                      updateSearchParams(
                        created_at_after,
                        formatTimestampToServerDate(e?.toString()),
                      )
                    }
                  />
                </div>
                <div className={s.datePicker}>
                  <MuiDatePicker
                    label="Дата до"
                    value={stringToDayJs(createdAtBeforeParam)}
                    onChange={(e) => {
                      updateSearchParams(
                        created_at_before,
                        formatTimestampToServerDate(e?.toString()),
                      );
                    }}
                  />
                </div>
              </div>
            )}

            <div className={s.selectWrapper}>
              <div className={s.selectContainerValidation}>
                <div className={s.selectIconWrapper}>
                  <GlobalSvgSelector id="flow-select-icon" />
                </div>
                <MultiSelect
                  extractDomain={true}
                  isClearable={true}
                  value={selectedDomain}
                  options={domainsListOptions}
                  setSelectedOptions={(option) => {
                    if (option) {
                      const { value } = option;
                      updateSearchParams(flow, value);
                      setIsFlowInvalid(false);
                    } else {
                      updateSearchParams(flow, "", true);
                    }
                  }}
                  placeholder="Поток (обязательно)"
                  isInvalid={isFlowInvalid}
                />
              </div>
              {isFlowInvalid && (
                <div className={s.errorMessage}>Выберите поток</div>
              )}
            </div>

            <div className={s.selectWrapper}>
              <div className={s.selectIconWrapper}>
                <GlobalSvgSelector id="geo-select-icon" />
              </div>
              <GeoSelect
                isClearable={true}
                value={selectedGeos}
                options={geoListOptions}
                onChange={(values) =>
                  updateSearchParams(
                    country_code,
                    values.map((el) => el.value).join(","),
                  )
                }
                placeholder="Гео"
                isMulti={true}
              />
            </div>

            <div className={s.selectWrapper}>
              <div className={s.selectIconWrapper}>
                <GlobalSvgSelector id="os-select-icon" />
              </div>
              <MultiSelect
                isClearable={true}
                value={selectedOS}
                options={OSListOptions}
                setSelectedOptions={(values) =>
                  updateSearchParams(os, values.map((el) => el.value).join(","))
                }
                placeholder="ОС"
                isMulti={true}
              />
            </div>

            <div className={s.selectWrapper}>
              <div className={s.selectContainerValidation}>
                <div className={s.selectIconWrapper}>
                  <GlobalSvgSelector id="ip-select-icon" />
                </div>
                <IpCreatableSelect
                  setErrorMessage={setIpSelectErrorMessage}
                  selectedOption={ipList}
                  setSelectedOption={(value) => {
                    setIpList(value);
                  }}
                  isInvalid={ipSelectErrorMessage}
                />
              </div>
              {ipSelectErrorMessage && (
                <div className={s.errorMessage}>{ipSelectErrorMessage}</div>
              )}
            </div>
            <div className={s.cleanBtnContainer}>
              {hasNonEmptyParams(searchParams) && (
                <button className={s.blueBorderBtn} onClick={clearSearchParams}>
                  Очистить
                </button>
              )}
              <button
                className={s.whiteBorderBtn}
                onClick={handleFetchCloacaStats}
              >
                Применить
              </button>
            </div>
          </div>
        </div>

        <div className={s.pageInnerContent}>
          {!cloacaTotalCount && !cloacaLoading ? (
            <EmptyList />
          ) : cloacaLoading ? (
            <section className={s.body}>
              <table>
                <thead>
                  <tr>
                    <th>Время</th>
                    <th>Поток</th>
                    <th>Гео</th>
                    <th>ОС</th>
                    <th>Браузер</th>
                    <th>Языки</th>
                    <th>Режим</th>
                    <th>Статус</th>
                  </tr>
                </thead>
              </table>
              <div className={s.skeletonWrapper}>
                <CustomSkeleton styles={s.skeletonTable} />
              </div>
            </section>
          ) : (
            <section className={s.body}>
              <table>
                <thead>
                  <tr>
                    <th>Время</th>
                    <th>Поток</th>
                    <th>Гео</th>
                    <th>ОС</th>
                    <th>Браузер</th>
                    <th>Языки</th>
                    <th>Режим</th>
                    <th>Статус</th>
                  </tr>
                </thead>
                <tbody>
                  {cloacaStatisticsList?.map(
                    (
                      {
                        timestamp,
                        country_code,
                        os,
                        browser,
                        languages,
                        mode,
                        target,
                        flow,
                      },
                      index,
                    ) => {
                      const { date, time } = formatDateTime(timestamp);

                      const geoFlag = geoList.find(
                        (geoItem) => geoItem.code === country_code,
                      )?.flag;

                      return (
                        <tr key={index}>
                          <td>
                            <p>{date}</p>
                            <p style={{ marginTop: 4 }}>{time}</p>
                          </td>
                          <td>{flow}</td>
                          <td className={s.geoTd}>
                            <span className={s.value}>
                              <span
                                className={s.flagWrapper}
                                style={
                                  geoFlag
                                    ? {
                                        backgroundImage: `url(${geoFlag})`,
                                      }
                                    : {
                                        background: "grey",
                                      }
                                }
                              ></span>
                              <span>{country_code}</span>
                            </span>
                          </td>
                          <td>{os}</td>
                          <td>{browser}</td>
                          <td>{languages?.join(" | ")}</td>
                          <td>{mode}</td>
                          <td>
                            {target === 0 ? (
                              <p className={s.mismatch}>
                                <GlobalSvgSelector id="cross-icon" />
                                Несовпадение ОС/ хостинг/VPN
                              </p>
                            ) : (
                              <p className={s.content}>
                                <GlobalSvgSelector id="check-icon" />
                                Контент
                              </p>
                            )}
                          </td>
                        </tr>
                      );
                    },
                  )}
                </tbody>
              </table>
            </section>
          )}
        </div>

        <div
          className={`${s.paginationContainerStatistics} ${isCollapsedSidebar === "true" ? s.collapsed : ""}`}
        >
          <div className={s.countPageSizeContainer}>
            <div className={s.newRecordsCount}>
              Всего: {!cloacaLoading && <span>{cloacaTotalCount}</span>}
            </div>
            <div className={s.pageSizeDropdownContainer}>
              <PageSizeDropdown
                setSize={handleSetPageSize}
                size={pageSizeValue}
              />
            </div>
          </div>
          {cloacaTotalCount > pageSizeValue && (
            <Pagination
              currentPage={parseInt(pageValue) || 1}
              setCurrentPage={(page) => {
                updateSearchParams(params.page, page);
                handleFetchCloacaStats();
              }}
              total={cloacaTotalCount}
              limit={pageSizeValue}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default CloacaStats;
