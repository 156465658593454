import React, { useState, useEffect, useRef } from "react";
import Tooltip from "@mui/material/Tooltip";
import { createTheme, ThemeProvider, useMediaQuery } from "@mui/material";

export const toolTipTheme = createTheme({
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          cursor: "default",
          marginTop: 0,
          backgroundColor: "#202936",
          color: "white",
          fontSize: 14,
          padding: 0,
          letterSpacing: 0.5,
          fontWeight: 400,
          zIndex: 1,
          maxWidth: "100%",
          wordWrap: "break-word",
        },
      },
    },
  },
});

const AdaptiveMuiTooltip = ({
  title,
  children,
  disabled,
  offsetValue = -10,
}) => {
  const [open, setOpen] = useState(false);
  const isMobile = useMediaQuery("(max-width:1000px)");
  const ref = useRef();

  const handleClick = () => {
    if (isMobile) {
      setOpen(!open);
    }
  };

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setOpen(false);
    }
  };

  const handleScroll = () => {
    setOpen(false);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    window.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const popperProps = {
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, offsetValue],
        },
      },
      {
        name: "preventOverflow",
        options: {
          boundary: "clippingParents",
          altBoundary: true,
          rootBoundary: "viewport",
        },
      },
      {
        name: "flip",
        options: {
          fallbackPlacements: ["top", "bottom", "right", "left"],
        },
      },
    ],
  };

  return (
    <ThemeProvider theme={toolTipTheme}>
      <Tooltip
        title={title}
        PopperProps={popperProps}
        open={open}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        disableHoverListener={isMobile}
        disableFocusListener={isMobile}
        disableTouchListener={isMobile}
      >
        <span
          style={{ cursor: "default" }} // Встановлюємо курсор тексту
          // style={{ cursor: "text" }} // Встановлюємо курсор тексту
          // style={{ cursor: disabled ? "not-allowed" : "text" }} // Додаємо курсор тексту
          onClick={handleClick}
          onMouseEnter={() => !isMobile && setOpen(true)}
          onMouseLeave={() => !isMobile && setOpen(false)}
          ref={ref}
        >
          {children}
        </span>
      </Tooltip>
    </ThemeProvider>
  );
};

export default AdaptiveMuiTooltip;
