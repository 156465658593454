import React from "react";
import {Outlet} from "react-router-dom";
import s from './prelandLayout.module.scss'

const PrelandLayout = ({}) => {
    return (
        <div className={s.prelandLayoutContent}>
            <Outlet/>
        </div>
    );
};

export default PrelandLayout;