import React, { useMemo, useState } from "react";
import s from "./redirector.module.scss";
import ReactSelectTheme from "../../../../../components/Blocks/ReactSelectTheme/ReactSelectTheme";
import GradientBorder from "../../../../../components/Blocks/GradientBorder/GradientBorder";
import RedirectorCard from "./RedirectorCard/RedirectorCard";
import { useSelector } from "react-redux";
import { applicationSelector } from "../../../../../redux/slices/applicationSlice";
import { Link } from "react-router-dom";
import PrimaryBtn from "../../../../../components/UiKit/Buttons/PrimaryBorderBtn/PrimaryBorderBtn";
import { redirectorSelector } from "../../../../../redux/slices/redirectorSlice";
import { getUser } from "../../../../../storage/storage";
// import CreateRedirectorPopup from "../../../AllRedirectors/CreateRedirectorPopup/CreateRedirectorPopup";
import { flowSettingsSelector } from "../../../../../redux/slices/flowSettingsSlice";
import CreateRedirectorPopup from "./CreateRedirectorPopup/CreateRedirectorPopup";

const Redirector = ({ formik }) => {
  const [isCreateRedirectorActive, setCreateRedirectorActive] = useState(false);
  const { appList } = useSelector(applicationSelector);
  const { setFlowRedirectPending } = useSelector(flowSettingsSelector);

  const { redirectorsList } = useSelector(redirectorSelector);

  const { user_id } = getUser();

  const redirectorsListOptions = useMemo(() => {
    const redirectorsIdToExclude = formik.values.redirectors.map(
      (item) => item.id,
    );

    return redirectorsList
      .filter(({ id }) => !redirectorsIdToExclude.includes(id))
      .map(({ sub7, id }) => ({
        label: sub7,
        value: id,
      }));
  }, [redirectorsList, formik.values.redirectors]);

  return (
    <div className={s.redirectWrapper}>
      <CreateRedirectorPopup
        active={isCreateRedirectorActive}
        setActive={setCreateRedirectorActive}
        setFlowRedirectorFormik={formik}
        appList={appList}
      />
      <div className={s.headerContainer}>
        <div className={s.fieldWrapper}>
          <p className={s.fieldName}>Выберите дополнительный редирект</p>
          <ReactSelectTheme
            darkTheme={true}
            options={redirectorsListOptions}
            placeholder="Редирект"
            value={null}
            action={(selectedOption) => {
              const foundObj = redirectorsList.find(
                (item) => item.id === selectedOption.value,
              );

              if (foundObj) {
                const updatedRedirectorsArr = [
                  foundObj,
                  ...formik.values.redirectors,
                ];

                formik.setFieldValue("redirectors", updatedRedirectorsArr);
              }
            }}
          />
        </div>
        <div className={s.buttonContainer}>
          <button
            className={s.primaryBtn}
            type="button"
            onClick={() => setCreateRedirectorActive(true)}
          >
            Создать редирект
          </button>
        </div>
      </div>
      <GradientBorder className={s.gradientBorderCustom} />
      <div className={s.pageInnerContent}>
        <div className={s.cardsWrapper}>
          {formik.values.redirectors.map((item) => (
            <RedirectorCard
              user_id={user_id}
              key={item.id}
              {...item}
              setFlowRedirectorFormik={formik}
              redirectorsListOptions={redirectorsListOptions}
              appList={appList}
              redirectorsList={redirectorsList}
            />
          ))}
        </div>
        {/*)}*/}
      </div>
      <form className={s.buttons} onSubmit={formik.handleSubmit}>
        <Link className={s.whiteBorderBtn} to="/my-flows">
          Отменить
        </Link>
        <PrimaryBtn
          text="Сохранить"
          submit={true}
          pending={setFlowRedirectPending}
          className={s.customPrimaryBtn}
        />
      </form>
    </div>
  );
};

export default Redirector;
