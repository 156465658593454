import React from "react";
import Select from "react-select";

export const selectStyles = {
  control: (baseStyles, { isSelected, selectProps }) => ({
    ...baseStyles,
    background: "#171a21",
    minHeight: 40,
    borderRadius: 40,
    paddingLeft: 34,
    overflow: "hidden",
    cursor: "pointer",
    color: "#F2F5FA",
    "&:hover": {
      borderColor: selectProps.isInvalid ? "#D02533" : baseStyles.borderColor,
    },
    boxShadow: selectProps.isInvalid ? "none" : baseStyles.boxShadow,
    borderColor: selectProps.isInvalid
      ? "#D02533"
      : isSelected
        ? "#F2F5FA"
        : "#171a21",
  }),

  multiValueLabel: (provided, { data }) => ({
    ...provided,
    color: "#F2F5FA",
    // color: data.is_alive ? "#F2F5FA" : "#FF4136",
    // color: "#F2F5FA",
  }),

  multiValueRemove: (provided, state) => ({
    ...provided,
    color: "#476CFF",
    background: "none",
    // height: "40px",
    "&:hover": {
      background: "none",
      color: "#476CFF",
    },
    "& svg": {
      height: "20px", // Встановлення висоти SVG до 30 пікселів
      width: "20px", // За потреби, можна також встановити ширину
    },
  }),

  dropdownIndicator: (baseStyles) => ({
    ...baseStyles,
    display: "none", // Приховуємо стрілочку
  }),

  indicatorSeparator: () => ({
    display: "none", // Приховуємо палочку
  }),

  clearIndicator: (baseStyles) => ({
    ...baseStyles,
    color: "#476CFF", // Колір хрестика
    cursor: "pointer",
    padding: 4,
    marginRight: 16,

    borderRadius: "50%",
    "&:hover": {
      color: "#476CFF", // Колір хрестика при наведенні
    },
    // width: 24,
    // height: 25,
    background: "#FFFFFF29",

    "& svg": {
      // height: "25px", // Висота SVG-іконки
      // width: "25px", // Ширина SVG-іконки
      fill: "#fff", // Колір заливки SVG (впливає на колір хрестика)
      stroke: "#fff", // Колір обведення (якщо є)
    },
  }),

  multiValue: (provided, state) => ({
    ...provided,
    backgroundColor: "rgba(225, 231, 255, 0.2)",
    padding: "0px 3px 0px 3px",
    fontSize: "17px",
    color: "#5D87FF",
    borderRadius: "4px",
  }),

  singleValue: (provided, state) => ({
    ...provided,
    // backgroundColor: '#2c3c5e',
    color: "#F2F5FA",
    // borderRadius: '5px',
  }),

  singleValueRemove: (provided, state) => ({
    ...provided,
    color: "red",
  }),

  menu: (baseStyles) => ({
    ...baseStyles,
    backgroundColor: "#171a21",
    borderRadius: 25,
    // height: 400,
    zIndex: 1000 /* Високий z-index, щоб він накривав іконки */,
    // padding: "0 8px",
    // maxHeight: '250px', // Змініть на бажану висоту
    // overflowY: 'auto',
    // height: 400, // Встановлюємо висоту на 400 пікселів
    maxHeight: "100%", // Зробимо максимальну висоту 100% від батьківського елемента

    // maxHeight: 400, // Замість height встановлюємо maxHeight
    // overflowY: "auto", // Додаємо авто-скролл, якщо елементи перевищують висоту
  }),

  menuList: (baseStyles) => ({
    ...baseStyles,
    // height: 380,
    borderRadius: 25,
    overflowX: "hidden",
    padding: "0 8px",
    // height: "100%",
    // height: 600,
    // height: 400, // Встановлюємо висоту на 400 пікселів

    // maxHeight: "100%", // Зробимо максимальну висоту 100% від батьківського елемента
    maxHeight: 370, // Замість height встановлюємо maxHeight

    backgroundColor: "#171a21",

    "&::-webkit-scrollbar": {
      width: 6,
      background: "#FFFFFF1A",
    },
    "&::-webkit-scrollbar-track": {
      borderRadius: 10,
      marginTop: "5px", // Відступ зверху для треку скролбару
      marginBottom: "5px", // Відступ знизу для треку скролбару
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: 10,
      background: "#FFFFFF33",
    },
  }),

  placeholder: (styles) => ({
    ...styles,
    color: "#99A0AE",
    fontWeight: 400,
    fontSize: 15,
    position: "absolute",
  }),

  input: (styles) => ({
    ...styles,
    color: "white",
    "&::placeholder": {
      color: "green", // Колір placeholder для одиночного селекту
    },
  }),

  valueContainer: (provided, state) => ({
    ...provided,
  }),

  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    const customStyle = {
      ...styles,
      color: "#F2F5FA",
      borderRadius: 20,
      backgroundColor: isFocused ? "#1E2437" : "#171a21",
      marginTop: 7,
      padding: "10px 8px 10px 16px",
      // borderBottom: "1px solid #5A6A85",
      cursor: "pointer",
      "&:last-child": {
        marginBottom: 7,
        // borderBottom: "none",
      },
      "&:active": {
        backgroundColor: "#1E2437",
      },
      "&:hover": {
        backgroundColor: "#1E2437",
      },
    };

    if (data?.name === "app_list" && !data.is_alive) {
      customStyle.color = "#FF4136";
    }

    return customStyle;
  },
};

const MultiSelect = ({
  options,
  setSelectedOptions,
  placeholder,
  isMulti,
  value,
  isClearable,
  defaultValue,
  isInvalid,
  extractDomain,
}) => {
  // Функція для отримання домену з URL
  const getDomainFromUrl = (url) => {
    try {
      const domain = new URL(url).hostname;
      return domain.replace(/^www\./, ""); // Видаляємо 'www', якщо є
    } catch (e) {
      // Якщо URL некоректний або не містить домену
      return url;
    }
  };

  // Пошук опцій за доменом або повним значенням
  const customFilter = (option, inputValue) => {
    let input = inputValue;

    // Якщо передано пропс `extractDomain`, витягуємо домен із введеного значення
    if (extractDomain) {
      input = getDomainFromUrl(inputValue); // Витягуємо домен із URL
    }

    return option.label.toLowerCase().includes(input.toLowerCase());
  };

  return (
    <Select
      noOptionsMessage={() => "Нет опций"}
      defaultValue={!isMulti && defaultValue}
      options={options}
      onChange={setSelectedOptions}
      value={value}
      isMulti={isMulti}
      styles={selectStyles}
      isClearable={isClearable}
      placeholder={placeholder}
      isInvalid={isInvalid}
      filterOption={customFilter} // Використовуємо кастомний фільтр
    />
  );
};

export default MultiSelect;
