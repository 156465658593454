import { useDispatch, useSelector } from "react-redux";
import {
  financeSelector,
  setTopUpBalanceActive,
} from "../../../../redux/slices/financeSlice";
import { globalDataSelector } from "../../../../redux/slices/globalDataSlice";
import React, { useEffect } from "react";
import s from "./newTopUpBalancePopup.module.scss";
import GlobalSvgSelector from "../../../GlobalSvgSelector/GlobalSvgSelector";
import BaseTextInput from "../../../BaseTextInput/BaseTextInput";
import { snackbar_info } from "../../../../utils/vars/staticVariables";

const NewTopUpBalancePopup = () => {
  const { wallet, topUpBalanceIsActive } = useSelector(financeSelector);
  const { exitPopup, isCollapsedSidebar } = useSelector(globalDataSelector);

  const dispatch = useDispatch();

  useEffect(() => {
    const handleEscClose = (event) => {
      if (event.key === "Escape") {
        handleClosePopup();
      }
    };

    if (topUpBalanceIsActive) {
      document.addEventListener("keydown", handleEscClose);
    }

    return () => {
      document.removeEventListener("keydown", handleEscClose);
    };
  }, [topUpBalanceIsActive, dispatch]);

  const handleClosePopup = () => {
    dispatch(setTopUpBalanceActive(false));
  };

  const handleWrapperClick = (event) => {
    if (event.target.classList.contains(s.popupWrapper)) {
      handleClosePopup();
    }
  };

  useEffect(() => {
    if (exitPopup && !topUpBalanceIsActive) {
      handleClosePopup();
    } else {
    }
  }, [exitPopup]);

  return (
    <div
      className={`${s.popupWrapper} ${topUpBalanceIsActive ? s.active : ""} ${isCollapsedSidebar === "true" ? s.collapsed : ""}`}
      onClick={handleWrapperClick}
    >
      <div
        className={topUpBalanceIsActive ? `${s.popup} ${s.active}` : s.popup}
      >
        <div className={s.header}>
          <button
            onClick={handleClosePopup}
            className={s.closePopup}
            type="button"
          >
            <GlobalSvgSelector id="close-popup" />
          </button>
          <div className={s.icon}>
            <GlobalSvgSelector id="balance-popup-icon" />
          </div>
          <div className={s.popupInfo}>
            <p className={s.title}>Пополнение баланса</p>
            <p className={s.description}>
              При переводе на указанный адрес ваш баланс обновится
              автоматически. В случае проблем обратитесь в{" "}
              <a
                href="https://t.me/yaroslava_support_wwa"
                target="_blank"
                rel="noreferrer"
              >
                Поддержку
              </a>
            </p>
          </div>
        </div>
        <div className={s.body}>
          <p className={s.inputDesc}>Криптокошелек (USDT TRC20)</p>
          <BaseTextInput
            value={wallet}
            placeholder="Загрузка..."
            copy={true}
            alertMessage="Криптокошелек скопирован"
            alertType={snackbar_info}
          />
        </div>
      </div>
    </div>
  );
};

export default NewTopUpBalancePopup;
