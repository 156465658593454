import React from 'react';
import Skeleton, {SkeletonTheme} from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'


const CustomSkeleton = ({styles}) => {
    return (
        <SkeletonTheme baseColor='#232D3F' highlightColor='#5578DA80'>
            <Skeleton className={styles}/>
        </SkeletonTheme>
    );
};

export default CustomSkeleton;