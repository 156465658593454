import React, { useState } from "react";
import CreatableSelect from "react-select/creatable";
import { selectStyles } from "../../../MultiSelect/MultiSelect";
import s from "./ipCreatableSelect.module.scss";

export const IpCreatableSelect = ({
  selectedOption,
  setSelectedOption,
  setErrorMessage,
  isInvalid,
}) => {
  const handleChange = (newValue) => {
    setSelectedOption(newValue);
  };

  const handleCreate = (inputValue) => {
    const newValue = { label: inputValue, value: inputValue.toLowerCase() };
    setSelectedOption((prev) => [...prev, newValue]);
  };

  return (
    <>
      <CreatableSelect
        formatCreateLabel={(inputValue) => `Создать "${inputValue}"`}
        noOptionsMessage={() => "Добавьте IP"}
        styles={selectStyles}
        value={selectedOption}
        onCreateOption={(ip) => {
          if (
            /^((25[0-5]|(2[0-4]|1\d|[1-9]|)\d)\.?\b){4}$/.test(ip) ||
            isIPv6(ip)
          ) {
            handleCreate(ip);
            setErrorMessage("");
          } else {
            setErrorMessage(
              "IP должен быть правильного формата: 0.0.0.0 (IPv4) или 0:0:0:0:0:0:0:0 (IPv6)",
            );
          }
        }}
        onChange={handleChange}
        isClearable={true}
        isMulti
        placeholder="IP адрес"
        isInvalid={isInvalid}
      />
      {/*{errorMessage && <div className={s.errorMessage}>{errorMessage}</div>}*/}
    </>
  );
};

export function isIPv6(ip) {
  const groups = ip.split("::");

  let valuesSum = 0;
  for (const group of groups) {
    if (group.length === 0) continue;
    const values = group.split(":");

    for (const value of values) {
      if (value.length > 4 || !/^[0-9A-Fa-f]+$/.test(value)) return false;
    }

    valuesSum += values.length;
  }

  return !(valuesSum > 8 || (valuesSum !== 8 && !ip.includes("::")));
}
