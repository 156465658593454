import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import $api from "../../api/url";
import { getSidebarWidth } from "../../storage/storage";
import {
  getPageSize,
  getStatisticsPageSize,
} from "../../storage/statisticsPageSize";
import { errorMessage } from "../../api/helpers/scripts";
import axios from "axios";
import { ABORTED_ERROR } from "../../utils/vars/staticVariables";

export const fetchDomainsList = createAsyncThunk(
  "globalData/fetchDomains",
  async () => {
    try {
      const { data } = await $api.get(`/domains/domains/`);
      return data;
    } catch (error) {
      throw error;
    }
  },
);

export const fetchGeosList = createAsyncThunk(
  "application/fetchGeoList",
  async () => {
    try {
      const response = await $api.get("/flow-settings/geo");
      return response.data;
    } catch (error) {
      throw error;
    }
  },
);

export const fetchUsersList = createAsyncThunk(
  "balance/fetchUsers",
  async () => {
    try {
      const { data } = await $api.get(`/users/users/`);
      return data;
    } catch (error) {
      throw error;
    }
  },
);

export const createTeam = createAsyncThunk(
  "management/createTeam",
  async (dataObj) => {
    try {
      const response = await $api.post(`/support/add-new-team/`, dataObj);

      const { data, status } = response;

      return { data, status };
    } catch (error) {
      const { data, status } = error.response;

      return { data, status };
    }
  },
);

export const fetchTechnicalUsersList = createAsyncThunk(
  "support/fetchTechnicalUsersList",
  async (params, { signal, rejectWithValue }) => {
    try {
      // const { data } = await $api.get(`support/get-technical-users-list/`);
      const { data } = await $api.get(
        "support/get-technical-users-list?" + params,
        {
          signal,
        },
      );
      return data;
    } catch (error) {
      let err = errorMessage(error);

      if (axios.isCancel(error)) {
        return rejectWithValue();
      }

      throw new Error(err);
    }
  },
);

export const fetchOSList = createAsyncThunk("statistics/fetchOS", async () => {
  try {
    const { data } = await $api.get("/click-data/os/");

    return data;
  } catch (error) {
    throw error;
  }
});

const globalDataSlice = createSlice({
  name: "globalData",
  initialState: {
    domainsList: [],
    geoList: [],
    usersList: [],
    OSList: [],

    exitPopup: false,
    isAdaptiveMenuActive: false,
    isCollapsedSidebar: getSidebarWidth() || "true",
    pageSizeValue: getPageSize(),

    technicalUsersListPending: false,
    technicalUsersList: [],
    technicalUsersCount: null,
  },
  reducers: {
    setIsCollapsedSidebar: (state, action) => {
      state.isCollapsedSidebar = action.payload;
    },
    setPageSizeValue: (state, action) => {
      state.pageSizeValue = action.payload;
    },

    setExitPopup: (state, action) => {
      state.exitPopup = action.payload;
    },
    setAdaptiveMenu: (state, action) => {
      state.isAdaptiveMenuActive = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDomainsList.fulfilled, (state, action) => {
        state.domainsList = action.payload;
      })

      .addCase(fetchGeosList.pending, (state) => {
        state.geoListPending = true;
      })
      .addCase(fetchGeosList.fulfilled, (state, action) => {
        state.geoList = action.payload;
        state.geoListPending = false;
      })
      .addCase(fetchGeosList.rejected, (state) => {
        state.geoListPending = false;
      })

      .addCase(fetchUsersList.pending, (state) => {
        state.usersList = [];
      })
      .addCase(fetchUsersList.fulfilled, (state, action) => {
        state.usersList = action.payload;
      })
      .addCase(fetchUsersList.rejected, (state) => {
        state.usersList = [];
      })

      .addCase(fetchTechnicalUsersList.pending, (state) => {
        state.technicalUsersList = [];
        state.technicalUsersListPending = true;
      })
      .addCase(fetchTechnicalUsersList.fulfilled, (state, { payload }) => {
        const { results, count } = payload;

        state.technicalUsersList = results;
        state.technicalUsersCount = count;
        state.technicalUsersListPending = false;
      })
      .addCase(fetchTechnicalUsersList.rejected, (state, { error }) => {
        const { message } = error;

        state.technicalUsersListPending = message === ABORTED_ERROR;
        state.technicalUsersList = [];

        if (message !== ABORTED_ERROR) {
          state.technicalUsersCount = 0;
        }
      })

      .addCase(fetchOSList.pending, (state) => {
        state.OSList = [];
      })
      .addCase(fetchOSList.fulfilled, (state, action) => {
        state.OSList = action.payload.details;
      })
      .addCase(fetchOSList.rejected, (state, action) => {
        state.OSList = [];
      });
  },
});

export default globalDataSlice.reducer;

export const {
  setIsCollapsedSidebar,
  setExitPopup,
  setPageSizeValue,
  setAdaptiveMenu,
} = globalDataSlice.actions;

export const globalDataSelector = (state) => state.globalData;
