import serviceReviewImg from "../../assets/img/FAQ_service_review.png";
import createFlowImg from "../../assets/img/FAQ_create_flow.png";
import fbPixelImg from "../../assets/img/FAQ_fb_pixel.png";
import trackerIntegrationImg from "../../assets/img/FAQ_tracker_integration.png";

import FAQ_lesson1 from "../../assets/img/FAQ_lesson1.png";
import FAQ_lesson2 from "../../assets/img/FAQ_lesson2.png";
import FAQ_lesson3 from "../../assets/img/FAQ_lesson3.png";
import FAQ_lesson4 from "../../assets/img/FAQ_lesson5.png";
import FAQ_lesson5 from "../../assets/img/FAQ_lesson5.png";
import FAQ_lesson6 from "../../assets/img/FAQ_lesson6.png";
import FAQ_lesson7 from "../../assets/img/FAQ_lesson7.png";

export const faq = {
  name: "Документация",
  desc: "Полезная информация, а также инструкции о том, как работает сервис, нововведения и основные флоу.",
  linksList: [
    {
      name: "Обзор сервиса",
      link: "https://stingy-trout-0f1.notion.site/WWA-303d7c630861460a8856e43a3e41e1c6?pvs=4",
      desc: "wwa-ios.com - это сервис от компании Wild Wild Apps, который позволяет запускать web to app траффик через Facebook на igaming IOS приложения. ",
      image: serviceReviewImg,
    },
    {
      name: "Создание потока",
      link: "https://stingy-trout-0f1.notion.site/WWA-fd9f67e383d742efae47ae8ecd1db81e?pvs=4",
      desc: "Дает возможность получить домен, настроить клоаку, выбрать один из существующих прелендингов или загрузить свой, а также выбрать приложение для залива",
      image: createFlowImg,
    },
    {
      name: "Интеграция с трекером",
      link: "https://stingy-trout-0f1.notion.site/WWA-c15ff07456714895bcba8feb088bd709?pvs=4",
      desc: "После настройки потока вам будут выданы ссылки для интеграции вашего трекера, например, Keitaro, и нашего сервиса, чтобы получать статистику и взаимодействовать с Facebook.",
      image: fbPixelImg,
    },
    {
      name: "FB PIXEL",
      link: "https://stingy-trout-0f1.notion.site/WWA-FB-PIXEL-6b6fd781231b4e909e27728e0e5a6750?pvs=4",
      desc: "После того, как вы провели необходимые действия в Facebook и получили токен, вам нужно добавить Pixel-Token В разделе Pixel API",
      image: trackerIntegrationImg,
    },
  ],
};

export const lessons = {
  name: "Уроки",
  desc: "Короткий курс видеороликов, знакомящий с основными функциями",
  linksList: [
    {
      name: "Авторизация и баланс",
      link: "https://www.youtube.com/watch?v=5LF8gC2E8m4&list=PL25QexTdjPU_tsDX2789kBTB8ltKW2eH6&index=1",
      desc: "",
      image: FAQ_lesson1,
    },
    {
      name: "Потоки и редиректы",
      link: "https://www.youtube.com/watch?v=RM2YQEz6kH8&list=PL25QexTdjPU_tsDX2789kBTB8ltKW2eH6&index=2",
      desc: "",
      image: FAQ_lesson2,
    },
    {
      name: "Получение ссылки для запуска",
      link: "https://www.youtube.com/watch?v=UbvFVgkt9sc&list=PL25QexTdjPU_tsDX2789kBTB8ltKW2eH6&index=3",
      desc: "",
      image: FAQ_lesson3,
    },
    {
      name: "Пиксель",
      link: "https://www.youtube.com/watch?v=5n7pjw5oNys&list=PL25QexTdjPU_tsDX2789kBTB8ltKW2eH6&index=4",
      desc: "",
      image: FAQ_lesson4,
    },
    {
      name: "Создание пикселя БМ",
      link: "https://www.youtube.com/watch?v=OspbOrWppic&list=PL25QexTdjPU_tsDX2789kBTB8ltKW2eH6&index=5",
      desc: "",
      image: FAQ_lesson5,
    },
    {
      name: "Дополнение Пиксели",
      link: "https://www.youtube.com/watch?v=VHLYje9aNqQ&list=PL25QexTdjPU_tsDX2789kBTB8ltKW2eH6&index=6",
      desc: "",
      image: FAQ_lesson6,
    },
    {
      name: "Проверка валидности пиксель токена",
      link: "https://www.youtube.com/watch?v=-YwUAKD2fPo&list=PL25QexTdjPU_tsDX2789kBTB8ltKW2eH6&index=7",
      desc: "",
      image: FAQ_lesson7,
    },
  ],
};
