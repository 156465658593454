import axios from "axios";
import { getAccessToken, getRefreshToken } from "../storage/storage";
import { logoutHandler } from "./helpers/handlers";
import { invalidToken } from "../utils/vars/errorMessages";

const DEVELOPING_MODE = false;

export const getApiUrl = () => {
  if (DEVELOPING_MODE) {
    return process.env.REACT_APP_STAGE_API_URL;
  } else {
    return process.env.REACT_APP_PROD_API_URL;
  }
};

export const API_URL = process.env.REACT_APP_STAGE_API_URL;

const $api = axios.create({
  baseURL: getApiUrl(),
});

$api.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${getAccessToken()}`;
  return config;
});

$api.interceptors.response.use(
  (config) => {
    return config;
  },
  async (error) => {
    const originalRequest = error.config;
    if (
      error?.response?.status === 401 &&
      originalRequest &&
      !originalRequest._isRetry
    ) {
      originalRequest._isRetry = true;
      try {
        const response = await axios.post(
          `${getApiUrl()}/users/token/refresh/`,
          {
            refresh: getRefreshToken(),
          },
        );
        const { access, refresh } = response.data;
        if (access && refresh) {
          localStorage.setItem("accessToken", access);
          localStorage.setItem("refreshToken", refresh);
          return $api.request(originalRequest);
        } else {
          logoutHandler();
        }
      } catch (e) {
        logoutHandler();
      }
    } else {
      if (error?.response?.data?.detail === invalidToken) {
        logoutHandler();
      }
      throw error;
    }
  },

  // (config) => {
  //   return config;
  // },
  // async (error) => {
  //   const originalRequest = error.config;
  //   if (
  //     error.response &&
  //     error.response.status === 401 &&
  //     originalRequest &&
  //     !originalRequest._isRetry
  //   ) {
  //     originalRequest._isRetry = true;
  //     try {
  //       const response = await axios.post(
  //         `${getApiUrl()}/users/token/refresh/`,
  //         {
  //           refresh: getRefreshToken(),
  //         },
  //       );
  //       localStorage.setItem("accessToken", response.data.access);
  //       localStorage.setItem("refreshToken", response.data.refresh);
  //       return $api.request(originalRequest);
  //     } catch (e) {
  //       logoutHandler();
  //     }
  //   }
  //   throw error;
  // },
);

export default $api;
