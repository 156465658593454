export const noDomains = "Нет свободных доменов";

export const notEnoughMoney =
  "Недостаточная сумма на балансе для создания потока. Пополните баланс.";

export const flowCreateLimit =
  "Вы достигли дневного лимита по количеству новых потоков. Чтобы расширить лимит обратитесь в саппорт";

export const pixelIdExists =
  "Для этого пользователя уже существует пиксель с таким ID.";

export const noIndexHtml = "Файл index.html не найден в архиве.";

export const sub7Exists =
  "Для такого юзера уже существует редиректор с таким sub7";

export const chosenSamePrelanding = "Вы выбрали тот же прелендинг";

export const prelandingFileCyrillicError =
  "Имя файла не должно содержать символы кириллицы.";

export const noIndexHtmlFormikError =
  "Файл index.html не был найден в корне .zip файла";

export const notRedirectorOwnerError = "Вы не являетесь владельцем редиректора";

export const noActiveAccountFound =
  "No active account found with the given credentials";

export const invalidToken = "Token is invalid";
