import { useSelector } from "react-redux";
import { prelandingSelector } from "../../../../../../../../redux/slices/prelandingSlice";
import s from "../../../../../../Prelandings/EditPrelanding/EditCustom/editCustom.module.scss";
import GlobalSvgSelector from "../../../../../../../../components/GlobalSvgSelector/GlobalSvgSelector";
import PrimaryBtn from "../../../../../../../../components/UiKit/Buttons/PrimaryBorderBtn/PrimaryBorderBtn";
import React from "react";

const EditCustom = ({ formik, setContent }) => {
  const { editPrelandPending } = useSelector(prelandingSelector);

  const { prelanding_file, name } = formik.values;

  let archiveName =
    prelanding_file?.name || prelanding_file?.split("/")?.pop() || "Загрузить";

  return (
    <form onSubmit={formik.handleSubmit} className={s.uploadPrelandWrapper}>
      <div className={s.formContentWrapper}>
        <div className={s.formFieldsWrapper}>
          <div className={s.fieldWrapper}>
            <p className={s.fieldName}>Название</p>
            <input
              type="text"
              name="name"
              className={`${s.textInput} ${
                formik.touched.name && formik.errors.name ? s.error : ""
              }`}
              placeholder="Название прелендинга"
              value={name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.name && formik.errors.name ? (
              <div className={s.error}>{formik.errors.name}</div>
            ) : null}
          </div>
          <div className={s.fieldWrapper}>
            <label
              htmlFor="upload-button"
              className={`${s.uploadPrelandBtn} ${
                formik.touched.prelanding_file && formik.errors.prelanding_file
                  ? s.zipArchiveError
                  : ""
              }`}
            >
              <GlobalSvgSelector id="archive-download-icon" />
              <span>{archiveName}</span>
            </label>
            {formik.touched.prelanding_file && formik.errors.prelanding_file ? (
              <div className={s.error}>{formik.errors.prelanding_file}</div>
            ) : null}
            <input
              id="upload-button"
              type="file"
              style={{ display: "none" }}
              onChange={(e) => {
                if (e.target.files[0]) {
                  formik.setFieldValue("prelanding_file", e.target.files[0]);
                  console.log("e.target.files[0]", e.target.files[0]);
                }
              }}
              accept=".zip"
            />
          </div>
        </div>
      </div>
      <div className={s.buttons}>
        <button
          className={s.whiteBorderBtn}
          type="button"
          onClick={() => {
            setContent("choose");
          }}
        >
          Отменить
        </button>
        <PrimaryBtn
          text="Сохранить"
          submit={true}
          pending={editPrelandPending}
          className={s.customPrimaryBtn}
        />
      </div>
    </form>
  );
};

export default EditCustom;
