import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import {params} from "../utils/vars/params";

const useGetSearchParams = () => {
    const [searchParams] = useSearchParams();

    const paramsObject = useMemo(() => {
        const result = {};
        Object.keys(params).forEach(key => {
            result[key] = searchParams.get(params[key]) || '';
        });
        return result;
    }, [searchParams, params]);

    return paramsObject;
};

export default useGetSearchParams;
