import { useSelector } from "react-redux";
import { financeSelector } from "../../../../redux/slices/financeSlice";
import { globalDataSelector } from "../../../../redux/slices/globalDataSlice";
import s from "./confirmCreateFlowPopup.module.scss";
import React, { useEffect } from "react";
import GlobalSvgSelector from "../../../../components/GlobalSvgSelector/GlobalSvgSelector";
import CancelButton from "../../../../components/UiKit/Buttons/CancelBtn/CancelBtn";
import PrimaryBtn from "../../../../components/UiKit/Buttons/PrimaryBorderBtn/PrimaryBorderBtn";

const ConfirmCreateFlowPopup = ({
  active,
  setActive,
  action,
  description,
  title,
  confirmBtnText,
  forcedClose = true,
  icon,
}) => {
  const { topUpBalanceIsActive } = useSelector(financeSelector);
  const { exitPopup, isCollapsedSidebar } = useSelector(globalDataSelector);

  const handleClosePopup = (event) => {
    if (event.target.classList.contains(s.popupWrapper)) {
      setActive(false);
    }
  };

  useEffect(() => {
    if ((forcedClose && topUpBalanceIsActive) || (forcedClose && exitPopup)) {
      setActive(false);
    }
  }, [topUpBalanceIsActive, exitPopup]);

  const onCreateHandler = () => {
    setActive(false);
  };

  useEffect(() => {
    const handleEscClose = (e) => {
      if (e.key === "Escape") {
        setActive(false);
        if (document.activeElement) {
          document.activeElement.blur();
        }
      }
    };
    const handleEnterPress = (e) => {
      if (e.key === "Enter") {
        if (document.activeElement) {
          document.activeElement.blur();
        }
        onCreateHandler();
        action();
      }
    };
    if (active) {
      document.addEventListener("keydown", handleEscClose);
      document.addEventListener("keydown", handleEnterPress);
    }

    return () => {
      document.removeEventListener("keydown", handleEscClose);
      document.removeEventListener("keydown", handleEnterPress);
    };
  }, [active]);

  return (
    <div
      // onSubmit={handleSubmit}
      className={`${s.popupWrapper} ${active ? s.active : ""} ${isCollapsedSidebar === "true" ? s.collapsed : ""}`}
      onClick={handleClosePopup}
    >
      <div className={active ? `${s.popup} ${s.active}` : s.popup}>
        <button
          onClick={() => setActive(false)}
          className={s.closePopup}
          type="button"
        >
          <GlobalSvgSelector id="close-popup" />
        </button>
        <div className={s.bodyContainer}>
          <span className={s.iconWrapper}>{icon}</span>
          <p className={s.title}>{title}</p>
          <p className={s.description}>{description}</p>
        </div>
        <div className={s.footer}>
          <CancelButton
            text="Отменить"
            action={() => setActive(false)}
            width={120}
          />
          <PrimaryBtn
            text={confirmBtnText}
            submit={true}
            action={onCreateHandler}
          />
        </div>
      </div>
    </div>
  );
};

export default ConfirmCreateFlowPopup;
