import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { token } from "../../localStorage/localStorage";
import axios from "axios";
import $api from "../../api/url";
import { setSubDomain } from "./applicationSlice";
import { errorMessage } from "../../api/helpers/scripts";
import { ABORTED_ERROR } from "../../utils/vars/staticVariables";

export const fetchPixelApi = createAsyncThunk(
  "pixelApi/fetchPixelApi",
  async (params, { signal, rejectWithValue }) => {
    try {
      const copiedParams = new URLSearchParams(params);

      // if (status?.value) {
      //   url += `&status=${status.value}`;
      // }
      // if (handleSupport?.value) {
      //   url += `&is_support_handled=${handleSupport.value}`;
      // }

      const { data } = await $api.get("/pixel/pixel-api?" + copiedParams, {
        signal,
      });
      return data;
    } catch (error) {
      let err = errorMessage(error);

      if (axios.isCancel(error)) {
        return rejectWithValue();
      }

      throw new Error(err);
    }
  },
);

// export const editPixelApiItem = createAsyncThunk(
//   "pixelApi/editPixelApiItem",
//   async ({ successAction, id, updatedData, onErrorAction }) => {
//     try {
//       const response = await $api.put(`/pixel/pixel-api/${id}/`, updatedData);
//       successAction();
//     } catch (error) {
//       const errorMessage = error.response.data.non_field_errors[0];
//
//       if (
//         errorMessage ===
//         "Для этого пользователя уже существует пиксель с таким ID."
//       ) {
//         onErrorAction("Pixel с таким ID уже существует");
//         throw new Error(errorMessage);
//       } else {
//         onErrorAction("Произошла ошибка");
//         throw error;
//       }
//     }
//   },
// );

// відповідає за чекбокс в піксель апі для сапортів
export const switchSupportHandle = createAsyncThunk(
  "pixelApi/switchSupportHandle",
  async (dataObj) => {
    try {
      const response = await $api.post(`/pixel/handle-pixels-api/`, dataObj);
      const { data, status } = response;
      return { data, status };
      // const { data } = await $api.post(`/pixel/handle-pixels-api/`, dataObj);
      // return data;
    } catch (error) {
      throw error;
    }
  },
);

export const checkPixelTokenValid = createAsyncThunk(
  "pixelApi/checkPixelTokenValid",
  async (token) => {
    try {
      const response = await $api.get(
        `/pixel/handle-pixels-api?token=${token}`,
      );

      const { data, status } = response;

      return { data, status };
    } catch (error) {
      const { data, status } = error.response;

      return { data, status };
    }
  },
);

export const editPixelApiItem = createAsyncThunk(
  "pixelApi/editPixelApiItem",
  async ({ pixelApiObj, id }) => {
    try {
      const response = await $api.put(`/pixel/pixel-api/${id}`, pixelApiObj);
      const { data, status } = response;

      return { data, status };
    } catch (error) {
      const { data, status } = error.response;

      return { data, status };
    }
  },
);

export const postPixelApiItem = createAsyncThunk(
  "pixelApi/postPixelApiItem",
  async (pixelApiObj) => {
    try {
      const response = await $api.post("/pixel/pixel-api/", pixelApiObj);
      const { data, status } = response;

      return { data, status };
    } catch (error) {
      const { data, status } = error.response;

      return { data, status };
    }
  },
);

export const checkAllTokens = createAsyncThunk(
  "pixelApi/checkAllTokens",
  async () => {
    try {
      const { data } = await $api.get(`/pixel/check-all-valid-token/`);

      return data?.detail
    } catch (error) {
      const { data, status } = error.response;

      return { data, status };
    }
  },
);

export const deletePixelApiItem = createAsyncThunk(
  "pixelApi/deletePixelApiItem",
  async (id) => {
    try {
      const response = await $api.delete(`/pixel/pixel-api/${id}/`);
      const { data, status } = response;

      return { data, status };
    } catch (error) {
      const { data, status } = error.response;

      return { data, status };
    }
  },
);

export const fetchPixelApiItem = createAsyncThunk(
  "pixelApi/fetchPixelApiItem",
  async (id) => {
    try {
      const { data } = await $api.get(`/pixel/pixel-api/${id}/`);

      return data;
    } catch (error) {
      const { data, status } = error.response;

      return { data, status };
    }
  },
);

const pixelApiSlice = createSlice({
  name: "pixelApi",
  initialState: {
    pixelApiList: [],
    pixelApiListPending: false,
    error: null,
    pixelApiItem: null,
    pixelApiItemPending: true,
    editPixelApiPending: false,
    totalCount: 0,
    currentPage: 1,
    createLoading: false,
    isValidPixelApiTokens: true,
    checkPixelApiPending: false,

    createPixelApiPending: false,
  },
  reducers: {
    pixelApiSetCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPixelApi.pending, (state) => {
        state.pixelApiListPending = true;
        state.pixelApiList = [];
      })
      .addCase(fetchPixelApi.fulfilled, (state, { payload }) => {
        const { results, count } = payload;

        state.pixelApiList = results;
        state.totalCount = count;
        state.pixelApiListPending = false;
      })
      .addCase(fetchPixelApi.rejected, (state, { error }) => {
        const { message } = error;

        state.pixelApiListPending = message === ABORTED_ERROR;
        state.pixelApiList = [];
        if (message !== ABORTED_ERROR) {
          state.totalCount = 0;
        }
      })

      .addCase(fetchPixelApiItem.pending, (state) => {
        state.pixelApiItemPending = true;
        state.pixelApiItem = null;
      })
      .addCase(fetchPixelApiItem.fulfilled, (state, action) => {
        state.pixelApiItem = action.payload;
        state.pixelApiItemPending = false;
      })
      .addCase(fetchPixelApiItem.rejected, (state) => {
        state.pixelApiItem = null;
        state.pixelApiItemPending = false;
      })

      .addCase(postPixelApiItem.pending, (state) => {
        state.createPixelApiPending = true;
      })
      .addCase(postPixelApiItem.fulfilled, (state, action) => {
        state.createPixelApiPending = false;
      })
      .addCase(postPixelApiItem.rejected, (state) => {
        state.createPixelApiPending = false;
      })

      .addCase(editPixelApiItem.pending, (state) => {
        state.editPixelApiPending = true;
      })
      .addCase(editPixelApiItem.fulfilled, (state, action) => {
        state.editPixelApiPending = false;
      })
      .addCase(editPixelApiItem.rejected, (state) => {
        state.editPixelApiPending = false;
      })

      .addCase(checkAllTokens.fulfilled, (state, action) => {
        state.isValidPixelApiTokens = action.payload;
      })

      .addCase(checkPixelTokenValid.pending, (state) => {
        state.checkPixelApiPending = true;
      })
      .addCase(checkPixelTokenValid.fulfilled, (state) => {
        state.checkPixelApiPending = false;
      })
      .addCase(checkPixelTokenValid.rejected, (state) => {
        state.checkPixelApiPending = false;
      });
  },
});

export const { pixelApiSetCurrentPage } = pixelApiSlice.actions;

export const pixelApiSelector = (state) => state.pixelApi;

export default pixelApiSlice.reducer;
