import s from "./percentageBtn.module.scss";
import GlobalSvgSelector from "../../../../components/GlobalSvgSelector/GlobalSvgSelector";
import React from "react";

const PercentageBtn = ({ percentageValue, onChange, hasError, isAlive }) => {
  function handleKeyDown(event) {
    const forbiddenKeys = ["e", ".", "-", ","];

    if (forbiddenKeys.includes(event.key)) {
      event.preventDefault();
    }
  }

  function handleInputChange(event) {
    let inputValue = event.target.value;

    if (/^0+/.test(inputValue) && inputValue.length > 1) {
      inputValue = inputValue.replace(/^0+/, "");
    }

    if (inputValue.length > 3) {
      inputValue = inputValue.slice(0, 3);
    }

    const parsedValue = inputValue === "" ? "" : parseInt(inputValue, 10);

    if (parsedValue === 0 && percentageValue === 0) {
      onChange(null);
      onChange(0);
      return;
    }

    if (parsedValue === 0) {
      onChange(0);
    }

    const isValidInput = /^[1-9]\d?$|^100$/.test(parsedValue);

    if (isValidInput) {
      onChange(parsedValue);
    }

    if (inputValue === "") {
      onChange(0);
    }
  }

  return (
    <div
      className={`${s.percentageWrapper} ${isAlive === false ? s.disabled : ""}`}
    >
      <div className={`${s.percentageInputWrapper}`}>
        <input
          className={`${s.input} ${hasError ? s.error : ""}`}
          type="number"
          placeholder="Процент"
          onChange={handleInputChange}
          value={percentageValue === null ? "" : Math.round(percentageValue)}
          onKeyDown={handleKeyDown}
        />
        {percentageValue !== null && <span className={s.percentSign}>%</span>}
        <div className={s.percentageButtonsWrapper}>
          <button
            type="button"
            className={s.percentageBtn}
            onClick={() => {
              if (percentageValue < 100) {
                onChange(percentageValue + 1);
              }
            }}
          >
            <GlobalSvgSelector id="percentage-inc" />
          </button>
          <button
            type="button"
            className={s.percentageBtn}
            onClick={() => {
              if (percentageValue <= 1) {
                onChange(0);
              } else {
                onChange(percentageValue - 1);
              }
            }}
          >
            <GlobalSvgSelector id="percentage-desc" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default PercentageBtn;
