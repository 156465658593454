import React, { useEffect, useRef, useState } from "react";
import s from "./flowTableAppFieldTooltip.module.scss";

const FlowTableAppTooltip = ({ children, uniqueApplications }) => {
  const [visible, setVisible] = useState(false);
  const tooltipRef = useRef(null);

  // Відображення або приховування тултипу
  const toggleTooltip = () => setVisible(!visible);

  // Закриття тултипу при кліку поза ним
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
        setVisible(false);
      }
    };

    if (visible) {
      document.addEventListener("click", handleOutsideClick);
    } else {
      document.removeEventListener("click", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [visible]);

  // Вибір подій в залежності від пристрою
  const eventHandlers = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
    ? { onClick: toggleTooltip }
    : {
        onMouseEnter: () => setVisible(true),
        onMouseLeave: () => setVisible(false),
      };

  return (
    <span className={s.tooltipContainer} {...eventHandlers} ref={tooltipRef}>
      {children}
      {visible && (
        <div className={s.tooltipContent}>
          {uniqueApplications.map(({ name, icon }, index) => (
            <div className={s.appValue} key={index}>
              <span
                className={s.iconWrapper}
                style={{
                  backgroundImage: icon ? `url(${icon})` : "none",
                }}
              ></span>
              <span>{name}</span>
            </div>
          ))}
        </div>
      )}
    </span>
  );
};

export default FlowTableAppTooltip;
