// import React from "react";
// import { Link, Outlet } from "react-router-dom";
// import AuthBackground from "../../components/UiKit/AuthBackground/AuthBackground";
// import GlobalSvgSelector from "../../components/GlobalSvgSelector/GlobalSvgSelector";
// import s from "./authLayout.module.scss";
// import bgImgBL from "../../assets/";
//
// const AuthLayout = () => {
//   return (
//     <div className={s.authWrapper}>
//       <img src="" alt="" />
//
//       <div className={s.logoWrapper}>
//         <Link to="/auth/login">
//           <GlobalSvgSelector id="logo-icon" />
//         </Link>
//       </div>
//
//       <Outlet />
//
//       <div className={s.companyRightsWrapper}>
//         WWA © 2024. All rights reserved.
//       </div>
//     </div>
//   );
// };
//
// export default AuthLayout;
//
import React from "react";
import { Link, Outlet } from "react-router-dom";
import AuthBackground from "../../components/UiKit/AuthBackground/AuthBackground";
import GlobalSvgSelector from "../../components/GlobalSvgSelector/GlobalSvgSelector";
import s from "./authLayout.module.scss";
import bgImgBL from "../../assets/img/blurredAuthBgBL.png";
// import bgImgTR from "../../assets/img/blurredAuthBgTR.png";
import blurredAuthBgBL from "../../assets/img/blurredAuthBgBL.png";
import blurredAuthBgTR from "../../assets/img/blurredAuthBgTR.png";
import iphonesImg from "../../assets/img/iphonesAuthBgImg.png";

const AuthLayout = () => {
  return (
    <div className={s.authWrapper}>
      <img src={blurredAuthBgTR} className={s.bgImgBL} />
      <img src={blurredAuthBgBL} className={s.bgImgTR} />
      <div className={s.authContentWrapper}>
        <div className={s.contentContainer}>
          <img src={iphonesImg} className={s.iphonesImg} draggable="false" />
          <Outlet />
        </div>
        <div className={s.companyRightsWrapper}>
          WWA © 2024. All rights reserved.
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;
