import { useCallback } from "react";

const useSetSearchParams = (setSearchParams) => {
  return useCallback(
    (param, value, remove) => {
      if (remove) {
        setSearchParams((_params) => {
          _params.delete(param);
          return _params;
        });
      } else {
        setSearchParams((_params) => {
          _params.set(param, value);
          return _params;
        });
      }
    },
    [setSearchParams],
  );
};

export default useSetSearchParams;
