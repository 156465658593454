export const distributePercentages = (arr) => {
  const totalPercentage = 100;
  const numObjects = arr.length;
  const basePercentage = Math.floor(totalPercentage / numObjects);
  const remainingPercentage = totalPercentage % numObjects;

  const distributedArr = arr.map((obj, index) => ({
    ...obj,
    percentage: basePercentage,
  }));

  for (let i = 0; i < remainingPercentage; i++) {
    distributedArr[i].percentage += 1;
  }

  return distributedArr;
};
