import React from "react";
import Select from "react-select";
import { SelectStyles } from "./themes";

const ReactSelectTheme = ({
  value,
  options,
  action,
  isMulti,
  placeholder,
  darkTheme,
  isClearable = false,
  isInvalid,
}) => (
  <Select
    value={value}
    isClearable={isClearable}
    options={options}
    onChange={(o) => {
      action(o);
    }}
    isMulti={isMulti}
    placeholder={placeholder}
    styles={SelectStyles}
    isInvalid={isInvalid}
    darkTheme={darkTheme}
    noOptionsMessage={() => "Нет опций"}
  />
);

export default ReactSelectTheme;
