import { Navigate, Route, Routes } from "react-router-dom";
import MainLayout from "../Layout/MainLayout/MainLayout";
import AppCatalog from "../pages/Authorized/AppCatalog/AppCatalog";
// import CreateFlow from "../components/Flows/CreateFlow/CreateFlow";
// import ClicksStats from "../components/Statistics/ClicksStats/ClicksStats";
// import PixelApi from "../components/PixelApi/PixelApi";
import Documentation from "../pages/Authorized/Documentation/Documentation";
// import Prelandings from "../components/Prelandings/Prelandings";
// import AllRedirectors from "../components/AllRedirectors/AllRedirectors";
import AuthLayout from "../Layout/AuthLayout/AuthLayout";
import Auth from "../pages/Unauthorized/Auth/Auth";
// import Flows from "../components/Flows/Flows";
import CreateNewPixelApi from "../components/PixelApi/CreateNewPixelApi/CreateNewPixelApi";
// import EditPixelApi from "../components/PixelApi/EditPixelApi/EditPixelApi";
import EditRedirector from "../components/AllRedirectors/EditRedirector/EditRedirector";
import CreateRedirector from "../components/AllRedirectors/CreateRedirector/CreateRedirector";
// import CommonStats from "../components/Statistics/CommonStats/CommonStats";
import EditFlowSettings from "../components/Flows/EditFlowSettings/EditFlowSettings";
// import CreatePrelanding from "../components/Prelandings/CreatePrelanding/CreatePrelanding";
import Preview from "../components/Prelandings/Preview/Preview";
import PrelandLayout from "../Layout/PrelandLayout/PrelandLayout";
// import EditPrelanding from "../components/Prelandings/EditPrelanding/EditPrelanding";
// import GeoAppStats from "../components/Statistics/GeoAppStats/GeoAppStats";
// import Transactions from "../components/Finances/Transactions/Transactions";
import Balances from "../pages/AdminAccess/Balances/Balances";
import { getIsAllowedStatisticsAccess, getIsStaff } from "../storage/storage";
// import CloacaStats from "../components/Statistics/CloacaStats/CloacaStats";
// import UsersStats from "../components/Statistics/UsersStats/UsersStats";
// import AppPriority from "../components/AppPriority/AppPriority";
import Account from "../pages/Authorized/Account/Account";
import ResetEmailConfirm from "../pages/Unauthorized/ResetPassword/ResetEmailConfirm/ResetEmailConfirm";
import ResetNewPassword from "../pages/Unauthorized/ResetPassword/ResetNewPassword/ResetNewPassword";
import RegisterForm from "../pages/Unauthorized/Register/RegisterForm/RegisterForm";
import RegisterEmailConfirm from "../pages/Unauthorized/Register/RegisterEmailConfirm/RegisterEmailConfirm";
import RegisterSuccess from "../pages/Unauthorized/Register/RegisterSuccess/RegisterSuccess";
import RegisterError from "../pages/Unauthorized/Register/RegisterError/RegisterError";
import ResetEmailForm from "../pages/Unauthorized/ResetPassword/ResetEmailForm/ResetEmailForm";
import ResetError from "../pages/Unauthorized/ResetPassword/ResetError/ResetError";
import Flows from "../pages/Authorized/Flows/Flows";
import PixelApi from "../pages/Authorized/PixelApi/PixelApi";
import ClicksStats from "../pages/Authorized/Statistics/ClicksStats/ClicksStats";
import CloacaStats from "../pages/Authorized/Statistics/CloacaStats/CloacaStats";
import CommonStats from "../pages/Authorized/Statistics/CommonStats/CommonStats";
import GeoAppStats from "../pages/AdminAccess/Statistics/GeoAppStats/GeoAppStats";
import UsersStats from "../pages/AdminAccess/Statistics/UsersStats/UsersStats";
// import Prelandings from "../pages/Prelandings/Prelandings";
import Transactions from "../pages/Authorized/Transactions/Transactions";
import EditPixelApi from "../pages/Authorized/PixelApi/EditPixelApi/EditPixelApi";
import Prelandings from "../pages/Authorized/Prelandings/Prelandings";
import CreatePrelanding from "../pages/Authorized/Prelandings/CreatePrelanding/CreatePrelanding";
import EditPrelanding from "../pages/Authorized/Prelandings/EditPrelanding/EditPrelanding";
import AllRedirectors from "../pages/Authorized/AllRedirectors/AllRedirectors";
import CreateFlow from "../pages/Authorized/CreateFlow/CreateFlow";
import FlowSettings from "../pages/Authorized/FlowSettings/FlowSettings";
import NewAuth from "../pages/Unauthorized/NewAuth/NewAuth";
import Prelanding from "../pages/Unauthorized/Prelanding/Prelanding";
import Page404 from "../pages/Authorized/Page404/Page404";
import ChangePassword from "../pages/Authorized/ChangePassword/ChangePassword";
import Management from "../pages/AdminAccess/Management/Management";
// import PixelApi from "../components/PixelApi/PixelApi";

export const useRoutes = (isLogged) => {
  if (isLogged) {
    return (
      <Routes>
        <Route path="/" element={<PrelandLayout />}>
          <Route path="prelanding/preview" element={<Prelanding />} />
        </Route>

        <Route path="/" element={<MainLayout />}>
          <Route index element={<Navigate to="/app-catalog" replace />} />

          <Route path="*" element={<Page404 />} />
          <Route path="app-catalog" element={<AppCatalog />} />

          <Route path="my-flows/create-flow" element={<CreateFlow />} />
          <Route path="my-flows/edit/:id" element={<FlowSettings />} />
          {/*<Route path="my-flows/edit/:id" element={<EditFlowSettings />} />*/}
          <Route path="my-flows" element={<Flows />} />

          <Route path="stats/clicks" element={<ClicksStats />} />
          <Route path="stats/common" element={<CommonStats />} />
          <Route path="stats/cloaca" element={<CloacaStats />} />

          {(getIsStaff() || getIsAllowedStatisticsAccess()) && (
            <>
              <Route path="stats/geo+app" element={<GeoAppStats />} />
              <Route path="stats/users" element={<UsersStats />} />
            </>
          )}
          {getIsStaff() && (
            <>
              <Route path="balances" element={<Balances />} />
            </>
          )}

          {/*{getIsStaff() && (*/}
          {/*  <>*/}
          {/*    <Route path="stats/geo+app" element={<GeoAppStats />} />*/}
          {/*    <Route path="stats/users" element={<UsersStats />} />*/}
          {/*    <Route path="balances" element={<Balances />} />*/}
          {/*  </>*/}
          {/*)}*/}

          {/*<Route path="app-priority" element={<AppPriority />} />*/}

          {/*<Route path="account" element={<Account />}/>*/}

          <Route path="account" element={<ChangePassword />} />

          <Route path="management" element={<Management />} />

          <Route path="pixel-api" element={<PixelApi />} />
          {/*<Route path="pixel-api/create-new" element={<CreateNewPixelApi />} />*/}
          <Route path="pixel-api/edit/:id" element={<EditPixelApi />} />
          <Route path="documentation" element={<Documentation />} />
          <Route path="transactions" element={<Transactions />} />

          <Route path="prelandings" element={<Prelandings />} />
          <Route path="prelandings/create" element={<CreatePrelanding />} />
          <Route path="prelandings/edit/:id" element={<EditPrelanding />} />

          <Route path="all-redirectors" element={<AllRedirectors />} />
          {/*<Route path="all-redirectors/create" element={<CreateRedirector />} />*/}
          {/*<Route path="all-redirectors/edit/:id" element={<EditRedirector />} />*/}
        </Route>
      </Routes>
    );
  }
  return (
    <Routes>
      <Route path="/" element={<AuthLayout />}>
        {/*<Route path="reset/email-form" element={<ResetEmailForm />} />*/}
        {/*<Route path="reset/email-confirm" element={<ResetEmailConfirm />} />*/}
        {/*<Route path="reset/new-password/*" element={<ResetNewPassword />} />*/}
        {/*<Route path="reset/error" element={<ResetError />} />*/}

        {/*<Route path="register/form" element={<RegisterForm />} />*/}
        {/*<Route path="register/email-confirm" element={<RegisterEmailConfirm />} />*/}
        {/*<Route path="register/success" element={<RegisterSuccess />} />*/}
        {/*<Route path="register/error" element={<RegisterError />} />*/}

        <Route path="auth/login" element={<NewAuth />} />
        <Route index element={<Navigate to="/auth/login" replace />} />
        <Route path="*" element={<Navigate to="/auth/login" replace />} />
      </Route>

      <Route path="/" element={<PrelandLayout />}>
        <Route path="prelanding/preview" element={<Prelanding />} />
      </Route>
    </Routes>
  );
};
