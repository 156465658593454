import { useMemo } from "react";

export const useSearchParamsOptions = (searchParams, paramNames) => {
  return useMemo(() => {
    return paramNames.map(
      (paramName) =>
        searchParams
          .get(paramName)
          ?.split(",")
          .filter((value) => value !== "")
          .map((value) => ({ label: value, value })) || [],
    );
  }, [searchParams, paramNames]);
};

export const useSelectOptions = (
  data,
  labelKey = "name",
  valueKey = "name",
) => {
  return useMemo(() => {
    return data.map((item) => ({
      label: item[labelKey],
      value: item[valueKey],
    }));
  }, [data, labelKey, valueKey]);
};
