import React, { useRef, useState } from "react";
import s from "./changePassword.module.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  globalDataSelector,
  setAdaptiveMenu,
} from "../../../redux/slices/globalDataSlice";
import { setTopUpBalanceActive } from "../../../redux/slices/financeSlice";
import GlobalSvgSelector from "../../../components/GlobalSvgSelector/GlobalSvgSelector";
import { getUser } from "../../../storage/storage";
import { useFormik } from "formik";
import * as Yup from "yup";
import { authSelector, changePassword } from "../../../redux/slices/authSlice";
import PrimaryBtn from "../../../components/UiKit/Buttons/PrimaryBorderBtn/PrimaryBorderBtn";
import { logoutHandler } from "../../../api/helpers/handlers";
import { Link } from "react-router-dom";

const ChangePassword = () => {
  const [isShowedOldPassword, setShowedOldPassword] = useState(false);
  const [isShowedNewPassword1, setShowedNewPassword1] = useState(false);
  const [isShowedNewPassword2, setShowedNewPassword2] = useState(false);

  const oldPasswordInputRef = useRef(null);
  const newPassword1InputRef = useRef(null);
  const newPassword2InputRef = useRef(null);

  const { isCollapsedSidebar, isAdaptiveMenuActive } =
    useSelector(globalDataSelector);

  const { changePasswordPending } = useSelector(authSelector);

  const dispatch = useDispatch();

  const { email, first_name, offer } = getUser()?.user || {};

  const handleShowPasswordClick = (e, ref, state, action) => {
    e.preventDefault();
    action(!state);

    if (ref.current) {
      ref.current.focus();
    }
  };

  const formik = useFormik({
    initialValues: {
      new_password: "",
      re_new_password: "",
      current_password: "",
    },
    validationSchema: Yup.object({
      new_password: Yup.string()
        .min(8, "Пароль должен содержать минимум 10 символов")
        .matches(/[A-Z]/, "Пароль должен содержать минимум одну большую букву")
        .matches(
          /[a-z]/,
          "Пароль должен содержать минимум одну маленькую букву",
        )
        .matches(/[0-9]/, "Пароль должен содержать минимум одну цифру")
        .matches(
          /[!@#$%^&*(),.?":{}|<>]/,
          "Пароль должен содержать специальный символ",
        )
        .required("Обязательное поле"),
      re_new_password: Yup.string()
        .oneOf([Yup.ref("new_password"), null], "Пароли должны совпадать")
        .required("Обязательное поле"),
      current_password: Yup.string().required("Обязательное поле"),
    }),
    onSubmit: async (form, { setFieldError }) => {
      form.current_password = form.current_password.trim();
      form.new_password = form.new_password.trim();
      form.re_new_password = form.re_new_password.trim();

      const changePasswordResult = await dispatch(changePassword(form));

      const dataResponse = changePasswordResult?.payload?.data;

      if (changePasswordResult?.payload?.status === 204) {
        logoutHandler();
      } else if (
        dataResponse?.current_password?.includes("Invalid password.")
      ) {
        return setFieldError("current_password", "Текущий пароль не совпадает");
      } else if (
        dataResponse?.new_password?.includes(
          "The password is too similar to the email address.",
        )
      ) {
        return setFieldError(
          "current_password",
          "Пароль должен отличаться от почты",
        );
      } else if (
        dataResponse?.new_password?.includes("This password is too common.")
      ) {
        return setFieldError("current_password", "Пароль слишком легкий");
      }
    },
    validateOnMount: true,
    validateOnBlur: true,
    validateOnChange: true,
  });

  return (
    <div
      className={`${s.changePasswordPageWrapper} ${isCollapsedSidebar === "true" ? s.collapsedSidebar : ""}`}
    >
      <div className={s.pageHeaderWrapper}>
        <div className={s.pageHeaderContainer}>
          <div className={s.staticElements}>
            <div className={s.leftSideWrapper}>
              <button
                className={s.walletBtn}
                onClick={() => {
                  dispatch(setAdaptiveMenu(false));
                  dispatch(setTopUpBalanceActive(true));
                }}
              >
                <GlobalSvgSelector id="wallet" />
              </button>
              <Link className={s.walletBtn} to="/account">
                <GlobalSvgSelector id="account-gear-icon" />
              </Link>
            </div>

            <button
              className={s.menuBtn}
              onClick={() => dispatch(setAdaptiveMenu(!isAdaptiveMenuActive))}
            >
              <GlobalSvgSelector id="burger-menu" />
            </button>
          </div>
        </div>
      </div>

      <div className={s.pageContent}>
        <div className={s.pageTitle}>
          <GlobalSvgSelector id="personal-account-icon" />
          Личный кабинет
        </div>
        <div className={s.contentWrapper}>
          <div className={s.personalDataInfoContainer}>
            <p className={s.title}>Персональная информация</p>
            <div className={s.inputsWrapper}>
              <div className={s.inputWrapper}>
                <p>Email</p>
                <input
                  type="text"
                  className={s.textInput}
                  value={email}
                  placeholder="Email"
                />
              </div>
              <div className={s.inputWrapper}>
                <p>Имя пользователя</p>
                <input
                  type="text"
                  className={s.textInput}
                  value={first_name}
                  placeholder="Имя пользователя"
                />
              </div>
              <div className={s.inputWrapper}>
                <p>Команда</p>
                <input
                  type="text"
                  className={s.textInput}
                  value={offer}
                  placeholder="Команда"
                />
              </div>
            </div>
          </div>
          <form
            className={s.changePasswordContainer}
            onSubmit={formik.handleSubmit}
          >
            <p className={s.title}>Смена пароля</p>
            <div className={s.inputsWrapper}>
              <div className={s.fieldWrapper}>
                <p>Старый пароль</p>
                <div className={s.inputWrapper}>
                  <input
                    name="current_password"
                    type={isShowedOldPassword ? "text" : "password"}
                    placeholder="Старый пароль"
                    value={formik.values.current_password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className={`${s.textInput} ${
                      formik.touched.current_password &&
                      formik.errors.current_password
                        ? s.error
                        : ""
                    }`}
                  />
                  {formik.touched.current_password &&
                  formik.errors.current_password ? (
                    <div className={s.error}>
                      {formik.errors.current_password}
                    </div>
                  ) : null}
                  <button
                    type="button"
                    className={s.showPasswordBtn}
                    onClick={(e) =>
                      handleShowPasswordClick(
                        e,
                        oldPasswordInputRef,
                        isShowedOldPassword,
                        setShowedOldPassword,
                      )
                    }
                  >
                    <GlobalSvgSelector
                      id={
                        isShowedOldPassword
                          ? "show-password-icon"
                          : "hide-password-icon"
                      }
                    />
                  </button>
                </div>
              </div>
              <div className={s.fieldWrapper}>
                <p>Новый пароль</p>
                <div className={s.inputWrapper}>
                  <input
                    name="new_password"
                    type={isShowedNewPassword1 ? "text" : "password"}
                    placeholder="Новый пароль"
                    value={formik.values.new_password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className={`${s.textInput} ${
                      formik.touched.new_password && formik.errors.new_password
                        ? s.error
                        : ""
                    }`}
                  />
                  {formik.touched.new_password && formik.errors.new_password ? (
                    <div className={s.error}>{formik.errors.new_password}</div>
                  ) : null}
                  <button
                    type="button"
                    className={s.showPasswordBtn}
                    onClick={(e) =>
                      handleShowPasswordClick(
                        e,
                        newPassword1InputRef,
                        isShowedNewPassword1,
                        setShowedNewPassword1,
                      )
                    }
                  >
                    <GlobalSvgSelector
                      id={
                        isShowedNewPassword1
                          ? "show-password-icon"
                          : "hide-password-icon"
                      }
                    />
                  </button>
                </div>
              </div>

              <div className={s.fieldWrapper}>
                <p>Повторите новый пароль</p>
                <div className={s.inputWrapper}>
                  <input
                    name="re_new_password"
                    type={isShowedNewPassword2 ? "text" : "password"}
                    placeholder="Повторите новый пароль"
                    value={formik.values.re_new_password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className={`${s.textInput} ${
                      formik.touched.re_new_password &&
                      formik.errors.re_new_password
                        ? s.error
                        : ""
                    }`}
                  />
                  {formik.touched.re_new_password &&
                  formik.errors.re_new_password ? (
                    <div className={s.error}>
                      {formik.errors.re_new_password}
                    </div>
                  ) : null}
                  <button
                    type="button"
                    className={s.showPasswordBtn}
                    onClick={(e) =>
                      handleShowPasswordClick(
                        e,
                        newPassword2InputRef,
                        isShowedNewPassword2,
                        setShowedNewPassword2,
                      )
                    }
                  >
                    <GlobalSvgSelector
                      id={
                        isShowedNewPassword2
                          ? "show-password-icon"
                          : "hide-password-icon"
                      }
                    />
                  </button>
                </div>
              </div>
            </div>
            <div className={s.buttons}>
              <button
                className={s.whiteBorderBtn}
                type="button"
                onClick={() => formik.resetForm()}
              >
                Отменить
              </button>
              <PrimaryBtn
                text="Сохранить"
                submit={true}
                pending={changePasswordPending}
                className={s.customPrimaryBtn}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
