import React from 'react';
import s from './resetEmailForm.module.scss'
import {useForm} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import {resetPassword} from "../../../../redux/slices/authSlice";
import AuthBackground from "../../../../components/UiKit/AuthBackground/AuthBackground";
import GlobalSvgSelector from "../../../../components/GlobalSvgSelector/GlobalSvgSelector";
import {TextField} from "@mui/material";
import {Link, useNavigate} from "react-router-dom";
import Loader from "../../../../components/UiKit/Loader/Loader";
import {authTextFieldStyles} from "../../Auth/Auth";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";


const validationSchema = yup.object().shape({
    email: yup
        .string()
        .email('Електронный адрес не валиден')
        .required('Обязательное поле')
});


const ResetEmailForm = () => {
    const { register, handleSubmit, setError, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema),
    });

    const navigate = useNavigate()
    const { loading } = useSelector((state) => state.auth);
    const dispatch = useDispatch();

    const onSubmit = async data => {
        const { email } = data;

        const dataObj = {
            email: email?.trim()
        };

        const successAction = () => {
            navigate('/reset/email-confirm')
        }

        dispatch(resetPassword({ data: dataObj, successAction, setError }))
    };


    return (
        <form
            className={s.authContainer}
            onSubmit={handleSubmit(onSubmit)}
        >
            <div className={s.header}>
                <h5>Восстановление пароля</h5>
                <span className={s.subtitle}>Введите электронный адрес вашего аккаунта, на который будет оправлено письмо с инструкциями</span>
            </div>

            <div className={s.content}>

                <div className={s.fieldContainer}>

                    <TextField
                        {...register("email", {
                            required: '',
                            pattern: {
                                value: /\S+@\S+\.\S+/,
                                message: "Електронный адрес не валиден"
                            }
                        })}
                        error={!!errors.email}
                        helperText={errors.email?.message}
                        label="Email"
                        type="email"
                        autoComplete="current-password"
                        sx={authTextFieldStyles}
                    />

                </div>
            </div>
            <div className={s.footer}>
                <button
                    className={s.loginBtn}
                    type="submit"
                    disabled={loading}
                >
                    {loading ? <Loader/> : 'Подтвердить'}
                </button>
            </div>
            <div className={s.createAccountContainer}>
                <Link to='/auth/login'>Вернуться к авторизации</Link>
            </div>
        </form>
    );
};

export default ResetEmailForm;