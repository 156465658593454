import React, { useEffect, useRef, useState } from "react";
import s from "../flows.module.scss";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { duplicateFlow } from "../../../../redux/slices/flowSlice";
import GetLinksPopup from "../../../../components/UiKit/Popups/GetLinksPopup/GetLinksPopup";
import GlobalSvgSelector from "../../../../components/GlobalSvgSelector/GlobalSvgSelector";
import {
  FLOW_STATUS_FILTER,
  FLOW_STATUS_MONEY,
  FLOW_STATUS_REVIEWS,
  snackbar_error,
  snackbar_info,
  snackbar_success,
  snackbar_warning,
} from "../../../../utils/vars/staticVariables";
import MuiTooltip from "../../../../components/UiKit/MuiTooltip/MuiTooltip";
import NewConfirmPopup from "../../../../components/UiKit/Popups/NewConfirmPopup/NewConfirmPopup";
import useCustomSnackbar from "../../../../hooks/useCustomSnackbar";
import { useSnackbar } from "notistack";
import {
  flowCreateLimit,
  noDomains,
  notEnoughMoney,
} from "../../../../utils/vars/errorMessages";
import SupportPopup from "../../../../components/UiKit/Popups/SupportPopup/SupportPopup";
import FlowTableGeoTooltip from "../../../../components/UiKit/FlowTableGeoTooltip/FlowTableGeoTooltip";
import FlowTableAppTooltip from "../../../../components/UiKit/ToolTips/FlowTableAppFieldTooltip/FlowTableAppTooltip";

const FlowTd = ({
  item,
  getFlows,
  onDeleteHandler,
  duplicateFlowPending,
  isPartner,
  geosList,
}) => {
  const [activeRemovePopup, setActiveRemovePopup] = useState(false);
  const [copyLinksPopupIsActive, setCopyLinksPopup] = useState(false);
  const [activeCreateFlowPopup, setActiveCreateFlowPopup] = useState(false);
  const [flowActionsPopup, setFlowActionsPopup] = useState(false);

  const [activeFlowCreateLimitPopup, setActiveFlowCreateLimitPopup] =
    useState(false);

  const flowActionPopupRef = useRef(null);
  const toggleFlowActionButtonRef = useRef(null);

  const dispatch = useDispatch();

  const showSnackbar = useCustomSnackbar();
  const { closeSnackbar } = useSnackbar();

  const disabledDuplicate = !item?.settings;

  const handleClickOutsideActionsPopup = (event) => {
    if (
      flowActionPopupRef.current &&
      !flowActionPopupRef.current.contains(event.target) &&
      toggleFlowActionButtonRef.current &&
      !toggleFlowActionButtonRef.current.contains(event.target) // Перевірка, чи клік був на кнопці
    ) {
      setFlowActionsPopup(false); // Закриваємо попап, якщо клік поза ним і не на кнопці
    }
  };

  useEffect(() => {
    // Додаємо обробник подій при монтовані компонента
    document.addEventListener("mousedown", handleClickOutsideActionsPopup);

    // Видаляємо обробник подій при демонтуванні компонента
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideActionsPopup);
    };
  }, []);

  const onDuplicateFlowHandler = async () => {
    const data = { flow_id: item.id };

    const duplicatePendingSnackbar = showSnackbar(
      `Дублирование потока ${item?.domain?.address} ...`,
      snackbar_info,
      true,
    );

    const duplicateFlowResult = await dispatch(duplicateFlow(data));

    if (duplicateFlowResult?.payload?.status === 201) {
      getFlows();
      closeSnackbar(duplicatePendingSnackbar);
      showSnackbar(
        `Поток ${item?.domain?.address} был дублирован`,
        snackbar_success,
      );
    } else {
      closeSnackbar(duplicatePendingSnackbar);

      if (duplicateFlowResult?.payload?.data?.details === noDomains) {
        return showSnackbar(noDomains, snackbar_warning);
      }

      if (duplicateFlowResult?.payload?.data?.details === notEnoughMoney) {
        return showSnackbar(
          "Недостаточно средств на балансе",
          snackbar_warning,
        );
      }

      if (duplicateFlowResult?.payload?.data?.details === flowCreateLimit) {
        return setActiveFlowCreateLimitPopup(true);
      }

      showSnackbar(
        `Не удалось дублировать поток ${item?.domain?.address}`,
        snackbar_error,
      );
    }
  };

  const { geo = [], status = "" } = item?.settings || {};

  const getStatusContent = (status) => {
    switch (status) {
      case "Filter":
        return (
          <>
            <GlobalSvgSelector id="cloacaStatusOn" />
            {FLOW_STATUS_FILTER}
          </>
        );
      case "Review":
        return (
          <>
            <GlobalSvgSelector id="cloacaStatusReview" />
            {FLOW_STATUS_REVIEWS}
          </>
        );
      case "Money":
        return (
          <>
            <GlobalSvgSelector id="cloacaStatusOff" />
            {FLOW_STATUS_MONEY}
          </>
        );

      default:
        return <></>;
    }
  };

  const uniqueApplications = item?.redirector
    ?.flatMap((item) => item.application_percentages)
    .map((app) => ({ name: app.application.name, icon: app.application.icon }))
    .reduce((acc, current) => {
      // Перевірка на унікальність за полем 'name'
      if (!acc.some((item) => item.name === current.name)) {
        acc.push(current);
      }
      return acc;
    }, []);

  return (
    <tr>
      <td className={s.noteTd}>
        <SupportPopup
          icon={<GlobalSvgSelector id="popup-duplicate-icon" />}
          active={activeFlowCreateLimitPopup}
          setActive={setActiveFlowCreateLimitPopup}
          action={() => setActiveFlowCreateLimitPopup(false)}
          title="Дублирование потока"
          description="Вы достигли дневного лимита по количеству новых потоков"
        />
        <NewConfirmPopup
          icon={<GlobalSvgSelector id="popup-duplicate-icon" />}
          active={activeCreateFlowPopup}
          setActive={setActiveCreateFlowPopup}
          action={() => onDuplicateFlowHandler()}
          confirmBtnText="Продолжить"
          title="Дублирование потока"
          description={
            isPartner
              ? `Дублировать поток ${item?.domain?.address} ?`
              : "При дублировании потока с вашего баланса спишется $6 за домен"
          }
        />
        <NewConfirmPopup
          icon={<GlobalSvgSelector id="popup-remove-icon" />}
          active={activeRemovePopup}
          setActive={setActiveRemovePopup}
          action={() => onDeleteHandler(item.id, item?.domain?.address)}
          confirmBtnText="Удалить"
          title="Удаление потока"
          description="Не удаляйте поток, если у вас есть активный траффик по нему, иначе
              пользователи не попадут на оффер"
        />
        <GetLinksPopup
          redirectors={item?.redirector}
          active={copyLinksPopupIsActive}
          domain={item?.domain?.address}
          setActive={setCopyLinksPopup}
        />

        <div className={s.editNoteField}>
          <Link to={`/my-flows/edit/${item.id}`} className={s.noteText}>
            {item.notes}
            <MuiTooltip title={item.notes}></MuiTooltip>
          </Link>
        </div>
      </td>
      <td>{item?.domain?.address || ""}</td>

      <td
        style={{ position: "relative" }}
        className={`${geo?.length > 0 ? s.geoTd : s.mocked}`}
      >
        <div className={s.tdContentContainer}>
          <FlowTableGeoTooltip geos={geo} geosList={geosList}>
            <div className={s.iterativeContentWrapper}>
              {geo.map(({ id, code }) => {
                const foundFlag = geosList.find((item) => item.id === id)?.flag;

                return (
                  <span className={s.value} key={id}>
                    <span
                      className={s.iconWrapper}
                      style={{
                        backgroundImage: foundFlag
                          ? `url(${foundFlag})`
                          : "none",
                      }}
                    ></span>
                    <span>{code}</span>
                  </span>
                );
              })}
            </div>
          </FlowTableGeoTooltip>
        </div>
      </td>
      <td className={s.statusTd}>
        <div className={s.statusIndicator}>{getStatusContent(status)}</div>
      </td>
      <td
        className={`${uniqueApplications?.length > 0 ? s.applicationTd : s.mocked}`}
      >
        <div className={s.tdContentContainer}>
          <FlowTableAppTooltip uniqueApplications={uniqueApplications}>
            <div className={s.applicationContentWrapper}>
              {uniqueApplications.map(({ name, icon }, index) => (
                <span className={s.appValue} key={index}>
                  <span
                    className={s.iconWrapper}
                    style={{
                      backgroundImage: icon ? `url(${icon})` : "none",
                    }}
                  ></span>
                  <span>{name}</span>
                </span>
              ))}
            </div>
          </FlowTableAppTooltip>
        </div>
      </td>

      <td className={s.actionsTd}>
        {flowActionsPopup && (
          <ul className={s.flowActionsPopup} ref={flowActionPopupRef}>
            <li>
              <Link to={`/my-flows/edit/${item.id}`}>
                <GlobalSvgSelector id="old-edit-icon" />
                <span>Редактировать</span>
              </Link>
            </li>
            <li onClick={() => setFlowActionsPopup(false)}>
              <a
                href={`/stats/clicks?domain=${item?.domain?.address}`}
                target="_blank"
                rel="noreferrer"
              >
                <GlobalSvgSelector id="new-stats-icon" />{" "}
                <span>Перейти к стат. по кликам</span>
              </a>
            </li>

            <li>
              <button
                onClick={() => {
                  setActiveCreateFlowPopup(true);
                  setFlowActionsPopup(false);
                }}
                disabled={disabledDuplicate || duplicateFlowPending}
              >
                <GlobalSvgSelector id="duplicate" />
                <span>
                  {duplicateFlowPending ? "Дублирование" : "Дублировать"}
                </span>
              </button>
            </li>

            <li>
              <button
                onClick={() => {
                  setActiveRemovePopup(true);
                  setFlowActionsPopup(false);
                }}
              >
                <GlobalSvgSelector id="new-remove-icon" />
                <span>Удалить</span>
              </button>
            </li>
          </ul>
        )}

        <div className={s.actionsWrapper}>
          <div className={s.actionsContainer}>
            <div className={s.actions}>
              <button
                className={`${s.manageTableStrokeBtn} ${s.linksBtn}`}
                onClick={() => setCopyLinksPopup(true)}
              >
                <MuiTooltip title="Получить ссылки">
                  <GlobalSvgSelector id="new-links-icon" />
                </MuiTooltip>
              </button>

              <button
                className={s.removeBtn}
                onClick={() => setFlowActionsPopup(!flowActionsPopup)}
                ref={toggleFlowActionButtonRef}
              >
                <MuiTooltip title="">
                  <GlobalSvgSelector id="flow-menu" />
                </MuiTooltip>
              </button>
            </div>
          </div>
        </div>
      </td>
    </tr>
  );
};

export default FlowTd;
