import { pageSizeOptions } from "../components/UiKit/PageSizeDropdown/PageSizeDropdown";

export const getPageSize = () => {
  const storedPageSize = localStorage.getItem("pageSize");

  if (pageSizeOptions.includes(Number(storedPageSize))) {
    return Number(storedPageSize);
  }

  return pageSizeOptions[0];
};

export const setPageSize = (value) => {
  localStorage.setItem("pageSize", value);
};
